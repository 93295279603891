import { useState, useContext, Fragment } from 'react'
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover, CircularProgress, Radio, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Iconify from 'components/Iconify'

import { UserContext } from 'contexts'
import { changeUserPreferences } from 'services/requests/user'
import { setMask, Toast } from 'utils'

export default function AccountPopover() {
  const { t } = useTranslation()

  const userContext = useContext(UserContext)
  const { state: { data } } = userContext

  const [open, setOpen] = useState(null)
  const [loadingLogout, setLoadingLogout] = useState(false)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleChangeProfilePreference = (e, profileId) => {
    e.preventDefault()

    changeUserPreferences({ body: { profileId } })
      .then(() => { window.location.href = '/' })
      .catch((err) => Toast(err, 'error'))
  }

  const handleLogout = () => {
    setLoadingLogout(true)
    userContext.exec.logout()
      .then(() => setLoadingLogout(false))
      .catch(() => setLoadingLogout(false))
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.8),
            },
          }),
        }}
      >
        <Avatar src={null} alt={data.name}>
          {data.name.slice(0, 1)}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {data.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {data.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {
          data.userCompany.map((item, key) => (
            <Fragment key={key}>
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2" noWrap>
                  {item.company.fantasyName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }} noWrap>
                  {setMask(item.company.cnpj, '99.999.999/9999-99')}
                </Typography>
                {
                  item.profiles.map((_item, _key) => (
                    <Box
                      key={`${key}_${_key}`}
                      onClick={e => handleChangeProfilePreference(e, _item.id)}
                      sx={{
                        pointerEvents: _item.isSelected ? 'none' : 'all'
                      }}
                    >
                      <FormControlLabel
                        value={_item.id}
                        control={<Radio size="small" />}
                        label={_item.name}
                        checked={_item.isSelected}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  ))
                }
              </Box>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Fragment>
          ))
        }

        <MenuItem onClick={handleLogout} sx={{ m: 1 }} disabled={loadingLogout}>
          {
            loadingLogout
              ? <CircularProgress size={20} sx={{ mr: '8px' }} />
              : <Iconify icon="mdi:logout" style={{ marginRight: '8px' }} />
          }
          {
            t('logout')
          }
        </MenuItem>
      </Popover>
    </>
  )
}
