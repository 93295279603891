import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Autocomplete,
  MenuItem,
  Checkbox,
  ListItemText,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { LocalizationProvider, DateTimePicker, DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ptBR } from 'date-fns/locale'

import Dialog from 'components/Dialog'
import NumberFormatCustom from 'components/NumberFormatCustom'

import { getStageTableColumnValues } from 'services/requests/stage'
import { getConditionsByDataType } from 'utils'

const initialValues = {
  condition: '=',
  value: '',
}

export default function FormFilterField({ open, storageTable, field, value, onConfirm, onCancel }) {
  const [distinctOptions, setDistinctOptions] = useState([])

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onConfirm(values)
      setSubmitting(false)
      handleClose()
    },
  })

  const handleClose = () => {
    formik.resetForm(initialValues)
    onCancel()
  }

  const handleConfirm = () => {
    formik.handleSubmit()
  }

  const fillEditForm = async () => {
    if (field.mapped_type === 'varchar' || field.mapped_type === 'text') {
      const distinctValues = await getStageTableColumnValues({ connectionId: storageTable.connectionId, table: storageTable.table, column: field.attribute })
      setDistinctOptions(distinctValues)
    } else {
      setDistinctOptions([])
    }

    if (value.condition || value.value) {
      formik.setFieldValue('condition', value.condition)
      formik.setFieldValue('value', value.value)
    }
  }

  useEffect(() => {
    if (open && storageTable && field && value) {
      fillEditForm()
    }
    // eslint-disable-next-line
  }, [open, storageTable, field, value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Dialog
        open={open}
        options={{
          title: t('data.stage.form.filters'),
          content: (
            <Box my={2}>
              <Paper elevation={2}>
                <Box px={2} py={3}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1">
                      {field.attribute}
                    </Typography>
                    <Typography variant="body2">&nbsp;&nbsp;({field.mapped_type})</Typography>
                  </Box>

                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <FormControl
                        sx={{ width: '100%' }}
                      >
                        <InputLabel id="condition">{t('data.stage.form_filter.condition')}</InputLabel>
                        <Select
                          labelId="condition"
                          label={t('data.stage.form_filter.condition')}
                          name="condition"
                          value={formik.values.condition}
                          onChange={e => formik.handleChange(e)}
                          sx={{ background: 'rgba(255, 255, 255, .4)' }}
                        >
                          {
                            getConditionsByDataType(field.mapped_type).map((item, key) => (
                              <MenuItem value={item.key} key={key}>
                                {item.label}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      {
                        getInputFilter(formik, field.mapped_type, distinctOptions)
                      }
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          ),
          cancellationText: 'data.stage.form_filter.cancel',
          confirmationText: 'data.stage.form_filter.save',
          dialogProps: {
            maxWidth: 'md',
            PaperProps: {
              style: {
                backgroundColor: '#f8f8f8'
              }
            }
          }
        }}
        onCancel={() => handleClose()}
        onConfirm={() => handleConfirm()}
      />
    </LocalizationProvider>
  )
}


export const getInputFilter = (formik, dataType, distinctValues) => {
  const { condition, value } = formik.values

  const handleChangeAutocomplete = (_, newValues) => {
    formik.setFieldValue('value', newValues)
  }

  const handleChange = (e) => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeDateTime = (name, date) => {
    formik.setFieldTouched(name)
    formik.setFieldValue(name, date)

  }

  const getLikeInput = () => (
    <TextField
      name={'value'}
      label={t('data.stage.form_filter.value_like')}
      value={value}
      onChange={handleChange}
    />
  )

  const getNullableInput = () => (
    <RadioGroup
      name={'value'}
      value={value}
      onChange={handleChange}
      sx={{ mt: 1, display: 'flex', justifyContent: 'space-around' }}
      row
    >
      <FormControlLabel value="NULL" control={<Radio />} label="NULL" />
      <FormControlLabel value="NOT NULL" control={<Radio />} label="NOT NULL" />
    </RadioGroup>
  )

  const getTextInput = () => (
    <Autocomplete
      multiple
      limitTags={2}
      name={'value'}
      value={value || []}
      onChange={handleChangeAutocomplete}
      disabled={!distinctValues?.length}
      options={distinctValues}
      getOptionLabel={option => option || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('data.stage.form_filter.value')}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem value={option} {...props}>
          <Checkbox checked={selected} />
          <ListItemText primary={option} />
        </MenuItem>
      )}
      PaperComponent={paperProps => {
        const { children, ...restPaperProps } = paperProps
        return (
          <Paper {...restPaperProps} sx={{ zIndex: 999999999 }}>
            {children}
          </Paper>
        )
      }}
    />
  )

  const getBoolInput = () => (
    <RadioGroup
      name={'value'}
      value={value}
      onChange={handleChange}
      sx={{ mt: 1, display: 'flex', justifyContent: 'space-around' }}
      row
    >
      <FormControlLabel value="0" control={<Radio />} label="FALSE" />
      <FormControlLabel value="1" control={<Radio />} label="TRUE" />
    </RadioGroup>
  )

  const getNumericInput = () => (
    <TextField
      name={'value'}
      label={t('data.stage.form_filter.value')}
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
  )

  const getTimestampInput = () => (
    <DateTimePicker
      name={'value'}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime('value', date)}
    />
  )

  const getDateInput = () => (
    <DatePicker
      name={'value'}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime('value', date)}
    />
  )

  const getTimeInput = () => (
    <TimePicker
      name={'value'}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime('value', date)}
    />
  )

  return (
    <FormControl sx={{ width: '100%' }}>
      {
        (condition === 'LIKE' || condition === 'NOT LIKE') ? (
          getLikeInput()
        ) : condition === 'NULLABLE' ? (
          getNullableInput()
        ) : (
          (dataType === 'varchar' || dataType === 'text') ? (
            getTextInput()
          ) : dataType === 'bool' ? (
            getBoolInput()
          ) : (dataType === 'timestamp' || dataType === 'timestamp with time zone') ? (
            getTimestampInput()
          ) : dataType === 'date' ? (
            getDateInput()
          ) : dataType === 'time' ? (
            getTimeInput()
          ) : (
            getNumericInput()
          )
        )
      }
    </FormControl>
  )
}

FormFilterField.propTypes = {
  open: PropTypes.bool,
  storageTable: PropTypes.object,
  field: PropTypes.object,
  value: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}
