import { Navigate, useRoutes } from 'react-router-dom'
import { useContext } from 'react'

import { UserContext } from 'contexts'
import { SimpleLayout, DashboardLayout } from 'layouts'
import menuConfig from 'routes/config'
import { Storage } from 'services'

// auth
import Login from 'pages/auth/Login'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import Register from 'pages/auth/Register'
import Invite from 'pages/auth/Invite'

// dashboard
import Dashboard from 'pages/Dashboard'

// panels
import Panel from 'pages/panels/Panel'

// visualization
import Reports from 'pages/visualization/Reports'
import Indicators from 'pages/visualization/Indicators'
import Panels from 'pages/visualization/Panels'
import PanelDetail from 'pages/visualization/PanelDetail'

// data
import Connections from 'pages/data/Connections'
import Materializations from 'pages/data/Materializations'
import MaterializationDetail from 'pages/data/MaterializationDetail'
import Stage from 'pages/data/Stage'

// manage
import Companies from 'pages/management/Companies'
import CompanyDetail from 'pages/management/CompanyDetail'

// settings
import Profiles from 'pages/settings/Profiles'
import Invites from 'pages/settings/Invites'
import Users from 'pages/settings/Users'
import Account from 'pages/settings/MyAccount'
import MyCompany from 'pages/settings/MyCompany'

// 404
import Page404 from 'pages/404'

import BaseRoute from './baseRoute'

export default function Router() {
  const { state: { data: userData } } = useContext(UserContext)
  const userDataStorage = Storage.get({ key: '-user' })

  const baseDefaultRoutes = [
    {
      path: 'auth',
      element: <BaseRoute isPublic />,
      children: [
        { element: <Login />, index: true },
        { path: 'recuperar-senha', element: <ForgotPassword /> },
        { path: 'redefinir-senha/:token', element: <ResetPassword /> },
        { path: 'criar-conta', element: <Register /> },
      ],
    },
    {
      path: 'auth/convite/:token',
      element: <Invite />
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ]

  const baseLoggedRoutes = [
    // visualization
    { path: 'visualizacao/consultas', element: <Reports /> },
    { path: 'visualizacao/indicadores', element: <Indicators /> },
    { path: 'visualizacao/paineis', element: <Panels /> },
    { path: 'visualizacao/paineis/:id', element: <PanelDetail /> },

    // data
    { path: 'dados/conexoes', element: <Connections /> },
    { path: 'dados/materializacoes', element: <Materializations /> },
    { path: 'dados/materializacoes/:id', element: <MaterializationDetail /> },
    { path: 'dados/stage', element: <Stage /> },

    // manage
    { path: 'gestao/empresas', element: <Companies /> },
    { path: 'gestao/empresas/:id', element: <CompanyDetail /> },

    // settings
    { path: 'configuracao/perfis', element: <Profiles /> },
    { path: 'configuracao/convites', element: <Invites /> },
    { path: 'configuracao/usuarios', element: <Users /> },
    { path: 'configuracao/minha-conta', element: <Account /> },
    { path: 'configuracao/minha-empresa', element: <MyCompany /> },
  ]

  const getRoutesBasedOnProfile = () => {
    const data = userData || userDataStorage

    if (data?.profile?.role) {
      const menu = data.company.isApproved === null ? [] : menuConfig
        .map(item => (
          item.items
            .filter(_item => _item.path && _item.roles.includes(data.profile.role))
            .map(_item => _item.path)
        ))
        .flat()

      return baseLoggedRoutes.filter(item => menu.findIndex(_item => item.path.startsWith(_item)) > -1)
    }

    return []
  }

  const routes = useRoutes([
    {
      path: '/',
      element: <BaseRoute isPrivate />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { element: <Dashboard />, index: true },
            { path: 'painel/:id', element: <Panel /> },
            ...getRoutesBasedOnProfile()
          ]
        }
      ]
    },
    ...baseDefaultRoutes
  ])

  return routes
}
