import PropTypes from 'prop-types'

import Dialog from 'components/Dialog'
import DataGrid from 'components/DataGrid'

import { getStagePreview } from 'services/requests/stage'

export default function StagePreview({ values, onClose }) {
  const listConfig = {
    fields: values?.rules?.map(item => ({
      accessorKey: item.columnAlias,
      header: `${item.columnAlias} (${item.dataType})`
    })),
    title: 'Preview',
    enableSorting: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false
  }

  return (
    <Dialog
      open={values?.rules?.length > 0}
      options={{
        content: (
          <DataGrid
            config={listConfig}
            getData={getStagePreview}
            getDataParams={{ body: values }}
          />
        ),
        allowClose: true,
        hideCancel: true,
        confirmationText: 'ok',
        confirmationButtonProps: {
          sx: {
            mr: 2
          }
        },
        dialogProps: {
          maxWidth: 'xl',
          PaperProps: {
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }
        },
        closeProps: {
          sx: {
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            right: 40,
            top: 40,
            zIndex: 9999
          }
        }
      }}
      onConfirm={() => onClose()}
      onClose={() => onClose()}
    />
  )
}

StagePreview.propTypes = {
  values: PropTypes.object,
  onClose: PropTypes.func
}
