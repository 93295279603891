import PropTypes from 'prop-types'
import {
  Grid,
  Typography
} from '@mui/material'

import { shortenLargeNumber } from 'utils'

export default function ViewBigNumber({ data }) {
  const getLabel = (itemSerie, item) =>
    `${itemSerie.name}${(data.groupBY[0] ? ` [${item[data.groupBY[0].name]}]` : '')}`

  const getValue = (itemSerie, item) => {
    const data = item[itemSerie.name]
    return shortenLargeNumber(data)
  }

  return (
    <Grid container spacing={2}>
      {
        data?.series?.map((itemSerie, indexSerie) => (
          data?.data?.map((_item, index) => (
            <Grid
              item
              xs={12} sm={6} md={4} lg={3}
              key={`${indexSerie}-${index}`}
            >
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                {
                  getLabel(itemSerie, _item)
                }
              </Typography>
              <Typography variant="h3">
                {
                  getValue(itemSerie, _item)
                }
              </Typography>
            </Grid>
          ))
        ))
      }
    </Grid>
  )
}

ViewBigNumber.propTypes = {
  data: PropTypes.object
}
