import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Container, Typography } from '@mui/material'

import { UserContext } from 'contexts'

export default function DashboardAppPage() {
  const { state: { data } } = useContext(UserContext)
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('project_name')}</title>
      </Helmet>

      <Container maxWidth="xl">
        {
          data.company.isApproved === null ? (
            <Typography variant="h4" sx={{ mb: 5 }}>
              Sua conta está aguardando o processo de aprovação!
            </Typography>
          ) : (
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi, Welcome back
            </Typography>
          )
        }
      </Container>
    </>
  );
}
