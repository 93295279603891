import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { resetPassword } from 'services/requests/auth'
import { Toast } from 'utils'

import Iconify from 'components/Iconify'

const initialValues = {
  password: '',
  cpassword: ''
}

export default function ResetForm({ t }) {
  const { token } = useParams()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showCPassword, setShowCPassword] = useState(false)

  const validationSchema = yup.object({
    password: yup.string()
      .required('Obrigatório')
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Senha fraca: sua senha deve conter números, letras maiúsculas, letras minúsculas e caracteres especiais'
      )
      .min(8, 'Sua senha deve conter pelo menos 8 caracteres')
      .max(32, 'Sua senha deve conter no máximo 32 caracteres'),
    cpassword: yup.string()
      .required('Obrigatório')
      .oneOf([yup.ref('password'), null], 'Senha e confirmação não conferem'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ password }, { setSubmitting }) => {
      resetPassword({ body: { password, token } })
        .then(() => {
          Toast(t('auth.reset_password.success'), 'success')
          navigate('/auth', { replace: true })
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label={t('auth.reset_password.form.password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="cpassword"
          label={t('auth.reset_password.form.cpassword')}
          type={showCPassword ? 'text' : 'password'}
          value={formik.values.cpassword}
          onChange={handleChange}
          error={formik.touched.cpassword && Boolean(formik.errors.cpassword)}
          helperText={formik.touched.cpassword && formik.errors.cpassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                  <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ mt: 2 }}
      >
        {t('auth.reset_password.form.submit')}
      </LoadingButton>
    </>
  )
}

ResetForm.propTypes = {
  t: PropTypes.func
}
