import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import {
  Drawer,
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'

import { updatePanel } from 'services/requests/panel'
import { Toast } from 'utils'

export default function PanelProfilesForm({ panel, onClose, profiles = [] }) {
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (panel?.id) {
      const profilesIds = panel.profiles.map(item => item.id)
      setSelectedProfiles(profilesIds)
    }
  }, [panel])

  const handleChangeProfile = id => {
    const indexToRemove = selectedProfiles.findIndex(item => item === id)

    if (indexToRemove > -1) {
      const newArr = [...selectedProfiles]
      newArr.splice(indexToRemove, 1)
      setSelectedProfiles([...newArr])
    } else {
      setSelectedProfiles([
        ...selectedProfiles,
        id
      ])
    }
  }

  const onSubmit = () => {
    setLoadingSubmit(true)

    const body = {
      profilesId: selectedProfiles
    }

    updatePanel({ id: panel.id, body })
      .then(() => {
        Toast(t('visualization.panels.profiles_update_success'), 'success')
        handleClose(true)
      })
      .catch((err) => {
        Toast(err, 'error')
        setLoadingSubmit(false)
      })
  }

  const handleClose = (reload) => {
    setSelectedProfiles([])
    setLoadingSubmit(false)
    onClose(reload)
  }

  return (
    <Drawer
      anchor="right"
      open={!!panel?.id}
      onClose={() => handleClose()}
      PaperProps={{
        sx: { width: '90%', maxWidth: '400px', px: 3, py: 6 }
      }}
    >
      {
        panel?.id ? (
          <Stack spacing={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pb: 3
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
              >
                {panel.name}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body1"
              >
                {panel.enable ? t('active') : t('inactive')}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle1"
                mb={2}
              >
                {t('visualization.panels.profiles_update_description')}
              </Typography>

              <FormControl>
                <FormGroup>
                  {
                    profiles.map((item, key) => (
                      <FormControlLabel
                        key={key}
                        checked={selectedProfiles.includes(item.id)}
                        value={item.id}
                        control={<Checkbox color="primary" />}
                        onChange={(e) => handleChangeProfile(e.target.value)}
                        label={(
                          <Stack my={1}>
                            <Typography variant="subtitle1">{`${item.name}`}</Typography>
                          </Stack>
                        )}
                        labelPlacement="end"
                      />
                    ))
                  }
                </FormGroup>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="flex-end" spacing={3}>
              <Button
                size="large"
                type="submit"
                sx={{ mt: 2, mr: 1, width: { xs: '100%', md: 'auto' } }}
                color="error"
                onClick={() => handleClose()}
              >
                {t('cancel')}
              </Button>

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                sx={{ mt: 2, ml: 1, width: { xs: '100%', md: 'auto' } }}
                onClick={() => onSubmit()}
                loading={loadingSubmit}
              >
                {t('submit')}
              </LoadingButton>
            </Box>
          </Stack>
        ) : null
      }
    </Drawer>
  )
}

PanelProfilesForm.propTypes = {
  panel: PropTypes.object,
  onClose: PropTypes.func,
  profiles: PropTypes.array
}
