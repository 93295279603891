import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getProfiles } from 'services/requests/profile'
import { enums } from 'utils'

export default function CompanyProfilesView() {
  const { id } = useParams()

  const listConfig = {
    title: t('settings.company_detail.profiles.title'),
    fields: [
      {
        accessorKey: 'name',
        header: t('settings.profiles.fields.name')
      },
      {
        accessorKey: 'role',
        accessorFn: row => t(`roles.${row.role}`),
        header: t('settings.profiles.fields.role'),
        filterVariant: 'select',
        filterSelectOptions: enums.roles.filter(item => item.key !== 'superadmin')
      }
    ]
  }

  return (
    <DataGrid
      getData={getProfiles}
      getDataParams={{ companyId: id }}
      config={listConfig}
    />
  )
}
