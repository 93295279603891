const roles = {
  superadmin: 'superadmin',
  client: 'client',
  editor: 'editor',
  viewer: 'viewer'
}

export default [
  {
    title: 'nav_config.dashboard.index',
    items: [
      {
        title: 'nav_config.dashboard.dashboard',
        path: '',
        icon: 'mdi:view-dashboard',
        roles: [roles.superadmin, roles.client, roles.editor, roles.viewer]
      }
    ]
  },
  {
    title: 'nav_config.panels.index',
    items: []
  },
  {
    title: 'nav_config.visualization.index',
    items: [
      {
        title: 'nav_config.visualization.reports',
        path: 'visualizacao/consultas',
        icon: 'mdi:report-box-outline',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.visualization.indicators',
        path: 'visualizacao/indicadores',
        icon: 'fluent:math-formula-16-filled',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.visualization.panels',
        path: 'visualizacao/paineis',
        icon: 'mdi:view-dashboard-edit-outline',
        roles: [roles.superadmin, roles.client, roles.editor]
      }
    ]
  },
  {
    title: 'nav_config.data.index',
    items: [
      {
        title: 'nav_config.data.connections',
        path: 'dados/conexoes',
        icon: 'mdi:database-cog-outline',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.data.materializations',
        path: 'dados/materializacoes',
        icon: 'icon-park-outline:data-switching',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.data.stage',
        path: 'dados/stage',
        icon: 'ic:outline-fact-check',
        roles: [roles.superadmin, roles.client, roles.editor]
      }
    ]
  },
  {
    title: 'nav_config.manage.index',
    items: [
      {
        title: 'nav_config.manage.companies',
        path: 'gestao/empresas',
        icon: 'mdi:business',
        roles: [roles.superadmin]
      }
    ]
  },
  {
    title: 'nav_config.settings.index',
    items: [
      {
        title: 'nav_config.settings.profiles',
        path: 'configuracao/perfis',
        icon: 'eos-icons:cluster-role',
        roles: [roles.superadmin, roles.client]
      },
      {
        title: 'nav_config.settings.invites',
        path: 'configuracao/convites',
        icon: 'mdi:invite',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.settings.users',
        path: 'configuracao/usuarios',
        icon: 'mdi:users-group',
        roles: [roles.superadmin, roles.client, roles.editor]
      },
      {
        title: 'nav_config.settings.my_account',
        path: 'configuracao/minha-conta',
        icon: 'mdi:user',
        roles: [roles.superadmin, roles.client, roles.editor, roles.viewer]
      },
      {
        title: 'nav_config.settings.my_company',
        path: 'configuracao/minha-empresa',
        icon: 'mdi:store',
        roles: [roles.superadmin, roles.client, roles.editor]
      }
    ]
  }
]
