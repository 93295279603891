import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getMaterializationLogs } from 'services/requests/materialization'
import { enums, numerize } from 'utils'

export default function CompanyUsersView() {
  const { id } = useParams()

  const formatDate = dt => format(new Date(dt), 'dd/MM/yyyy \'às\' HH:mm:ss')

  const formatDiffTimes = sec => numerize(sec, 3, { sufix: ' s' })

  const listConfig = {
    title: 'Logs',
    enableColumnFilters: false,
    fields: [
      {
        accessorKey: 'status',
        accessorFn: row => t(`materialization_logs.${row.status}`),
        header: t('data.materialization_detail.logs_fields.status'),
        filterVariant: 'select',
        filterSelectOptions: enums.materializationLog
      },
      {
        accessorKey: 'rowcount',
        accessorFn: row => row.rowcount ? numerize(row.rowcount, 0) : '',
        header: t('data.materialization_detail.logs_fields.row_count')
      },
      {
        accessorKey: 'startat',
        accessorFn: row => row.startat ? formatDate(row.startat) : '',
        header: t('data.materialization_detail.logs_fields.start_at'),
        size: 200
      },
      {
        accessorKey: 'readstartdiff',
        accessorFn: row => row.readstartdiff ? formatDiffTimes(row.readstartdiff) : '',
        header: t('data.materialization_detail.logs_fields.read_start_diff'),
        size: 200
      },
      {
        accessorKey: 'readenddiff',
        accessorFn: row => row.readenddiff ? formatDiffTimes(row.readenddiff) : '',
        header: t('data.materialization_detail.logs_fields.read_end_diff'),
        size: 200
      },
      {
        accessorKey: 'writestartdiff',
        accessorFn: row => row.writestartdiff ? formatDiffTimes(row.writestartdiff) : '',
        header: t('data.materialization_detail.logs_fields.write_start_diff'),
        size: 200
      },
      {
        accessorKey: 'writeenddiff',
        accessorFn: row => row.writeenddiff ? formatDiffTimes(row.writeenddiff) : '',
        header: t('data.materialization_detail.logs_fields.write_end_diff'),
        size: 200
      },
      {
        accessorKey: 'enddiff',
        accessorFn: row => row.enddiff ? formatDiffTimes(row.enddiff) : '',
        header: t('data.materialization_detail.logs_fields.end_diff'),
        size: 200
      }
    ],
    renderDetailPanel: ({ row: { original: row } }) => row?.log ? (
      <pre style={{ margin: '0 8px 50px' }}>{row.log}</pre>
    ) : null,
    initialState: {
      sorting: [{
        id: 'startat',
        desc: true
      }]
    }
  }

  return (
    <DataGrid
      getData={getMaterializationLogs}
      getDataParams={{ id }}
      config={listConfig}
    />
  )
}
