import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AuthView from 'sections/auth/View'
import ResetPasswordForm from 'sections/auth/Form/ResetPassword'

export default function ResetPassword() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.reset_password.page_title')} | {t('project_name')}</title>
      </Helmet>

      <AuthView
        t={t}
        pageType="reset_password"
        Form={ResetPasswordForm}
      />
    </>
  )
}
