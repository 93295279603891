import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { t } from 'i18next'

import { thousandDetails, shortenLargeNumber } from 'utils'

import { useChart } from 'components/Chart'

ChartLine.propTypes = {
  data: PropTypes.object,
  grouprow: PropTypes.array,
}

export default function ChartLine({ data = {} , filtersDb = [], grouprow=[], handleChartClick}) {
  const series = data?.series?.map(item => ({
    name: item.name,
    data: data.data.map(_item => _item[item.name])
  })) || []

  const labels = data?.groupBY?.map(item => (
    data.data.map(_item => _item[item.name])
  )) || []

  const categories = labels?.length
    ? [...labels.reduce((a, b) => a.map((v, i) => `${v} / ${b[i]}`)).map(item => String(item))]
    : []

  const options = {
    noData: {
      text: t('empty_data')
    },
    grid: {
      show: false
    },
    chart: {
      background: '#fff',
      toolbar: {
        show: false
      },
      type: 'line',
      animations: {
        enabled: false
      },
      events: {
        click: async (event, chartContext, config) => {
          if (config.dataPointIndex !== -1) {
            handleChartClick(event, chartContext, config)
          }
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      distributed: true,
      style: {
        colors: ['#000'],
        fontSize: '10px'
      },
      formatter: val => thousandDetails(val)
    },
    tooltip: {
      y: {
        formatter: val => thousandDetails(val)
      }
    },
    yaxis: {
      labels: {
        formatter: num => shortenLargeNumber(num)
      }
    },
    xaxis: {
      categories,
      categoriesDb: categories,
      details: data?.groupBY,
      position: 'bottom',
      labels: {
        formatter: num => {
          if (data.groupBY[0].type === 'numeric' || data.groupBY[0].type === 'integer') { // grouprow
            return shortenLargeNumber(num)
          }
          return num
        }
      }
    }
  }

  const chartOptions = useChart(options)

  return (
    <ReactApexChart
      type="line"
      series={series}
      options={chartOptions}
      height={390}
    />
  )
}
