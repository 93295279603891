import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

import i18n from 'utils/i18n'

const SwitchAndOr = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-65%)',
      width: 8,
      height: 8,
      color: 'rgba(255, 255, 255, .5)',
      fontSize: 7
    },
    '&::before': {
      content: `"  ${i18n.t('and')}  "`,
      left: 12,
    },
    '&::after': {
      content: `"  ${i18n.t('or')}  "`,
      right: 12,
    },
  },
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        content: `"${i18n.t('or')}"`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, .3)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[600],
    width: 32,
    height: 32,
    '&::before': {
      content: `"${i18n.t('and')}"`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: '100%',
      transform: 'translateY(-75%)',
      textAlign: 'center',
      fontSize: 10
    },
  }
}))

export default SwitchAndOr
