import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Iconify from 'components/Iconify'
import FormDrawer from 'components/FormDrawer'

import { createConnection, updateConnection, validateConnection } from 'services/requests/connection'
import { Toast, enums } from 'utils'

const initialValues = {
  type: '',
  adapter: '',
  host: '',
  port: '',
  database: '',
  user: '',
  password: '',
}

export default function ConnectionForm({ open, data, onClose }) {
  const [filteredAdapters, setFilteredAdapters] = useState(enums.adapters)
  const [showPassword, setShowPassword] = useState(false)
  const [tested, setTested] = useState(false)

  const dbValidatorExtension = {
    is: adapter => !adapter?.startsWith('filelocal'),
    then: s => s.required(t('required')),
    otherwise: s => s
  }

  const validationSchema = yup.object({
    type: yup.string()
      .required(t('required')),
    adapter: yup.string()
      .required(t('required')),
    host: yup.string()
      .when('adapter', dbValidatorExtension),
    port: yup.string()
      .when('adapter', dbValidatorExtension),
    database: yup.string()
      .when('adapter', dbValidatorExtension),
    user: yup.string()
      .when('adapter', dbValidatorExtension),
    password: yup.string()
      .when('adapter', dbValidatorExtension)
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ type, adapter, host, port, database, user, password }, { setSubmitting }) => {
      const method = ((formik.values.adapter && !formik.values.adapter.startsWith('filelocal')) && !tested)
        ? validateConnection
        : data?.id ? updateConnection : createConnection

      let config
      if (!adapter.startsWith('filelocal')) {
        config = { host, port, database, user, password }
      }

      method({ id: data?.id, body: { type, adapter, config } })
        .then((res) => {
          if (adapter.startsWith('filelocal') || tested) {
            Toast(t('data.connections.form.success'), 'success')
            formik.setFieldValue('password', '')
            formik.setFieldTouched('password', false)
            setTested(false)
            handleClose(true)
          } else if (res?.valid) {
            Toast(t('data.connections.form.tested'), 'success')
            setTested(true)
          } else {
            Toast(t('data.connections.form.tested_invalid'), 'error')
          }
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChangeType = e => {
    formik.handleChange(e)

    const { value } = e.target
    let filtered = enums.adapters
    if (value === 'storage') {
      filtered = enums.adapters.filter(item => item.key === 'pg')
    }
    if (!filtered.find(item => item.key === formik.values.adapter)) {
      formik.setFieldValue('adapter', '')
    }
    setFilteredAdapters(filtered)
  }

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  useEffect(() => {
    if (data?.id) {
      handleChangeType({ target: { value: data.type, name: 'type' } })
      formik.setFieldValue('adapter', data.adapter)
      formik.setFieldValue('host', data.config?.host || '')
      formik.setFieldValue('port', data.config?.port || '')
      formik.setFieldValue('database', data.config?.database || '')
      formik.setFieldValue('user', data.config?.user || '')
      formik.setFieldValue('password', '')
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (tested) setTested(false)
    // eslint-disable-next-line
  }, [formik.values])

  const handleClose = (reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }

  return (
    <FormDrawer
      title={t(`data.connections.${data?.id? 'update' : 'new'}`)}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('data.connections.form.test'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting && !tested,
          disabled: tested,
          variant: 'outlined',
          condition: !formik.values.adapter?.startsWith('filelocal')
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting && (formik.values.adapter?.startsWith('filelocal') || tested),
          disabled: Boolean((formik.values.adapter && !formik.values.adapter.startsWith('filelocal')) && !tested),
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="type">{t('data.connections.form.type')}</InputLabel>
              <Select
                labelId="type"
                name="type"
                label={t('data.connections.form.type')}
                value={formik.values.type}
                onChange={handleChangeType}
                error={formik.touched.type && Boolean(formik.errors.type)}
              >
                {
                  enums.connectionTypes.map((item, key) => (
                    <MenuItem value={item.key} key={key}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.type && Boolean(formik.errors.type) ? (
                  <FormHelperText error>{formik.touched.type && formik.errors.type}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="adapter">{t('data.connections.form.database_type')}</InputLabel>
              <Select
                labelId="adapter"
                name="adapter"
                label={t('data.connections.form.database_type')}
                value={formik.values.adapter}
                onChange={handleChange}
                error={formik.touched.adapter && Boolean(formik.errors.adapter)}
              >
                {
                  filteredAdapters.map((item, key) => (
                    <MenuItem value={item.key} key={key}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.adapter && Boolean(formik.errors.adapter) ? (
                  <FormHelperText error>{formik.touched.adapter && formik.errors.adapter}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
          {
            formik.values.adapter && !formik.values.adapter.startsWith('filelocal') ? (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="host"
                    label={t('data.connections.form.host')}
                    value={formik.values.host}
                    onChange={handleChange}
                    error={formik.touched.host && Boolean(formik.errors.host)}
                    helperText={formik.touched.host && formik.errors.host}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="port"
                    label={t('data.connections.form.port')}
                    value={formik.values.port}
                    onChange={handleChange}
                    error={formik.touched.port && Boolean(formik.errors.port)}
                    helperText={formik.touched.port && formik.errors.port}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="database"
                    label={t('data.connections.form.database')}
                    value={formik.values.database}
                    onChange={handleChange}
                    error={formik.touched.database && Boolean(formik.errors.database)}
                    helperText={formik.touched.database && formik.errors.database}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="user"
                    label={t('data.connections.form.user')}
                    value={formik.values.user}
                    onChange={handleChange}
                    error={formik.touched.user && Boolean(formik.errors.user)}
                    helperText={formik.touched.user && formik.errors.user}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    name="password"
                    label={t('data.connections.form.password')}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    sx={{ width: '100%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null
          }
        </Grid>
      </Box>
    </FormDrawer>
  )
}

ConnectionForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
}
