import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Stack,
  Typography
} from '@mui/material'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

import MaterializationDetailView from 'sections/data/MaterializationDetail/View'
import MaterializationDetailLogs from 'sections/data/MaterializationDetail/Logs'

export default function Company() {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{t('data.materialization_detail.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <Typography variant="h4" color="primary" sx={{ width: 'max-content' }} className="link" onClick={() => navigate(-1)}>
              <Iconify icon="lets-icons:back" /> {t('data.materialization_detail.title')}
            </Typography>
            <Typography variant="body2">
              {t('data.materialization_detail.description')}
            </Typography>
            <Stack mt={5} spacing={5}>
              <MaterializationDetailView />
              <MaterializationDetailLogs />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
