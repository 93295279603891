import PropTypes from 'prop-types'
import {
  Drawer,
  Stack,
  Box,
  Grid,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

export default function FormDrawer({
  title,
  open,
  handleClose,
  actions = [],
  height = '92%',
  maxHeight = '92%',
  children
}) {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => handleClose?.()}
      PaperProps={{
        sx: { height, maxHeight, width: '100%' }
      }}
    >
      <Box width={'100%'} sx={{ background: 'white', p: 3, position: 'fixed', zIndex: 999 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={4} display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{ width: '100%', textAlign: { xs: 'center', sm: 'left' }, mb: { xs: 2, sm: 0 } }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" spacing={3}>
              {
                actions.map(({ condition = true, label, ...props }, key) => (
                  condition ? (
                    <LoadingButton
                      key={key}
                      size="large"
                      type="button"
                      sx={{ mr: key === 0 ? 1 : 0, ml: key === actions.length - 1 ? 1 : 0, width: { xs: '100%', sm: 'auto' } }}
                      {...props}
                    >
                      {label}
                    </LoadingButton>
                  ) : null
                ))
              }
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Stack spacing={3} sx={{ px: 3, mt: { xs: 18, sm: 13 }, pb: { xs: 3, sm: 6 } }}>
        {
          children
        }
      </Stack>
    </Drawer>
  )
}

FormDrawer.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  actions: PropTypes.array,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  children: PropTypes.node,
}
