import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback } from 'react'
import {
  Box,
  TextField,
  FormControl,
  FormHelperText,
  Autocomplete,
  Grid
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table'
import { getStageTableCascadeColumns, } from 'services/requests/stage'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  sortableKeyboardCoordinates
} from "@dnd-kit/sortable";
import {produce} from "immer";
import _ from "lodash";

import FormDrawer from 'components/FormDrawer'

import { createReport, updateReport, getReportsPreview } from 'services/requests/report'
import { Toast } from 'utils'

import ContainerFields, {SidebarField} from './components/ContainerFields'
import ContainerDnd from './components/ContainerDnd'
import { ItemDndOnDraging } from './components/SortableItem'

const initialFieldsReport = {
  tableColumns: [],
  rows: [],
  columns: [],
  filters: [],
  functions: []
}

const initialValues = {
  name: '',
  storageTable: null
}

function getData(prop) {
  return prop?.data?.current ?? {};
}
function createSpacer({ id, field }) {
  return {
    id,
    field,
    type: "spacer",
    title: "spacer"
  };
}

export default function ReportForm({ open, tables, dataForm, onClose }) {

  const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(
    Date.now()
  );
  const spacerInsertedRef = useRef();
  const currentDragFieldRef = useRef();
  const [activeSidebarField, setActiveSidebarField] = useState(); // only for fields from the sidebar
  const [activeField, setActiveField] = useState(); // only for fields that are in the form.

  const [columnsPreview, setColumnsPreview] = useState([])
  const [dataPreview, setDataPreview] = useState([])
  const table = useMaterialReactTable({
    columns: columnsPreview,
    data: dataPreview,
    rowCount: 100,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false
  })
  const [refreshData, setRefreshData] = useState(false)
  const [fieldsReport, setFieldsReport] = useState(initialFieldsReport);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const validationSchema = yup.object({
    name: yup.string()
      .required(t('required')),
    storageTable: yup.object()
      .required(t('required'))
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ name, storageTable }, { setSubmitting }) => {
      const config = { ...fieldsReport }
      delete config.tableColumns

      const body = {
        name,
        connectionStorageId: storageTable.connectionId,
        storageTable: storageTable.table,
        config
      }

      const method = dataForm?.id ? updateReport : createReport

      method({ id: dataForm?.id, body })
        .then(() => {
          Toast(t('visualization.reports.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .finally(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeStorageTable = async (_, value) => {
    formik.setFieldTouched('storageTable')
    formik.setFieldValue('storageTable', value)

    const response = await getStageTableCascadeColumns({ connectionId: value.connectionId, table: value.table })

    setFieldsReport(produce((draft) => ({
      ...draft,
      tableColumns: response || []
    })));
  }
  const refreshPreview = async () => {
    const body = {
      "name": "preview",
      "storageTable": formik.values.storageTable.table,
      "connectionStorageId": formik.values.storageTable.connectionId,
      "config": {
        "rows": fieldsReport.rows,
        "functions": fieldsReport.functions,
        "columns": fieldsReport.columns,
        "filters": fieldsReport.filters,
      },
    }
    const itens = await getReportsPreview({ body });
    setColumnsPreview(itens?.fields || [])
    setDataPreview(itens?.data || []);
    setRefreshData(false);
  }

  const fillEditForm = async () => {
    formik.setFieldValue('name', dataForm.name)
    await handleChangeStorageTable('storageTable', { connectionId: dataForm.connectionStorage.id, table: dataForm.storageTable })

    setFieldsReport(produce((draft) => ({
      ...draft,
      ...dataForm.config
    })));
    setRefreshData(true)
  }

  const handleClose = useCallback((reload) => {
    formik.resetForm(initialValues)
    setFieldsReport(initialFieldsReport)
    setRefreshData(false)
    setColumnsPreview([])
    setDataPreview([])
    onClose(reload)
  }, [formik, onClose])

  const cleanUp = () => {
    setActiveSidebarField(null);
    setActiveField(null);
    currentDragFieldRef.current = null;
    spacerInsertedRef.current = false;
  };

  const handleDragStart = (e) => {
    const { active } = e;
    const activeData = getData(active);
    if (activeData.fromSidebar) {
      const { field } = activeData;
      setActiveSidebarField(field);
      currentDragFieldRef.current = {
        id: active.id,
        field,
        name: `${field.id}${fieldsReport.tableColumns.length + 1}`,
        parent: null
      };
      return;
    }
    const { index } = activeData;

    setActiveField(activeData);
    currentDragFieldRef.current = activeData;

    setFieldsReport(produce((draft) => {
      draft[activeData.typeParrent].splice(index, 1, createSpacer({ id: active.id, field: activeData.field}));
    }));
  }
  const handleDragOver = (e) => {
    const { active, over } = e;
    const activeData = getData(active);
    const overData = getData(over);
    const arraItens =['rows', 'columns', 'filters', 'functions'];
    // if (activeData.fromSidebar) {
      if (over) {
        if(fieldsReport[overData.typeParrent].filter((f) => {return f.type === "spacer"}).length === 0){
          const spacer = createSpacer({
            id: `${active.id  }-spacer`,
            field: activeData.field
          });
          spacer.field = activeData.field;

          setFieldsReport(produce((draft) => {
            const loopRemoveSpacer = _.remove(arraItens, (currentObject) => (
              currentObject !== overData.typeParrent
            ));
            loopRemoveSpacer.forEach( async (plocalFunction) => {
              draft[plocalFunction] = draft[plocalFunction].filter((f) => f.type !== "spacer");
            })
            if (!draft[overData.typeParrent].length) {
              draft[overData.typeParrent].push(spacer);
            } else {
              const nextIndex =
                overData.index > -1 ? overData.index : draft[overData.typeParrent].length;

              draft[overData.typeParrent].splice(nextIndex, 0, spacer);
            }
          }));
        } else {
          setFieldsReport(produce((draft) => {
            const spacerIndex = draft[overData.typeParrent].findIndex((f) => f.type === "spacer");
            const nextIndex =
              overData.index > -1 ? overData.index : draft[overData.typeParrent].length - 1;
              draft[overData.typeParrent] = arrayMove(draft[overData.typeParrent], nextIndex , spacerIndex);
          }))
        }
      } else if (!over) {
        console.log('2222');
        setFieldsReport(produce((draft) => {
          draft.rows = draft.rows.filter((f) => f.type !== "spacer");
          draft.columns = draft.columns.filter((f) => f.type !== "spacer");
          draft.filters = draft.filters.filter((f) => f.type !== "spacer");
          draft.functions = draft.functions.filter((f) => f.type !== "spacer");
        }));
        // spacerInsertedRef.current = false;
      } else {
        console.log('3333');
        setFieldsReport(produce((draft) => {
          const loopRemoveSpacer = _.remove(arraItens, (currentObject) => (
            currentObject !== overData.typeParrent
          ));
          loopRemoveSpacer.forEach( async (plocalFunction) => {
            draft[plocalFunction] = draft[plocalFunction].filter((f) => f.type !== "spacer");
          })
          const spacerIndex = draft[overData.typeParrent].findIndex((f) => f.type === "spacer");
          const nextIndex =
            overData.index > -1 ? overData.index : draft[overData.typeParrent].length - 1;
          if (nextIndex === spacerIndex) {
            return;
          }
          draft[overData.typeParrent] = arrayMove(draft[overData.typeParrent], spacerIndex, overData.index);
        }));
      }
    // }
    // else {
    //   console.log('&&&&&&&')
    // }
  }
  const handleDragEnd = (e) => {
    const { over, active } = e;
    if (!over) {
      const activeData = getData(active);
      const keyField=activeData.id
      cleanUp();

      setFieldsReport(produce((draft) => {
        draft.rows = draft.rows.filter((f) => f.type !== "spacer");
        draft.columns = draft.columns.filter((f) => f.type !== "spacer");
        draft.filters = draft.filters.filter((f) => f.type !== "spacer");
        draft.functions = draft.functions.filter((f) => f.type !== "spacer");
        if(activeData.typeParrent){
          draft[activeData.typeParrent] = draft[activeData.typeParrent].filter((f) => f.id !== keyField);
        }
      }));
      return;
    }
    const nextField = currentDragFieldRef.current;
    if (nextField) {
      const overData = getData(over);
      setFieldsReport(produce((draft) => {
        const spacerIndex = draft[overData.typeParrent].findIndex((f) => f.type === "spacer");
        draft[overData.typeParrent].splice(spacerIndex, 1, nextField);
        const toVar = overData.index ? overData.index : 0
        draft[overData.typeParrent] = arrayMove(
          draft[overData.typeParrent],
          spacerIndex,
          toVar
        );
      }));
    }
    setSidebarFieldsRegenKey(Date.now());
    cleanUp();

  }
  useEffect(() => {
    if (dataForm?.id) {
      fillEditForm()
    }
    // eslint-disable-next-line
  }, [dataForm])

  useEffect(() => {
    (async () => {
      if (refreshData === true) {
        refreshPreview()
      }
    })()
    // eslint-disable-next-line
  }, [refreshData])

  // useEffect(() => {
  //   (async () => {
  //     setFieldsReport((draft) => {

  //     })
  //     console.log(updateFieldItem);
  //   })()
  // }, [])

  return (
    <FormDrawer
      title={t(`visualization.reports.${dataForm?.id ? 'update' : 'new'}`)}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: () => formik.handleSubmit(),
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              name="name"
              label={t('visualization.reports.form.name')}
              value={formik.values.name}
              onChange={handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                disableClearable
                name="storageTable"
                value={formik.values.storageTable}
                onChange={handleChangeStorageTable}
                disabled={!tables?.length}
                options={tables}
                isOptionEqualToValue={(option, value) => (option?.table === value?.table)}
                getOptionLabel={option => option.table || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('visualization.reports.form.storage_table')}
                    error={formik.touched.storageTable && Boolean(formik.errors.storageTable)}
                  />
                )}
              />
              {
                formik.touched.storageTable && Boolean(formik.errors.storageTable) ? (
                  <FormHelperText error>{formik.touched.storageTable && formik.errors.storageTable}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={3}>
          <DndContext
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            // collisionDetection={closestCenter}
          >
            <Grid item xs={4}>
              <ContainerFields
                key={'dados-drop'}
                fieldsRegKey={sidebarFieldsRegenKey}
                type="data"
                name="Dados"
                height={563}
                items={fieldsReport.tableColumns}
              />
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ContainerDnd
                      id="rows"
                      type="rows"
                      name="Linhas"
                      description="Arraste os campos para esta opção para criar um relatório tabular (linhas). Todos os campos selecionados neste rótulo virão como linhas no relatório dinâmico."
                      height={250}
                      items={fieldsReport.rows}
                      setRefreshData={setRefreshData}
                      fieldsReport={fieldsReport}
                      setFieldsReport={setFieldsReport}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ContainerDnd
                      id="columns"
                      type="columns"
                      name="Colunas"
                      description="Arraste os campos para esta opção para criar um relatório matricial (cross table). Todos os campos selecionados neste rótulo virarão colunas no relatório dinâmico. É importante ressaltar que esta opção deverá ser utilizada em conjunto com a opção Funções do relatório dinâmico. Caso não seja utilizado em conjunto, o conteúdo do campo selecionado virará também as linhas do resultado."
                      height={250}
                      items={fieldsReport.columns}
                      setRefreshData={setRefreshData}
                      fieldsReport={fieldsReport}
                      setFieldsReport={setFieldsReport}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ContainerDnd
                      id="filters"
                      type="filters"
                      name="Filtros"
                      description="Arraste os campos para esta opção para criar filtros no relatório. Todos os campos poderão ser tratados com os operadores: Igual [=], Maior ou igual (>=], Menor ou igual [<=], Diferente [<>] ou Contém [%%]. Esta opção também permite que vários filtros sejam realizados utilizando-se as opções [E] ou [OU]."
                      height={250}
                      storageTable={formik.values.storageTable}
                      items={fieldsReport.filters}
                      setRefreshData={setRefreshData}
                      fieldsReport={fieldsReport}
                      setFieldsReport={setFieldsReport}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ContainerDnd
                      id="functions"
                      type="functions"
                      name="Funções"
                      description="Arraste os campos para esta opção para criar linhas de funções para o resultado do relatório. Estão disponíveis as funções de Contagem [Contar], Somatorios [Somar] e Média [Média]. É importante ressaltar que as opções [Somar], (Média], [Máximo] e [Mínimo] so serão aplicáveis aos campos do tipo Numéricos."
                      height={250}
                      storageTable={formik.values.storageTable}
                      items={fieldsReport.functions}
                      setRefreshData={setRefreshData}
                      fieldsReport={fieldsReport}
                      setFieldsReport={setFieldsReport}
                    />
                  </Grid>
                </Grid>
            </Grid>
            <DragOverlay dropAnimation={false}>
              {activeSidebarField ? (
                <SidebarField overlay field={activeSidebarField} />
              ) : null}
              {activeField ? <ItemDndOnDraging overlay field={activeField} /> : null}
            </DragOverlay>
          </DndContext>
        </Grid>
        <Grid container spacing={2} mt={3}>
          <Grid item xs={12}>
            <MaterialReactTable
              table={table}
            />
          </Grid>
        </Grid>
      </Box>
    </FormDrawer>
  )
}

ReportForm.propTypes = {
  open: PropTypes.bool,
  dataForm: PropTypes.object,
  tables: PropTypes.array,
  data: PropTypes.object,
  onClose: PropTypes.func
}
