import PropTypes from 'prop-types'
import { useState, useContext, Fragment } from 'react'
import {
  Box,
  Grid,
  Stack,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider
} from '@mui/material'
import { t } from 'i18next'

import FormDrawer from 'components/FormDrawer'
import Iconify from 'components/Iconify'

import { UserContext } from 'contexts'
import { createInvite } from 'services/requests/invite'
import { Toast, roles } from 'utils'

export default function InviteForm({ open, onClose, profiles = [], companyId = null }) {
  const { state: { data: { profile: { role: currentUserRole } } } } = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [errorEmailRequired, setErrorEmailRequired] = useState(false)
  const [errorEmailValid, setErrorEmailValid] = useState(false)
  const [errorProfileRequired, setErrorProfileRequired] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const handleChangeProfile = id => {
    const indexToRemove = selectedProfiles.findIndex(item => item === id)

    if (indexToRemove > -1) {
      const newArr = [...selectedProfiles]
      newArr.splice(indexToRemove, 1)
      setSelectedProfiles([...newArr])
    } else {
      setSelectedProfiles([
        ...selectedProfiles,
        id
      ])
    }

    if (errorProfileRequired) {
      setErrorProfileRequired(false)
    }
  }

  const handleChangeEmail = email => {
    setEmail(email)

    if (errorEmailRequired) {
      setErrorEmailRequired(false)
    }

    if (errorEmailValid) {
      setErrorEmailValid(false)
    }
  }

  const onSubmit = () => {
    const validEmail = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g.test(email)

    if (!email) {
      setErrorEmailRequired(true)
    }

    if (!validEmail) {
      setErrorEmailValid(true)
    }

    if (!selectedProfiles?.length) {
      setErrorProfileRequired(true)
    }

    if (!email || !validEmail || !selectedProfiles?.length) {
      return
    }

    setLoadingSubmit(true)

    const body = {
      email,
      profilesId: selectedProfiles,
      companyId: companyId || undefined
    }

    createInvite({ body })
      .then(() => {
        Toast(t('settings.invites.form.success'), 'success')
        handleClose(true)
      })
      .catch((err) => {
        Toast(err, 'error')
        setLoadingSubmit(false)
      })
  }

  const handleClose = (reload) => {
    setEmail('')
    setSelectedProfiles([])
    setLoadingSubmit(false)
    onClose(reload)
  }

  return (
    <FormDrawer
      title={t('settings.invites.new')}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: onSubmit,
          loading: loadingSubmit,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        {
          profiles.length ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="name"
                  label={t('settings.invites.form.email')}
                  value={email}
                  onChange={e => handleChangeEmail(e.target.value)}
                  error={errorEmailRequired || errorEmailValid}
                  helperText={errorEmailRequired ? t('settings.invites.form.error_email_required') : errorEmailValid ? t('settings.invites.form.error_email_valid') : null}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="subtitle1"
                  my={3}
                >
                  {t('settings.invites.form.profiles')}
                </Typography>

                <FormControl sx={{ width: '100%' }}>
                  <FormGroup>

                    <Grid container spacing={2}>
                      {
                        profiles.map((item, key) => (
                          <Grid item xs={12} sm={4} md={4} key={key}>
                            <FormControlLabel
                              checked={selectedProfiles.includes(item.id)}
                              value={item.id}
                              control={<Checkbox color="primary" />}
                              onChange={(e) => handleChangeProfile(e.target.value)}
                              label={(
                                <Stack my={1}>
                                  <Typography variant="subtitle1">{`${item.name}`}</Typography>
                                  <Typography variant="body2">{`(${t(`roles.${item.role}`)})`}</Typography>
                                </Stack>
                              )}
                              labelPlacement="end"
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </FormGroup>
                  {
                    errorProfileRequired ? (
                      <Typography variant="body2" color="error" mt={1}>
                        {t('settings.invites.form.error_profiles_required')}
                      </Typography>
                    ) : null
                  }
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Typography
              variant="subtitle1"
              mb={3}
            >
              <Iconify icon="mdi:alert" /><br />
              {t('settings.invites.form.not_profiles')}
            </Typography>
          )
        }

        <Box mt={3}>
          <Divider />

          <Grid container spacing={2}>
            {
              roles
                .filter(item => (
                  (currentUserRole === 'superadmin' && item !== 'client') ||
                  (currentUserRole === 'client' && item !== 'superadmin') ||
                  (currentUserRole === 'editor' && item === 'viewer')
                ))
                .map((item, key) => (
                  <Grid item xs={12} sm={4} md={4} key={key}>
                    <Typography variant="subtitle2" mb={1} mt={3}>
                      {t(`roles.${item}`)}
                    </Typography>
                    <Typography color="gray">
                      {
                        t(`roles.${item}_description`).split('\n')
                          .map((_item, _key) => <Fragment key={`${key}-${_key}`}>{_item}<br /></Fragment>)
                      }
                    </Typography>
                  </Grid>
                ))
            }
          </Grid>
        </Box>
      </Box>
    </FormDrawer>
  )
}

InviteForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  profiles: PropTypes.array,
  companyId: PropTypes.string
}
