import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

// import { getIndicators } from 'services/requests/indicators'

import IndicatorForm from './Form'

export default function IndicatorsView({ newItem, cancelNew }) {
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('visualization.indicators.fields.name')
      },
      {
        accessorKey: 'fact',
        header: t('visualization.indicators.fields.fact')
      },
      {
        accessorKey: 'data_type',
        header: t('visualization.indicators.fields.data_type')
      },
      {
        accessorKey: 'data_unit',
        header: t('visualization.indicators.fields.data_unit')
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('visualization.indicators.update'),
        onClick: item => setSelectedData(item)
      }
    ],
    onRowClick: row => setSelectedData(row)
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={() => []} // getIndicators
        config={listConfig}
        ref={dataGridRef}
      />

      <IndicatorForm
        open={(!!selectedData?.id || newItem)}
        data={selectedData}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
      />
    </Box>
  )
}

IndicatorsView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

