import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AuthView from 'sections/auth/View'
import ForgotPasswordForm from 'sections/auth/Form/ForgotPassword'

export default function ForgotPassword() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.forgot_password.page_title')} | {t('project_name')}</title>
      </Helmet>

      <AuthView
        t={t}
        pageType="forgot_password"
        Form={ForgotPasswordForm}
      />
    </>
  )
}
