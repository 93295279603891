import PropTypes from 'prop-types'
import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material'

import { UserContext } from 'contexts'

import Logo from 'components/Logo'
import NavSection from 'components/DashNavSection'

import menuConfig from 'routes/config'
import { getPanelsDashboard } from 'services/requests/panel'
import { Toast, useResponsive } from 'utils'

import {
  StyledScrollbar,
  StyledLogo,
  StyledAccount
} from './styled'

const NAV_WIDTH = 280

export default function Nav({ openNav, onCloseNav }) {
  const { state: { data: userData } } = useContext(UserContext)

  const { pathname } = useLocation()

  const isDesktop = useResponsive('up', 'lg')

  const [menuConfigLinks, setMenuConfigLinks] = useState([])
  const [companyProfile, setCompanyProfile] = useState(null)

  const getPanelsAvailable = async () => getPanelsDashboard()
    .then(res => [...res.map(item => ({
      title: item.name,
      path: `painel/${item.id}`,
      icon: 'vaadin:chart-grid'
    }))])
    .catch((err) => Toast(err, 'error'))

  const mountMenu = async () => {
    setCompanyProfile({
      company: userData.company.fantasyName,
      profile: userData.profile.name
    })

    if (userData.company.isApproved !== null) {
      const panels = await getPanelsAvailable() || []

      const subMenus = menuConfig.map(item => (
        item.items.filter(_item => _item.roles.includes(userData.profile.role))
      ))
      const filterdMenu = menuConfig
        .map((item, key) => ({
          ...item,
          items: item.title === 'nav_config.panels.index' ? panels : subMenus[key]
        }))
        .filter(item => item.items.length > 0)

      setMenuConfigLinks(filterdMenu)
    }
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (userData.id) {
      mountMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const renderContent = (
    <StyledScrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <StyledLogo>
        <Logo authHeight />
      </StyledLogo>

      <Box sx={{ mb: 3, mx: 2.5, width: 240 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={null} alt={companyProfile?.company} variant="rounded">
              {companyProfile?.company.slice(0, 1)}
            </Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary', width: 150 }} noWrap>
                {companyProfile?.company}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary', width: 150 }} noWrap>
                {companyProfile?.profile}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={menuConfigLinks} />
    </StyledScrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}
