import PropTypes from 'prop-types'

import Line from './_Line'
import Bar from './_Bar'
import Column from './_Column'
import TreeMap from './_TreeMap'
import Table from './_Table'
import BigNumber from './_BigNumber'

const ComponentsRender = {
  line: props => <Line {...props} />,
  bar: props => <Bar {...props} />,
  column: props => <Column {...props} />,
  treemap: props => <TreeMap {...props} />,
  table: props => <Table {...props} />,
  big_number: props => <BigNumber {...props} />
}

export default function Visualization({ data, render }) {
  return (
    ComponentsRender?.[render]
      ? ComponentsRender[render]({ data, render })
      : null
  )
}

Visualization.propTypes = {
  data: PropTypes.object,
  render: PropTypes.string,
}
