import PropTypes from 'prop-types'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import FormDrawer from 'components/FormDrawer'

const initialValues = {}

export default function IndicatorForm({ open, data, onClose }) {
  const validationSchema = yup.object({})

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (body, { setSubmitting }) => {
      setSubmitting(false)
    },
  })

  const handleClose = (reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }

  return (
    <FormDrawer
      title={t(`visualization.indicators.${data?.id? 'update' : 'new'}`)}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        FORM INDICATOR
      </Box>
    </FormDrawer>
  )
}

IndicatorForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
}
