import PropTypes from 'prop-types'
import { Fragment, useState, useEffect, useContext } from 'react'
import {
  Drawer,
  Box,
  Button,
  Stack,
  Avatar,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Divider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'

import ACL from 'components/ACL'

import { UserContext } from 'contexts'
import { updateUserCompany } from 'services/requests/userCompany'
import { Toast, roles } from 'utils'

export default function UserForm({ user, onClose, profiles = [] }) {
  const { state: { data: currentUser }, exec } = useContext(UserContext)

  const [isActive, setIsActive] = useState(false)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [errorProfileRequired, setErrorProfileRequired] = useState(false)
  const [errorProfileAdmin, setErrorProfileAdmin] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (user?.id) {
      setIsActive(user.userCompany[0].isActive)

      const profilesIds = user.userCompany[0].profiles.map(item => item.id)
      setSelectedProfiles(profilesIds)
    }
  }, [user])

  const handleChangeProfile = id => {
    const indexToRemove = selectedProfiles.findIndex(item => item === id)

    if (indexToRemove > -1) {
      const newArr = [...selectedProfiles]
      newArr.splice(indexToRemove, 1)
      setSelectedProfiles([...newArr])
    } else {
      setSelectedProfiles([
        ...selectedProfiles,
        id
      ])
    }

    if (errorProfileRequired) {
      setErrorProfileRequired(false)
    }

    if (errorProfileAdmin) {
      setErrorProfileAdmin(false)
    }
  }

  const onSubmit = () => {
    if (!selectedProfiles?.length) {
      setErrorProfileRequired(true)
      return
    }

    if (user.id === currentUser.id && (currentUser.profile.role === 'superadmin' || currentUser.profile.role === 'client')) {
      const adminSelected = profiles.find(item => selectedProfiles.includes(item.id) && (item.role === 'superadmin' || item.role === 'client'))
      if (!adminSelected) {
        setErrorProfileAdmin(currentUser.profile.role === 'superadmin' ? 'superadmin' : 'client')
        return
      }
    }

    setLoadingSubmit(true)

    const body = {
      profilesId: selectedProfiles
    }

    const itsMe = currentUser.id === user.id
    if (!itsMe) {
      body.isActive = isActive
    }

    updateUserCompany({ id: user.userCompany[0].id, body })
      .then(() => {
        Toast(t('settings.users.form.success'), 'success')

        if (itsMe) {
          exec.getCurrentUser()
        }

        handleClose(true)
      })
      .catch((err) => {
        Toast(err, 'error')
        setLoadingSubmit(false)
      })
  }

  const handleClose = (reload) => {
    setIsActive(false)
    setSelectedProfiles([])
    setLoadingSubmit(false)
    onClose(reload)
  }

  const availableChangeIsActive = () => {
    const profilesIds = profiles.map(item => item.id)
    const profileUnvailable = user?.userCompany[0].profiles.findIndex(item => !profilesIds.includes(item.id))
    return profileUnvailable === -1 && currentUser.id !== user.id
  }

  return (
    <Drawer
      anchor="right"
      open={!!user?.id}
      onClose={() => handleClose()}
      PaperProps={{
        sx: { width: '90%', maxWidth: '400px', px: 3, py: 6 }
      }}
    >
      {
        user?.id ? (

          <Stack spacing={3}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                pb: 3
              }}
            >
              <Avatar
                src={null}
                alt={user.name}
                sx={{
                  height: 80,
                  mb: 2,
                  width: 80
                }}
              >
                {user.name.slice(0, 1)}
              </Avatar>

              <Typography
                gutterBottom
                variant="h5"
              >
                {user.name}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body1"
              >
                {user.email}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle1"
                mb={2}
              >
                {t('settings.users.form.profiles')}
              </Typography>

              <FormControl>
                <FormGroup>
                  {
                    profiles.map((item, key) => (
                      <FormControlLabel
                        key={key}
                        checked={selectedProfiles.includes(item.id)}
                        value={item.id}
                        control={<Checkbox color="primary" />}
                        onChange={(e) => handleChangeProfile(e.target.value)}
                        label={(
                          <Stack my={1}>
                            <Typography variant="subtitle1">{`${item.name}`}</Typography>
                            <Typography variant="body2">{`(${t(`roles.${item.role}`)})`}</Typography>
                          </Stack>
                        )}
                        labelPlacement="end"
                      />
                    ))
                  }
                </FormGroup>
                {
                  errorProfileRequired ? (
                    <Typography variant="body2" color="error" mt={1}>
                      {t('settings.users.form.error_profiles_required')}
                    </Typography>
                  ) : null
                }
                {
                  errorProfileAdmin ? (
                    <Typography variant="body2" color="error" mt={1}>
                      {t(`settings.users.form.error_profiles_${errorProfileAdmin}`)}
                    </Typography>
                  ) : null
                }
              </FormControl>
            </Box>

            <Box>
              <Divider />

              {
                roles
                  .filter(item => (
                    (currentUser.role === 'superadmin' && item !== 'client') ||
                    (currentUser.role === 'client' && item !== 'superadmin') ||
                    (currentUser.role === 'editor' && item === 'viewer')
                  ))
                  .map((item, key) => (
                    <Fragment key={key}>
                      <Typography variant="subtitle2" mb={1} mt={3}>
                        {t(`roles.${item}`)}
                      </Typography>
                      <Typography color="gray">
                        {
                          t(`roles.${item}_description`).split('\n')
                            .map((_item, _key) => <Fragment key={`${key}-${_key}`}>{_item}<br /></Fragment>)
                        }
                      </Typography>
                    </Fragment>
                  ))
              }
            </Box>

            <ACL condition={availableChangeIsActive()}>
              <Divider />

              <Typography variant="subtitle1">
                {t('settings.users.form.active')}
              </Typography>

              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    checked={isActive}
                    control={<Switch color="primary" />}
                    onChange={(e) => setIsActive(e.target.checked)}
                    label={t('settings.users.form.is_active')}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </ACL>

            <Box display="flex" justifyContent="flex-end" spacing={3}>
              <Button
                size="large"
                type="submit"
                sx={{ mt: 2, mr: 1, width: { xs: '100%', md: 'auto' } }}
                color="error"
                onClick={() => handleClose()}
              >
                {t('cancel')}
              </Button>

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                sx={{ mt: 2, ml: 1, width: { xs: '100%', md: 'auto' } }}
                onClick={() => onSubmit()}
                loading={loadingSubmit}
              >
                {t('submit')}
              </LoadingButton>
            </Box>
          </Stack>
        ) : null
      }
    </Drawer>
  )
}

UserForm.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  profiles: PropTypes.array
}
