import { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { t } from 'i18next'

import ACL, { isAuthorized } from 'components/ACL'
import DataGrid from 'components/DataGrid'

import { getUsers } from 'services/requests/user'
import { getProfiles } from 'services/requests/profile'
import { Toast, enums } from 'utils'

import UserForm from './Form'

export default function UsersView() {
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)
  const [profiles, setProfiles] = useState([])

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('settings.users.fields.name')
      },
      {
        accessorKey: 'email',
        header: t('settings.users.fields.email')
      },
      {
        accessorKey: 'userCompany.profiles.name',
        accessorFn: row => row.userCompany[0].profiles.map(item => item.name).join(', '),
        header: t('settings.users.fields.profiles'),
        filterVariant: 'select',
        filterSelectOptions: profiles.map(item => item.name)
      },
      {
        accessorKey: 'userCompany.isActive',
        accessorFn: row => t(`${row.userCompany[0].isActive ? 'active' : 'inactive'}`),
        header: t('settings.users.fields.is_active'),
        filterVariant: 'select',
        filterSelectOptions: enums.active
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('settings.users.edit_permissions'),
        onClick: item => setSelectedData(item)
      }
    ],
    onRowClick: row => setSelectedData(row)
  }

  const getDataProfiles = () => {
    getProfiles()
      .then(res => setProfiles(res))
      .catch(() => Toast('Não foi possível obter os perfís disponíveis'))
  }

  useEffect(() => {
    const authorized = isAuthorized({ roles: ['superadmin', 'client', 'editor'] })

    if (authorized) {
      getDataProfiles()
    }
  }, [])

  return (
    <Box mt={3}>
      <DataGrid
        getData={getUsers}
        config={listConfig}
        ref={dataGridRef}
      />

      <ACL roles={['superadmin', 'client', 'editor']}>
        <UserForm
          user={selectedData}
          onClose={(reload) => {
            setSelectedData(null)
            if (reload) {
              dataGridRef?.current.refreshData()
            }
          }}
          profiles={profiles}
        />
      </ACL>
    </Box>
  )
}
