import { API } from 'services'

export function getCurrentUser(params) {
  return API({
    endpoint: '/user/me',
    ...params
  })
}

export function updateCurrentUser(params) {
  return API({
    method: 'PUT',
    endpoint: '/user/me',
    ...params
  })
}

export function changeUserPreferences(params) {
  return API({
    method: 'PUT',
    endpoint: '/user/preference',
    ...params
  })
}

export function getUsers(params) {
  const { companyId = null, ...rest } = params || {}

  return API({
    endpoint: `/user${companyId ? `/companyId/${companyId}` : ''}`,
    ...rest
  })
}
