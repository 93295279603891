import PropTypes from 'prop-types'
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table'

export default function ViewTable({ data }) {
  const table = useMaterialReactTable({
    columns: data?.fields || [],
    data: data?.data || [],
    rowCount: 100,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false
  })

  return (
    <div className="datagrid-panel">
      <MaterialReactTable
        table={table}
      />
    </div>
  )
}

ViewTable.propTypes = {
  data: PropTypes.object,
}
