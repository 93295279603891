export const objectToFormData = (obj, formData = new FormData(), parentKey = '') => {
  Object.entries(obj).forEach(([key, value]) => {
    const formKey = parentKey ? `${parentKey}.${key}` : key

    if (value instanceof File) {
      formData.append(formKey, value)
    } else if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
      objectToFormData(value, formData, formKey)
    } else {
      formData.append(formKey, value)
    }
  })

  return formData
}
