import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'

import { Storage } from 'services'

export const AppThemeContext = createContext(null)

AppThemeProvider.propTypes = {
  children: PropTypes.node
}

export function AppThemeProvider({ children }) {
  const cookie = new Cookies()

  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState(null)

  const exec = {
    changeTheme(params) {
      setData(params)
      return true
    }
  }

  const getInitialData = async () => {
    if (cookie.get('theme')) {
      const theme = Storage.get({ key: '-theme' })

      if (theme) {
        setData(theme)
        setInitialized(true)
      }
    } else {
      setData(null)
      Storage.delete({ key: '-theme' })
    }

    setInitialized(true)
  }

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppThemeContext.Provider value={{ state: { initialized, data }, exec }}>
      {children}
    </AppThemeContext.Provider>
  )
}
