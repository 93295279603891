import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'
import { useDialog } from 'components/Dialog'

import { deletePanel, getPanels, getProfiles, updatePanel } from 'services/requests/panel'
import { Toast, enums } from 'utils'

import PanelProfilesForm from './FormProfiles'

export default function PanelsView() {
  const navigate = useNavigate()
  const dataGridRef = useRef(null)
  const dialog = useDialog()

  const [profiles, setProfiles] = useState([])
  const [selectedData, setSelectedData] = useState(null)

  const handleUpdatePanel = (id, body) => {
    updatePanel({ id, body })
      .then(() => {
        dataGridRef?.current.refreshData()
        Toast(t(`visualization.panels.enable_turn_${body.enable ? 'on' : 'off'}_success`), 'success')
      }).catch(err => {
        Toast(err, 'error')
      })
  }

  const handleDeletePanel = row => {
    dialog({
      title: t('visualization.panels.delete_title'),
      description: t('visualization.panels.delete_description', { name: row.name})
    })
      .then(() => {
        deletePanel({ id: row.id })
          .then(() => {
            dataGridRef?.current.refreshData()
            Toast(t('visualization.panels.delete_success'), 'success')
          }).catch(err => {
            Toast(err, 'error')
          })
      })
      .catch(() => { })
  }

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('visualization.panels.fields.name')
      },
      {
        accessorKey: 'type',
        accessorFn: row => t(`panel_types.${row.type}`),
        header: t('visualization.panels.fields.type'),
        filterVariant: 'select',
        filterSelectOptions: enums.panelTypes
      },
      {
        accessorKey: 'enable',
        header: t('visualization.panels.fields.enable'),
        filterVariant: 'checkbox',
        Cell: ({ cell }) => cell.getValue() === true ? t('active') : t('inactive'),
        size: 80
      },
      {
        accessorKey: 'profiles',
        accessorFn: row => row.profiles.length > 0 ? row.profiles.map(item => item.name).join(', ') : t('visualization.panels.profiles_all'),
        header: t('visualization.panels.fields.profiles'),
        filterVariant: 'select',
        filterSelectOptions: profiles.map(item => item.name)
      }
    ],
    actions: [
      {
        icon: 'mdi:eye-off',
        label: t('visualization.panels.enable_turn_off'),
        onClick: row => handleUpdatePanel(row.id, { enable: !row.enable }),
        condition: row => row.enable
      },
      {
        icon: 'mdi:eye',
        label: t('visualization.panels.enable_turn_on'),
        onClick: row => handleUpdatePanel(row.id, { enable: !row.enable }),
        condition: row => !row.enable
      },
      {
        icon: 'mdi:users-group',
        label: t('visualization.panels.profiles_update'),
        onClick: row => setSelectedData(row)
      },
      {
        icon: 'mdi:pencil',
        label: t('visualization.panels.update'),
        onClick: row => navigate(`/visualizacao/paineis/${row.id}`)
      },
      {
        icon: 'mdi:trash',
        label: t('visualization.panels.delete'),
        onClick: row => handleDeletePanel(row)
      }
    ],
    onRowClick: row => navigate(`/visualizacao/paineis/${row.id}`)
  }

  const getDataProfiles = () => {
    getProfiles()
      .then(res => {
        setProfiles(res)
      })
      .catch(err => {
        Toast(err, 'error')
      })
  }
  useEffect(() => {
    getDataProfiles()
  }, [])

  return (
    <Box mt={5}>
      <DataGrid
        getData={getPanels}
        config={listConfig}
        ref={dataGridRef}
      />

      <PanelProfilesForm
        panel={selectedData}
        onClose={(reload) => {
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
        profiles={profiles}
      />
    </Box>
  )
}
