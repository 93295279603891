// util to translate outside React component
import i18n from './i18n'

const myEnums = {}

function fill() {
  // enums

  // roles
  myEnums.roles = [{
    key: 'superadmin',
    label: i18n.t('roles.superadmin'),
  }, {
    key: 'client',
    label: i18n.t('roles.client'),
  }, {
    key: 'editor',
    label: i18n.t('roles.editor'),
  }, {
    key: 'viewer',
    label: i18n.t('roles.viewer'),
  }]

  // active
  myEnums.active = [{
    key: 'active',
    label: i18n.t('active'),
  }, {
    key: 'inactive',
    label: i18n.t('inactive'),
  }]

  // invite status
  myEnums.inviteStatus = [{
    key: 'pending',
    label: i18n.t('invite.pending'),
  }, {
    key: 'accepted',
    label: i18n.t('invite.accepted'),
  }, {
    key: 'refused',
    label: i18n.t('invite.refused'),
  }, {
    key: 'cancelled',
    label: i18n.t('invite.cancelled'),
  }, {
    key: 'expired',
    label: i18n.t('invite.expired'),
  }]

  // company status
  myEnums.companyStatus = [{
    key: 'active',
    label: i18n.t('company.active'),
  }, {
    key: 'inactive',
    label: i18n.t('company.inactive'),
  }, {
    key: 'pending',
    label: i18n.t('company.pending'),
  }, {
    key: 'reproved',
    label: i18n.t('company.reproved'),
  }]

  // connection adapters
  myEnums.adapters = [{
    key: 'pg',
    label: i18n.t('adapters.pg'),
  }, {
    key: 'mysql',
    label: i18n.t('adapters.mysql'),
  }, {
    key: 'mssql',
    label: i18n.t('adapters.mssql'),
  }, {
    key: 'oracledb',
    label: i18n.t('adapters.oracledb'),
  }, {
    key: 'filelocal-csv',
    label: i18n.t('adapters.filelocal-csv'),
  }, {
    key: 'filelocal-shp',
    label: i18n.t('adapters.filelocal-shp'),
  }]

  // connections
  myEnums.connectionTypes = [{
    key: 'remote',
    label: i18n.t('connection_types.remote')
  }, {
    key: 'storage',
    label: i18n.t('connection_types.storage')
  }]

  // materialization type
  myEnums.materializationType = [{
    key: 'incremental',
    label: i18n.t('materialization_types.incremental'),
  }, {
    key: 'full',
    label: i18n.t('materialization_types.full'),
  }]

  myEnums.materializationLog = [{
    key: 'running',
    label: i18n.t('materialization_logs.running')
  }, {
    key: 'success',
    label: i18n.t('materialization_logs.success')
  }, {
    key: 'failed',
    label: i18n.t('materialization_logs.failed')
  }]

  // etl
  myEnums.stageType = [{
    key: 'fat',
    label: i18n.t('stage_types.fat')
  }, {
    key: 'dim',
    label: i18n.t('stage_types.dim')
  }, {
    key: 'plct',
    label: i18n.t('stage_types.plct')
  }]

  myEnums.joinType = [{
    key: 'INNER',
    label: i18n.t('join_types.inner')
  }, {
    key: 'LEFT',
    label: i18n.t('join_types.left')
  }, {
    key: 'RIGHT',
    label: i18n.t('join_types.right')
  }, {
    key: 'CROSS',
    label: i18n.t('join_types.cross')
  }]

  // filter conditions
  myEnums.condition = [{
    key: '=',
    label: i18n.t('conditions.equal')
  }, {
    key: '<>',
    label: i18n.t('conditions.different')
  }, {
    key: '>',
    label: i18n.t('conditions.bigger')
  }, {
    key: '>=',
    label: i18n.t('conditions.bigger_equal')
  }, {
    key: '<',
    label: i18n.t('conditions.less')
  }, {
    key: '<=',
    label: i18n.t('conditions.less_equal')
  }, {
    key: 'NULLABLE',
    label: i18n.t('conditions.nullable')
  }, {
    key: 'LIKE',
    label: i18n.t('conditions.like')
  }, {
    key: 'NOT LIKE',
    label: i18n.t('conditions.not_like')
  }]

  // panel types
  myEnums.panelTypes = [{
    key: 'primary',
    label: i18n.t('panel_types.primary')
  }, {
    key: 'secondary',
    label: i18n.t('panel_types.secondary')
  }]
}

fill()

i18n.on('languageChanged init', () => {
  fill()
})

// export the const
export const enums = myEnums
