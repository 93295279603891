import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getCompanies } from 'services/requests/company'
import { setMask, enums } from 'utils'

import CompanyForm from './Form'

export default function CompaniesView({ newItem, cancelNew }) {
  const navigate = useNavigate()
  const dataGridRef = useRef(null)

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('settings.companies.fields.name')
      },
      {
        accessorKey: 'fantasyName',
        header: t('settings.companies.fields.fantasy_name')
      },
      {
        accessorFn: row => setMask(row.cnpj, '99.999.999/9999-99'),
        header: t('settings.companies.fields.cnpj')
      },
      {
        accessorFn: row => `${
          t(`company.${row.isApproved === null ? 'pending' : row.isApproved ? 'approved' : 'reproved'}`)
        }${
          row.isApproved ? ` / ${t(`company.${row.isActive ? 'active' : 'inactive'}`)}` : null
        }`,
        header: t('settings.companies.fields.status'),
        filterVariant: 'select',
        filterSelectOptions: enums.companyStatus
      }
    ],
    actions: [
      {
        icon: 'mdi:file-eye-outline',
        label: t('settings.companies.open'),
        onClick: item => navigate(`/gestao/empresas/${item.id}`)
      }
    ],
    onRowClick: row => navigate(`/gestao/empresas/${row.id}`)
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={getCompanies}
        config={listConfig}
        ref={dataGridRef}
      />

      <CompanyForm
        open={newItem}
        onClose={(reload) => {
          cancelNew()
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
      />
    </Box>
  )
}

CompaniesView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}
