import PropTypes from 'prop-types'
import { useState, useContext } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { Stack, IconButton, InputAdornment, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Iconify from 'components/Iconify'
import TextField from 'components/TextField'

import { register } from 'services/requests/auth'
import { UserContext } from 'contexts'
import { Toast, removeMask } from 'utils'

const initialValues = {
  company_name: '',
  fantasy_name: '',
  cnpj: '',
  name: '',
  email: '',
  password: ''
}

export default function RegisterForm({ t }) {
  const navigate = useNavigate()
  const userContext = useContext(UserContext)

  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object({
    company_name: yup.string()
      .required('Obrigatório'),
    fantasy_name: yup.string()
      .required('Obrigatório'),
    cnpj: yup.string()
      .cnpj('O CNPJ deve ser válido')
      .required('Obrigatório'),
    name: yup.string()
      .required('Obrigatório'),
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    password: yup.string()
      .required('Obrigatório')
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Senha fraca: sua senha deve conter números, letras maiúsculas, letras minúsculas e caracteres especiais'
      )
      .min(8, 'Sua senha deve conter pelo menos 8 caracteres')
      .max(32, 'Sua senha deve conter no máximo 32 caracteres'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ company_name, fantasy_name, cnpj, name, email, password }, { setSubmitting }) => {
      const response = await register({ body: { company_name, fantasy_name, cnpj: removeMask(cnpj), name, email, password } })
        .then(() => true)
        .catch((err) => {
          Toast(err, 'error')
          setSubmitting(false)
          return false
        })

      if (response) {
        userContext.exec
          .login({ body: { email, password } })
          .then(() => true)
          .catch(() => Toast('Conta criada com sucesso', 'success'))
          .then(() => {
            navigate('/', { replace: true })
            setSubmitting(false)
          })
      }
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="company_name"
          label={t('auth.register.form.company_name')}
          value={formik.values.company_name}
          onChange={handleChange}
          error={formik.touched.company_name && Boolean(formik.errors.company_name)}
          helperText={formik.touched.company_name && formik.errors.company_name}
        />

        <TextField
          name="fantasy_name"
          label={t('auth.register.form.fantasy_name')}
          value={formik.values.fantasy_name}
          onChange={handleChange}
          error={formik.touched.fantasy_name && Boolean(formik.errors.fantasy_name)}
          helperText={formik.touched.fantasy_name && formik.errors.fantasy_name}
        />

        <TextField
          name="cnpj"
          label={t('auth.register.form.cnpj')}
          value={formik.values.cnpj}
          onChange={handleChange}
          error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
          helperText={formik.touched.cnpj && formik.errors.cnpj}
          mask="99.999.999/9999-99"
        />

        <TextField
          name="name"
          label={t('auth.register.form.name')}
          value={formik.values.name}
          onChange={handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          name="email"
          label={t('auth.register.form.email')}
          value={formik.values.email}
          onChange={handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          name="password"
          label={t('auth.register.form.password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ my: 2 }}
      >
        {t('auth.register.form.submit')}
      </LoadingButton>

      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
        <Link to={'/auth'} component={RouterLink} variant="subtitle2" underline="hover">
          {t('auth.register.form.login')}
        </Link>
      </Stack>
    </>
  )
}

RegisterForm.propTypes = {
  t: PropTypes.func
}
