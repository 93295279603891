import { Typography, Container, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StyledContent } from './styled'

export default function Empty({
  title,
  hideTitle = false,
  hideIllustration = false,
  sizeIllustration = 260
}) {

  const { t } = useTranslation()

  return (
    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        {
          !hideTitle ? (
            <Typography variant="h4">
              {title || t('empty.title')}
            </Typography>
          ) : null
        }

        {
          !hideIllustration ? (
            <Box
              component="img"
              src="/assets/illustrations/illustration_empty_content.svg"
              sx={{ height: sizeIllustration, mx: 'auto', mt: 4 }}
            />
          ) : null
        }
      </StyledContent>
    </Container>
  )
}
