import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { t } from 'i18next'

import { thousandDetails } from 'utils'

import { useChart } from 'components/Chart'

ChartTreeMap.propTypes = {
  data: PropTypes.object
}

export default function ChartTreeMap({ data = {} , filtersDb = [], grouprow=[], handleChartClick }) {
  const series = data?.series?.map(item => ({
    name: item.name,
    data: data.data.map(_item => (
      {
        x: _item[data.groupBY[0].name] !== null ? _item[data.groupBY[0].name] : 'Vazio',
        y: _item[item.name]
      }))
  })) || []

  const labels = data?.groupBY?.map(item => (
    data.data.map(_item => _item[item.name])
  )) || []

  const categories = labels?.length
    ? [...labels.reduce((a, b) => a.map((v, i) => `${v} / ${b[i]}`)).map(item => String(item))]
    : []
  const options = {
    noData: {
      text: t('empty_data')
    },
    grid: {
      show: false
    },
    chart: {
      background: '#fff',
      type: 'treemap',
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      },
      events: {
        click: async (event, chartContext, config) => {
          if(config.dataPointIndex !== -1){
            handleChartClick(event, chartContext, config)
          }
        }
      }
    },
    legend: {
      show: false
    },
    // dataLabels: {
    //   enabled: true,
    //   textAnchor: 'start',
    //   distributed: true,
    //   style: {
    //     colors: ['#000'],
    //     fontSize: '10px'
    //   },
    //   formatter: val => thousandDetails(val)
    // },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    },
    tooltip: {
      y: {
        formatter: val => thousandDetails(val)
      }
    },
    xaxis: {
      categories,
      categoriesDb: categories,
      details: data.groupBY,
    }
  }
  if (data.splitStack) {
    options.chart.stacked = true;
    options.chart.stackType = '100%';
  }

  const chartOptions = useChart(options)
  console.log(chartOptions, series);
  return (
    <ReactApexChart
      series={series}
      options={chartOptions}
      type="treemap"
      height={500}
    />
  )
}
