import { API } from 'services'

export function getConnections(params) {
  return API({
    endpoint: '/connection',
    ...params
  })
}

export function createConnection(params) {
  return API({
    method: 'POST',
    endpoint: '/connection',
    ...params
  })
}

export function validateConnection(params) {
  return API({
    method: 'POST',
    endpoint: '/connection/validate',
    ...params
  })
}

export function updateConnection({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/connection/${id}`,
    ...params
  })
}

export function getConnectionTables({ id, ...params }) {
  return API({
    endpoint: `/connection/${id}/structure`,
    ...params
  })
}

export function getConnectionColumns({ id, schema, table, ...params }) {
  return API({
    endpoint: `/connection/${id}/columns?schema=${schema}&table=${table}`,
    ...params
  })
}
