import { API } from 'services'

export function getProfiles(params) {
  const { companyId = null, ...rest } = params || {}

  return API({
    endpoint: `/profile${companyId ? `/companyId/${companyId}` : ''}`,
    ...rest
  })
}

export function createProfile(params) {
  return API({
    method: 'POST',
    endpoint: '/profile',
    ...params
  })
}

export function updateProfile({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/profile/${id}`,
    ...params
  })
}
