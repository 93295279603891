import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Grid,
  FormControl,
  Autocomplete,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  Button,
  Divider,
  IconButton,
  FormHelperText
} from '@mui/material'
import { t } from 'i18next'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import * as yup from 'yup'

import FormDrawer from 'components/FormDrawer'
import Iconify from 'components/Iconify'

import { updateStageRelation, getStages, getStageTableColumns } from 'services/requests/stage'
import { Toast, enums } from 'utils'

const initialValues = {
  relation: []
}

const initialValueTableRelation = {
  condition: '',
  stageColumn: '',
  foreignColumn: ''
}

const initialValueTable = {
  foreignTable: '',
  type: '',
  alias: '',
  relations: [initialValueTableRelation]
}

export default function StageFormRelation({ open, data, onClose }) {
  const [stages, setStages] = useState([])
  const [stagesColumns, setStagesColumns] = useState({})

  const validationSchema = yup.object({
    relation: yup.array()
      .of(
        yup.object().shape({
          foreignTable: yup.string().required('Obrigatório'),
          type: yup.string().required('Obrigatório'),
          alias: yup.string().required('Obrigatório'),
          relations: yup.array()
            .of(
              yup.object().shape({
                condition: yup.string().required('Obrigatório'),
                stageColumn: yup.string().required('Obrigatório'),
                foreignColumn: yup.string().required('Obrigatório')
              })
            )
        })
      )
      .required('Required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ relation }, { setSubmitting }) => {
      const body = {
        relation
      }

      updateStageRelation({ id: data.id, body })
        .then(() => {
          Toast(t('data.stage.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.handleChange(e)
  }

  const handleChangeForeignTable = async (index, value) => {
    formik.setFieldValue(`relation.${index}.foreignTable`, value)
    formik.setFieldValue(`relation.${index}.relations`, [{ ...initialValueTableRelation }])

    if (!(stagesColumns?.[value]?.length > 0)) {
      const res = await getStageTableColumns({ connectionId: data.connectionStorage.id, table: value })
      if (res?.length >= 0) {
        const cols = res.map(item => item.attribute)
        const stgCols = {
          ...stagesColumns,
          [value]: cols
        }

        setStagesColumns({ ...stgCols })
      }
    }
  }

  const handleAddTable = () => {
    const newRelations = [
      ...formik.values.relation,
      { ...initialValueTable }
    ]
    formik.setFieldValue('relation', newRelations)
  }

  const handleAddTableRelation = (index) => {
    const newRelations = [
      ...formik.values.relation[index].relations,
      { ...initialValueTableRelation }
    ]
    const relations = [ ...formik.values.relation ]
    relations[index].relations = [...newRelations]

    formik.setFieldValue('relation', relations)
  }

  const getStagesData = async () => {
    const response = await getStages()
    if (response?.length) {
      setStages(response.map(item => item.stageTable).filter(item => item !== data.stageTable))
    }
  }

  const fillEditForm = async () => {
    const res = await getStageTableColumns({ connectionId: data.connectionStorage.id, table: data.stageTable })
    if (res?.length >= 0) {
      const cols = res.map(item => item.attribute)
      const stgCols = {
        ...stagesColumns,
        [data.stageTable]: cols
      }

      setStagesColumns({ ...stgCols })
    }

    formik.setFieldValue('stageTable', data.stageTable)
    formik.setFieldValue('relation', data?.relations || [])
  }

  const handleClose = useCallback((reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }, [formik, onClose])

  useEffect(() => {
    if (data?.id) {
      fillEditForm()
      getStagesData()
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <FormDrawer
      title={t('data.stage.update_relation')}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: () => formik.handleSubmit(),
          loading: false,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              name="stageTable"
              label={t('data.stage.form_relation.stage_table')}
              value={data?.stageTable}
              sx={{ width: '100%' }}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider sx={{ mt: 1, mb: 3 }} />
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <FieldArray
            name="relation"
            render={arrayHelpers => (
              <div>
                {
                  formik.values.relation?.map((_, key) => (
                    <Box key={key}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={5}>
                          <TextField
                            name={`relation.${key}.alias`}
                            label={t('data.stage.form_relation.alias')}
                            value={formik.values.relation[key].alias}
                            onChange={handleChange}
                            error={formik.touched.relation?.[key]?.alias && Boolean(formik.errors.relation?.[key]?.alias)}
                            helperText={formik.touched.relation?.[key]?.alias ? formik.errors.relation?.[key]?.alias : null}
                            sx={{ width: '100%' }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                              disablePortal
                              name={`relation.${key}.foreignTable`}
                              value={formik.values.relation[key].foreignTable}
                              onChange={(_, value) => handleChangeForeignTable(key, value)}
                              disabled={false}
                              options={stages}
                              isOptionEqualToValue={(option, value) => (option === value)}
                              getOptionLabel={option => option || ''}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('data.stage.form_relation.foreing_table')}
                                  error={formik.touched.relation?.[key]?.foreignTable && Boolean(formik.errors.relation?.[key]?.foreignTable)}
                                />
                              )}
                            />
                            {
                              (formik.touched.relation?.[key]?.foreignTable && Boolean(formik.errors.relation?.[key]?.foreignTable)) ? (
                                <FormHelperText error>{formik.errors.relation[key].foreignTable}</FormHelperText>
                              ) : null
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={2} display="flex" flexDirection="row">
                          <FormControl
                            sx={{ width: '100%' }}
                            error={formik.touched.relation?.[key]?.type && Boolean(formik.errors.relation?.[key]?.type)}
                          >
                            <InputLabel id="type">{t('data.stage.form_relation.join_type')}</InputLabel>
                            <Select
                              labelId="type"
                              label={t('data.stage.form_relation.join_type')}
                              name={`relation.${key}.type`}
                              value={formik.values.relation[key].type}
                              onChange={handleChange}
                            >
                              {
                                enums.joinType.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>{item.label}</MenuItem>
                                ))
                              }
                            </Select>
                            {
                              (formik.touched.relation?.[key]?.type && Boolean(formik.errors.relation?.[key]?.type)) ? (
                                <FormHelperText error>{formik.errors.relation[key].type}</FormHelperText>
                              ) : null
                            }
                          </FormControl>
                          <IconButton onClick={() => arrayHelpers.remove(key)} color="error">
                            <Iconify icon="mdi:trash" />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <FieldArray
                        name={`relation[${key}].relations`}
                        render={subarrayHelpers => (
                          <div>
                            {
                              formik.values.relation[key].relations?.map((__, _key) => (
                                <Grid container spacing={2} key={`${key}_${_key}`} mt={1}>
                                  <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <FormControl sx={{ width: '100%' }}>
                                      <Autocomplete
                                        disablePortal
                                        name={`relation.${key}.relations.${_key}.stageColumn`}
                                        value={formik.values.relation[key].relations[_key].stageColumn}
                                        onChange={(_, value) => formik.setFieldValue(`relation.${key}.relations.${_key}.stageColumn`, value)}
                                        disabled={false}
                                        options={stagesColumns?.[data.stageTable] || []}
                                        isOptionEqualToValue={(option, value) => (option === value)}
                                        getOptionLabel={option => option || ''}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={t('data.stage.form_relation.stage_column')}
                                            error={formik.touched.relation?.[key]?.relations?.[_key]?.stageColumn && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.stageColumn)}
                                          />
                                        )}
                                      />
                                      {
                                        (formik.touched.relation?.[key]?.relations?.[_key]?.stageColumn && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.stageColumn)) ? (
                                          <FormHelperText error>{formik.errors.relation?.[key]?.relations?.[_key]?.stageColumn}</FormHelperText>
                                        ) : null
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={3} lg={2}>
                                    <FormControl
                                      sx={{ width: '100%' }}
                                      error={formik.touched.relation?.[key]?.relations?.[_key]?.condition && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.condition)}
                                    >
                                      <InputLabel id="condition">{t('data.stage.form_relation.condition')}</InputLabel>
                                      <Select
                                        labelId="condition"
                                        label={t('data.stage.form_relation.condition')}
                                        name={`relation.${key}.relations.${_key}.condition`}
                                        value={formik.values.relation[key].relations[_key].condition}
                                        onChange={handleChange}
                                      >
                                        {
                                          enums.condition.map((item, key) => (
                                            <MenuItem value={item.key} key={key}>
                                              <ListItemText>{item.label}</ListItemText>
                                              {/* <Typography variant="body2" color="text.secondary">
                                                {item.key}
                                              </Typography> */}
                                            </MenuItem>
                                          ))
                                        }
                                      </Select>
                                      {
                                        (formik.touched.relation?.[key]?.relations?.[_key]?.condition && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.condition)) ? (
                                          <FormHelperText error>{formik.errors.relation?.[key]?.relations?.[_key]?.condition}</FormHelperText>
                                        ) : null
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={5} lg={5} display="flex" flexDirection="row">
                                    <FormControl sx={{ width: '100%' }}>
                                      <Autocomplete
                                        disablePortal
                                        name={`relation.${key}.relations.${_key}.foreignColumn`}
                                        value={formik.values.relation[key].relations[_key].foreignColumn}
                                        onChange={(_, value) => formik.setFieldValue(`relation.${key}.relations.${_key}.foreignColumn`, value)}
                                        disabled={false}
                                        options={stagesColumns?.[formik.values.relation[key].foreignTable] || []}
                                        isOptionEqualToValue={(option, value) => (option === value)}
                                        getOptionLabel={option => option || ''}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={t('data.stage.form_relation.foreing_column')}
                                            error={formik.touched.relation?.[key]?.relations?.[_key]?.foreignColumn && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.foreignColumn)}
                                          />
                                        )}
                                      />
                                      {
                                        (formik.touched.relation?.[key]?.relations?.[_key]?.foreignColumn && Boolean(formik.errors.relation?.[key]?.relations?.[_key]?.foreignColumn)) ? (
                                          <FormHelperText error>{formik.errors.relation?.[key]?.relations?.[_key]?.foreignColumn}</FormHelperText>
                                        ) : null
                                      }
                                    </FormControl>
                                    {
                                      _key > 0 ? (
                                        <IconButton onClick={() => subarrayHelpers.remove(_key)} color="error">
                                          <Iconify icon="mdi:trash" />
                                        </IconButton>
                                      ) : null
                                    }
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </div>
                        )}
                      />

                      <Grid container mt={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="flex-end">
                          <Button
                            type="submit"
                            sx={{ mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
                            variant="contained"
                            onClick={() => handleAddTableRelation(key)}
                            endIcon={<Iconify icon="mdi:plus" />}
                          >
                            {t('data.stage.form_relation.add_column')}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider sx={{ my: 3 }} />
                      </Grid>
                    </Box>
                  ))
                }
              </div>
            )}
          />
        </FormikProvider>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              sx={{ mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
              variant="contained"
              onClick={() => handleAddTable()}
              endIcon={<Iconify icon="mdi:plus" />}
            >
              {t('data.stage.form_relation.add_table')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormDrawer>
  )
}

StageFormRelation.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
}
