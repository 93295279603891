import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  Typography,
  Skeleton
} from '@mui/material'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

import { getMaterialization, runMaterialization } from 'services/requests/materialization'
import { Toast } from 'utils'

import MaterializationForm from '../Materializations/Form'

export default function MaterializationView() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [onUpdate, setOnUpdate] = useState(false)

  const getData = () => {
    getMaterialization({ id })
      .then(res => setData(res))
      .catch(() => navigate(-1))
      .then(() => setLoading(false))
  }

  const handleExecuteMaterialization = () => {
    runMaterialization({ id })
      .then(() => {
        Toast(t('data.materializations.running'), 'info')
      })
      .catch(err => Toast(err, 'error'))
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      {
        loading ? (
          <Loader />
        ) : (
          <Grid container alignItems="stretch" rowSpacing={2} columnSpacing={2}>
            <Grid item style={{ display: 'flex', flexDirection: 'row' }} xs={12} sm={6} md={4} lg={4}>
              <Card style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardHeader title={t('data.materialization_detail.fields.materialization')} />
                <CardContent>
                  <DetailField
                    label={t('data.materialization_detail.fields.alias')}
                    value={data.alias}
                  />
                  <DetailField
                    label={t('data.materialization_detail.fields.type')}
                    value={t(`materialization_types.${data.backupType}`)}
                  />
                  <DetailField
                    label={t('data.materialization_detail.fields.status')}
                    value={t(data.status === 'active' ? 'active' : 'inactive')}
                  />
                  {
                    data?.scheduleType ? (
                      <DetailField label={t('data.materialization_detail.fields.recurrence')}>
                        <span className="italic underline">{t(`materialization_schedule_types.${data.scheduleType}`)}</span><br />
                        {
                          data.scheduleType === 'week'
                            ? data.scheduleDays.sort((a, b) => (a - b)).map(item => t(`weekdays.${item}`)).join(', ')
                            : data.scheduleDays.sort((a, b) => (a - b)).map(item => `${t('day')} ${item}`).join(', ')
                        }<br />
                        {data.scheduleHours.sort((a, b) => (a - b)).map(item => `${(`0${item}`).slice(-2)}:00`).join(', ')}
                      </DetailField>
                    ) : null
                  }
                </CardContent>
              </Card>

            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'row' }} xs={12} sm={6} md={4} lg={4}>
              <Card style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardHeader title={t('data.materialization_detail.fields.connections')} />
                <CardContent>
                  <DetailField label={t('data.materialization_detail.fields.connection_remote')}>
                    <span className="italic underline">
                      {t('data.materialization_detail.fields.database_type')}:</span> {t(`adapters.${data.connectionRemote.adapter}`)}<br />
                    {
                      data.connectionRemote?.config?.database ? (
                        <>
                          <span className="italic underline">
                            {t('data.materialization_detail.fields.connection')}:</span> {`${data.connectionRemote.config.host}:${data.connectionRemote.config.port}`}<br />
                          <span className="italic underline">
                            {t('data.materialization_detail.fields.database')}:</span> {data.connectionRemote.config.database}
                        </>
                      ) : null
                    }
                  </DetailField>
                  <DetailField label={t('data.materialization_detail.fields.connection_storage')}>
                    <span className="italic underline">
                      {t('data.materialization_detail.fields.database_type')}:</span> {t(`adapters.${data.connectionStorage.adapter}`)}<br />
                    <span className="italic underline">
                      {t('data.materialization_detail.fields.connection')}:</span> {`${data.connectionStorage.config.host}:${data.connectionStorage.config.port}`}<br />
                    <span className="italic underline">
                      {t('data.materialization_detail.fields.database')}:</span> {data.connectionStorage.config.database}
                  </DetailField>
                </CardContent>
              </Card>

            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'row' }} xs={12} sm={12} md={4} lg={4}>
              <Card style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' }}>
                <Box>
                  <CardHeader title={t('data.materialization_detail.fields.table')} />
                  <CardContent>
                    <DetailField
                      label={t('data.materialization_detail.fields.schema')}
                      value={data.originSchemaName}
                    />
                    <DetailField
                      label={t('data.materialization_detail.fields.table')}
                      value={data.originTableName}
                    />
                  </CardContent>
                </Box>
                <CardActions
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={() => data.connectionRemote.adapter.startsWith('filelocal') ? setOnUpdate(true) : handleExecuteMaterialization()}
                    startIcon={<Iconify icon="icon-park-solid:play" />}
                  >
                    {t('data.materialization_detail.execute')}
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={() => setOnUpdate(true)}
                    startIcon={<Iconify icon="mdi:pencil" />}
                    sx={{ mt: 1.5, ml: '0 !important' }}
                  >
                    {t('data.materialization_detail.update')}
                  </Button>
                </CardActions>
              </Card>

            </Grid>
          </Grid>
        )
      }

      <MaterializationForm
        open={onUpdate}
        data={data}
        onClose={(reload) => {
          setOnUpdate(false)
          if (reload) {
            getData()
          }
        }}
      />
    </Box>
  )
}

const DetailField = ({ label, value, children }) => (
  <Box mb={2}>
    {
      label ? (
        <Typography color="text.primary" variant="subtitle1">
          {label}
        </Typography>
      ) : null
    }
    {
      value ? (
        <Typography color="text.secondary" variant="body1">
          {value}
        </Typography>
      ) : children
    }
  </Box>
)

const Loader = () => (
  <Grid container>
    <Grid item xs={12} md={6} lg={4} p={1}>
      <Skeleton animation="wave" height={50} />
      <Skeleton animation="wave" height={50} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
    <Grid item xs={12} md={6} lg={8} p={1}>
      <Skeleton animation="wave" height={50} />
      <Skeleton animation="wave" height={50} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
  </Grid>
)

DetailField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node
}
