import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  Skeleton,
  Button,
  Tab
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { t } from 'i18next'

import { isAuthorized } from 'components/ACL'
import Error from 'components/Error'
import Iconify from 'components/Iconify'

import CompanyDetailView from 'sections/management/CompanyDetail/View'
import CompanyDetailForm from 'sections/management/CompanyDetail/Form'
import CompanyDetailProfiles from 'sections/management/CompanyDetail/Profiles'
import CompanyDetailUsers from 'sections/management/CompanyDetail/Users'
import CompanyDetailInvites from 'sections/management/CompanyDetail/Invites'
import InviteForm from 'sections/settings/Invites/Form'

import { getCompany } from 'services/requests/company'
import { getProfiles } from 'services/requests/profile'

export default function Company() {
  const navigate = useNavigate()
  const { id } = useParams()

  const disabledUpdate = !isAuthorized({ roles: ['superadmin', 'client'] })

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [profiles, setProfiles] = useState(null)
  const [newItem, setNewItem] = useState(false)
  const [tab, setTab] = useState('1')

  const handleChangeTab = (e, newTab) => {
    setTab(newTab)
  }

  const getData = () => {
    setLoading(true)

    getCompany({ id })
      .then(res => setData(res))
      .catch(() => setData(null))
      .then(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line
  }, [])

  const getDataProfiles = () => {
    getProfiles({ companyId: id })
      .then(res => setProfiles(res))
      .catch(() => {})
  }

  useEffect(() => {
    getDataProfiles()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('settings.company_detail.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
              >
                <Typography variant="h4" color="primary" sx={{ width: 'max-content' }} className="link" onClick={() => navigate(-1)}>
                  <Iconify icon="lets-icons:back" /> {t('settings.companies.title')}
                </Typography>
                <Typography variant="body2">
                  {t(`settings.company_detail.description${disabledUpdate ? '_restrict' : ''}`)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  size="large"
                  type="submit"
                  sx={{ mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
                  variant="contained"
                  onClick={() => setNewItem(true)}
                  startIcon={<Iconify icon="mdi:plus" />}
                >
                  {t('settings.company_detail.new_invite')}
                </Button>
              </Grid>
            </Grid>
            {
              loading
                ? <Loader />
                : data?.id ? (
                  <div>
                    <Grid
                      container
                      mt={3}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        p={1}
                      >
                        {
                          data?.id ? (
                            <CompanyDetailView
                              data={data}
                              refresh={() => getData()}
                            />
                          ) : null
                        }
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={8}
                        p={1}
                      >
                        <CompanyDetailForm data={data} refresh={() => getData()} />
                      </Grid>
                    </Grid>
                  </div>
                ) : <Error />
            }

            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 8 }}>
                <TabList onChange={handleChangeTab} aria-label="company infos">
                  <Tab
                    label={t('settings.company_detail.users.title')}
                    value="1"
                  />
                  <Tab
                    label={t('settings.company_detail.profiles.title')}
                    value="2"
                  />
                  <Tab
                    label={t('settings.company_detail.invites.title')}
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel
                sx={{ px: 0 }}
                value="1"
              >
                <CompanyDetailUsers />
              </TabPanel>
              <TabPanel
                sx={{ px: 0 }}
                value="2"
              >
                <CompanyDetailProfiles />
              </TabPanel>
              <TabPanel
                sx={{ px: 0 }}
                value="3"
              >
                <CompanyDetailInvites />
              </TabPanel>
            </TabContext>
          </Stack>
        </Container>
      </Box>

      <InviteForm
        open={newItem}
        onClose={() => {
          setNewItem(false)
        }}
        profiles={profiles || []}
        companyId={id}
      />
    </>
  )
}

const Loader = () => (
  <Grid container>
    <Grid item xs={12} md={6} lg={4} p={1}>
      <Skeleton animation="wave" height={250} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
    <Grid item xs={12} md={6} lg={8} p={1}>
      <Skeleton animation="wave" height={250} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
  </Grid>
)
