import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  // CardActions,
  // Button,
  // Divider,
  Typography
} from '@mui/material'
// import { t } from 'i18next'

// import ACL from 'components/ACL'

import { setMask } from 'utils'

export default function MyCompanyView({ data }) {
  return (
    <Card>
      <CardMedia
        sx={{ height: 220 }}
        image={`https://placehold.co/500x500?text=${data.name.slice(0, 1)}`}
        title={data.name}
      />
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            textAlign="center"
          >
            {data.fantasyName}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body1"
          >
            {setMask(data.cnpj, '99.999.999/9999-99')}
          </Typography>
        </Box>
      </CardContent>
      {/* <ACL roles={['superadmin', 'client']}>
        <Divider />
        <CardActions>
          <Button
            fullWidth
            variant="text"
          >
            { t('settings.my_company.upload_logo') }
          </Button>
        </CardActions>
      </ACL> */}
    </Card>
  )
}

MyCompanyView.propTypes = {
  data: PropTypes.object
}
