import PropTypes from 'prop-types'
import {
  Box,
  Grid
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import FormDrawer from 'components/FormDrawer'
import TextField from 'components/TextField'

import { createCompany } from 'services/requests/company'
import { Toast, removeMask } from 'utils'

const initialValues = {
  name: '',
  fantasyName: '',
  cnpj: '',
  email: ''
}

export default function CompanyForm({ open, onClose }) {
  const validationSchema = yup.object({
    name: yup.string()
      .required('Obrigatório'),
    fantasyName: yup.string()
      .required('Obrigatório'),
    cnpj: yup.string()
      .cnpj('O CNPJ deve ser válido')
      .required('Obrigatório'),
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório')
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ name, fantasyName, cnpj, email }, { setSubmitting }) => {
      createCompany({ body: { name, fantasyName, cnpj: removeMask(cnpj), email } })
        .then(() => {
          Toast(t('settings.companies.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleClose = (reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }

  return (
    <FormDrawer
      title={t('settings.companies.new')}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              name="name"
              label={t('settings.companies.form.name')}
              value={formik.values.name}
              onChange={handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              name="fantasyName"
              label={t('settings.companies.form.fantasy_name')}
              value={formik.values.fantasyName}
              onChange={handleChange}
              error={formik.touched.fantasyName && Boolean(formik.errors.fantasyName)}
              helperText={formik.touched.fantasyName && formik.errors.fantasyName}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              name="cnpj"
              label={t('settings.companies.form.cnpj')}
              value={formik.values.cnpj}
              onChange={handleChange}
              error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
              helperText={formik.touched.cnpj && formik.errors.cnpj}
              mask="99.999.999/9999-99"
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              name="email"
              label={t('settings.companies.form.email')}
              value={formik.values.email}
              onChange={handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormDrawer>
  )
}

CompanyForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}
