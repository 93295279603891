import PropTypes from 'prop-types'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { Stack, TextField, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { forgotPassword } from 'services/requests/auth'
import { Toast } from 'utils'

const initialValues = {
  email: ''
}

export default function ForgotPasswordForm({ t }) {
  const navigate = useNavigate()

  const validationSchema = yup.object({
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email }, { setSubmitting }) => {
      forgotPassword({ body: { email } })
        .then(() => {
          Toast(t('auth.forgot_password.success'), 'success')
          navigate('/auth', { replace: true })
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('auth.forgot_password.form.email')}
          value={formik.values.email}
          onChange={handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ my: 2 }}
      >
        {t('auth.forgot_password.form.submit')}
      </LoadingButton>

      <Stack direction="row" alignItems="center" justifyContent="end">
        <Link to="/auth" component={RouterLink} variant="subtitle2" underline="hover">
          {t('auth.forgot_password.form.login')}
        </Link>
      </Stack>
    </>
  )
}


ForgotPasswordForm.propTypes = {
  t: PropTypes.func
}
