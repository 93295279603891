export const numerize = (num, toFixed = 2, config) => {
  const prefix = config?.prefix || ''
  const sufix = config?.sufix || ''

  const value = !Number.isNaN(num)
    ? Number(num).toFixed(toFixed).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d),)/g, ".")
    : '0'

  return `${prefix}${value}${sufix}`
}

export const shortenLargeNumber = (num, digits) => {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  let decimal

  if (num < 100) return numerize(num)

  if (num < 1000) return numerize(num, 1)

  for (let i = units.length - 1; i >= 0; i -= 1) {
    decimal = (1000 ** (i + 1))

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i]
    }
  }

  return num
}

export const thousand = (n, obj) => {
  if (obj && obj.type) {
    return parseInt(n, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  if (!n) return n;
  const parts = n.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

export const thousandDetails = (n, obj) => {
  if (obj && obj.type) {
    return parseInt(n, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  if (!n) return 0;
  n = parseFloat(n).toFixed(2);
  const parts = n.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}
