import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AuthView from 'sections/auth/View'
import RegisterForm from 'sections/auth/Form/Register'

export default function Register() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.register.page_title')} | {t('project_name')}</title>
      </Helmet>

      <AuthView
        t={t}
        pageType="register"
        Form={RegisterForm}
      />
    </>
  )
}
