import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getInvites } from 'services/requests/invite'
import { getProfiles } from 'services/requests/profile'
import { enums } from 'utils'

export default function CompanyUsersView() {
  const { id } = useParams()

  const [profiles, setProfiles] = useState([])

  const listConfig = {
    title: t('settings.company_detail.invites.title'),
    fields: [
      {
        accessorKey: 'email',
        header: t('settings.invites.fields.email')
      },
      {
        accessorKey: 'profiles.name',
        accessorFn: row => row.profiles.map(item => item.name).join(', '),
        header: t('settings.invites.fields.profiles'),
        filterVariant: 'select',
        filterSelectOptions: profiles.map(item => item.name)
      },
      {
        accessorKey: 'status',
        accessorFn: row => `${t(`invite.${row.status}`)}${row.status === 'pending' ? ` (${t('expires_in')} ${format(row.expiresAt, 'dd/MM/yyyy')})` : ''}`,
        header: t('settings.invites.fields.status'),
        filterVariant: 'select',
        filterSelectOptions: enums.inviteStatus
      },
      {
        accessorKey: 'userInvitor.name',
        header: t('settings.invites.fields.invitor_name')
      }
    ]
  }

  const getDataProfiles = () => {
    getProfiles({ companyId: id })
      .then(res => setProfiles(res))
      .catch(() => {})
  }

  useEffect(() => {
    getDataProfiles()
    // eslint-disable-next-line
  }, [])

  return (
    <DataGrid
      getData={getInvites}
      getDataParams={{ companyId: id }}
      config={listConfig}
    />
  )
}
