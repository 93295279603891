import { API } from 'services'

export function getStageTables({ query = {}, ...params }) {
  const qp = new URLSearchParams(query).toString()

  return API({
    endpoint: `/stage/storage/tables?${qp}`,
    ...params
  })
}
export function getStageTableCascadeColumns({ connectionId, table, ...params }) {
  return API({
    endpoint: `/stage/storage/${connectionId}/cascadecolumns?table=${table}`,
    ...params
  })
}

export function getStageTableColumns({ connectionId, table, ...params }) {
  return API({
    endpoint: `/stage/storage/${connectionId}/columns?table=${table}`,
    ...params
  })
}

export function getStageTableColumnValues({ connectionId, table, column, ...params }) {
  return API({
    endpoint: `/stage/storage/${connectionId}/values?table=${table}&column=${column}`,
    ...params
  })
}

export function getStagePreview(params) {
  return API({
    method: 'POST',
    endpoint: `/stage/storage/preview`,
    ...params
  })
}


export function getReport(params) {
  return API({
    endpoint: `/stage/report/preview`,
    ...params
  })
}

export function getStages(params) {
  return API({
    endpoint: `/stage`,
    ...params
  })
}

export function createStage(params) {
  return API({
    method: 'POST',
    endpoint: `/stage`,
    ...params
  })
}

export function updateStage({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/stage/${id}`,
    ...params
  })
}

export function updateStageRelation({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/stage/${id}/relation`,
    ...params
  })
}

export function execStage({ id, ...params }) {
  return API({
    endpoint: `/stage/exec/${id}`,
    ...params
  })
}
