import PropTypes from 'prop-types'
import { Button, Typography, Container, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { StyledContent } from './styled'

export default function Error({ getData }) {
  const { t } = useTranslation()

  return (
    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h4">
          {t('error.title')}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          {t('error.description')}
        </Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_error.svg"
          sx={{ height: 260, mx: 'auto' }}
        />

        <Button onClick={() => getData()} size="large" variant="contained">
          {t('error.action')}
        </Button>
      </StyledContent>
    </Container>
  )
}

Error.propTypes = {
  getData: PropTypes.func
}
