import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import ACL from 'components/ACL'
import Iconify from 'components/Iconify'

import MaterializationsView from 'sections/data/Materializations/View'

export default function Materializations() {
  const [newItem, setNewItem] = useState(false)

  return (
    <>
      <Helmet>
        <title>{t('data.materializations.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
              >
                <Typography variant="h4">
                  {t('data.materializations.title')}
                </Typography>
                <Typography variant="body2">
                  {t('data.materializations.description')}
                </Typography>
              </Grid>
              <ACL roles={['superadmin', 'client', 'editor']}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    size="large"
                    type="submit"
                    sx={{ mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
                    variant="contained"
                    onClick={() => setNewItem(true)}
                    startIcon={<Iconify icon="mdi:plus" />}
                  >
                    {t('data.materializations.new')}
                  </Button>
                </Grid>
              </ACL>
            </Grid>
            <div>
              <MaterializationsView
                newItem={newItem}
                cancelNew={() => setNewItem(false)}
              />
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
