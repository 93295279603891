export const stringToColor = (str, opacity = 1) => {
  let hash = 0
  str.split('').forEach(char => {
    // eslint-disable-next-line no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })

  let color = '#'
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += value.toString(16).padStart(2, '0')
  }

  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return `${color}${_opacity.toString(16).toUpperCase()}`
}
