import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getStages, getStageTables, execStage } from 'services/requests/stage'
import { enums, Toast } from 'utils'

import ETLForm from './FormStage'
import ETLFormRelation from './FormRelation'

export default function StageView({ newItem, cancelNew }) {
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)
  const [tables, setTables] = useState([])

  const handleExecStage = id => {
    execStage({ id })
      .then(() => {
        Toast(t('data.stage.exec_running'), 'info')
      })
      .catch(err => Toast(err, 'error'))
  }

  useEffect(() => {
    getStageTables({})
      .then(res => setTables(res))
      .catch(() => { })
  }, [])

  const listConfig = {
    fields: [
      {
        accessorKey: 'stageType',
        accessorFn: row => t(`stage_types.${row.stageType}`),
        header: t('data.stage.fields.type'),
        filterVariant: 'select',
        filterSelectOptions: enums.stageType,
        size: 120
      },
      {
        accessorKey: 'storageTable',
        header: t('data.stage.fields.storage_table'),
        size: 280
      },
      {
        accessorKey: 'stageTable',
        accessorFn: row => row.stageTable,
        header: t('data.stage.fields.stage_table'),
        size: 280
      },
      {
        accessorKey: 'rules',
        accessorFn: row => t('data.stage.rules_count', { count: row.rules?.length || 0 }),
        header: t('data.stage.fields.rules'),
        enableColumnFilter: false,
        size: 180
      },
      {
        accessorKey: 'filters',
        accessorFn: row => t('data.stage.filters_count', { count: row.filters?.length || 0 }),
        header: t('data.stage.fields.filters'),
        enableColumnFilter: false,
        size: 180
      },
      {
        accessorKey: 'relations',
        accessorFn: row => t('data.stage.relations_count', { count: row.relations?.length || 0 }),
        header: t('data.stage.fields.relations'),
        enableColumnFilter: false,
        size: 180
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('data.stage.update'),
        onClick: item => setSelectedData({ data: { ...item }, formType: 'etl' })
      },
      {
        icon: 'fluent-mdl2:relationship',
        label: t('data.stage.update_relation'),
        onClick: item => setSelectedData({ data: { ...item }, formType: 'relation' })
      },
      {
        icon: 'icon-park-solid:play',
        label: t('data.stage.exec'),
        onClick: item => handleExecStage(item.id)
      }
    ],
    onRowClick: row => setSelectedData({ data: { ...row }, formType: 'etl' })
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={getStages}
        config={listConfig}
        ref={dataGridRef}
      />

      <ETLForm
        open={(selectedData?.formType === 'etl' || newItem)}
        data={selectedData?.data || null}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
        tables={tables}
      />

      <ETLFormRelation
        open={selectedData?.formType === 'relation'}
        data={selectedData?.data || null}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
        tables={tables}
      />
    </Box>
  )
}

StageView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

