import { enums } from './enums'

export const getConditionsByDataType = dataType => {
  let ops = []

  if (['varchar', 'text'].includes(dataType)) {
    ops = enums.condition
      .filter(op => ['=', '<>', 'NULLABLE', 'LIKE', 'NOT LIKE'].includes(op.key))
  } else if (dataType === 'bool') {
    ops = enums.condition
      .filter(op => ['=', 'NULLABLE'].includes(op.key))
  } else {
    ops = enums.condition
      .filter(op => ['=', '<>', '>', '>=', '<', '<=', 'NULLABLE'].includes(op.key))
  }

  return ops
}
