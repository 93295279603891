import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Collapse,
  CircularProgress,
  IconButton
} from '@mui/material'
import { t } from 'i18next'

import { getReportDashboard } from 'services/requests/report'
import { Toast } from 'utils'

import Iconify from 'components/Iconify'

import Visualization from './components/Visualization'

export default function WidgetPainel({
  item,
  configElement,
  removeElement
}) {
  const refContent = useRef(null)

  const visualizationMinHeight = item.render === 'big_number' ? 80 : 410

  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [expansible, setExpansible] = useState(false)
  const [data, setData] = useState({})

  const getData = () => {
    setLoading(true)

    const { report } = item

    getReportDashboard({ id: report })
      .then(res => {
        setData(res)
      })
      .catch((err) => {
        Toast(err, 'error')
        setData({
          series: [],
          groupBY: []
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.report])

  useEffect(() => {
    const contentHeight = refContent.current?.clientHeight
    const size = visualizationMinHeight
    if (contentHeight <= size) {
      setExpansible(false)
    } else {
      setExpansible(true)
    }
  }, [visualizationMinHeight, refContent.current?.clientHeight])

  return (
    <Card>
      <CardHeader
        title={item.title}
        subheader={item.subtitle}
      />
      <CardContent sx={{ py: 3 }}>
        <Collapse in={expanded} collapsedSize={visualizationMinHeight} timeout="auto">
          <div ref={refContent}>
            {
              loading ? (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: visualizationMinHeight
                }}>
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <>
                  <Visualization
                    data={data}
                    render={item.render}
                    filtersDb={[]}
                    grouprow={[]}
                    handleChartClick={() => {}}
                  />
                </>
              )
            }
          </div>
        </Collapse>
      </CardContent>
      <CardActions sx={{ mb: 2, mx: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {
          expansible ? (
            <Button
              size="small"
              color="inherit"
              variant="link"
              onClick={() => setExpanded(!expanded)}
              disabled={!expansible}
            >
              {t(expanded ? 'see_less' : 'see_more')}
            </Button>
          ) : null
        }
        {
          configElement ? (
            <IconButton sx={{ ml: 1 }} onClick={() => configElement()}>
              <Iconify icon="material-symbols:settings" />
            </IconButton>
          ) : null
        }
        {
          removeElement ? (
            <IconButton sx={{ color: 'error.main', ml: 1 }} onClick={() => removeElement()}>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          ) : null
        }
        {
          !configElement && !removeElement ? (
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={() => { }}
              startIcon={<Iconify icon="mdi:download" />}
            >
              { t('download_data') }
            </Button>
          ) : null
        }
      </CardActions>
    </Card>
  )
}

WidgetPainel.propTypes = {
  item: PropTypes.object,
  configElement: PropTypes.func,
  removeElement: PropTypes.func
}
