import { API } from 'services'

export function getInvites(params) {
  const { companyId = null, ...rest } = params || {}

  return API({
    endpoint: `/invite${companyId ? `/companyId/${companyId}` : ''}`,
    ...rest
  })
}

export function createInvite(params) {
  return API({
    method: 'POST',
    endpoint: '/invite',
    ...params
  })
}

export function resendInvite({ id, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/invite/resend/${id}`,
    ...params
  })
}

export function cancelInvite({ id, ...params }) {
  return API({
    method: 'DELETE',
    endpoint: `/invite/${id}`,
    ...params
  })
}

export function getInvitePreview({ token, ...params }) {
  return API({
    endpoint: `/invite/preview/${token}`,
    ...params
  })
}

export function acceptInvite({ token, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/invite/accept/${token}`,
    ...params
  })
}

export function refuseInvite({ token, ...params }) {
  return API({
    method: 'DELETE',
    endpoint: `/invite/refuse/${token}`,
    ...params
  })
}
