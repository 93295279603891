import { alpha } from '@mui/material/styles'

const GREY = {
  0: '#161C24',
  100: '#212B36',
  200: '#454F5B',
  300: '#637381',
  400: '#919EAB',
  500: '#C4CDD5',
  600: '#DFE3E8',
  700: '#F4F6F8',
  800: '#F9FAFB',
  900: '#FFFFFF',
}

const PRIMARY = {
  main: '#139BC3',
  contrastText: '#000',
}

const SECONDARY = {
  main: '#16E0A1',
  contrastText: '#000',
}

const INFO = {
  lighter: '#637381',
  light: '#C4CDD5',
  main: '#DFE3E8',
  dark: '#C4CDD5',
  darker: '#F4F6F8',
  contrastText: '#000'
}

const SUCCESS = {
  lighter: '#08660D',
  light: '#229A16',
  main: '#54D62C',
  dark: '#AAF27F',
  darker: '#E9FCD4',
  contrastText: GREY[800],
}

const WARNING = {
  lighter: '#7A4F01',
  light: '#B78103',
  main: '#FFC107',
  dark: '#FFE16A',
  darker: '#FFF7CD',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#7A0C2E',
  light: '#B72136',
  main: '#FF4842',
  dark: '#FFA48D',
  darker: '#FFE7D9',
  contrastText: '#000',
}

const palette = {
  common: { black: '#fff', white: '#000' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: GREY[0],
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
