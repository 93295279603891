import PropTypes from 'prop-types'
import { Fragment, useEffect, useContext } from 'react'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import FormDrawer from 'components/FormDrawer'

import { UserContext } from 'contexts'
import { createProfile, updateProfile } from 'services/requests/profile'
import { Toast, roles } from 'utils'

const initialValues = {
  name: '',
  role: ''
}

export default function ProfileForm({ open, data, onClose }) {
  const { state: { data: { profile: { role: currentUserRole } } } } = useContext(UserContext)

  const validationSchema = yup.object({
    name: yup.string()
      .required('Obrigatório'),
    role: yup.string()
      .required('Obrigatório'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ name, role }, { setSubmitting }) => {
      const method = data?.id ? updateProfile : createProfile

      method({ id: data?.id, body: { name, role } })
        .then(() => {
          Toast(t('settings.profiles.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  useEffect(() => {
    if (data?.id) {
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('role', data.role)
    }
    // eslint-disable-next-line
  }, [data])

  const handleClose = (reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }

  return (
    <FormDrawer
      title={t('settings.profiles.new')}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="name"
              label={t('settings.profiles.form.name')}
              value={formik.values.name}
              onChange={handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="subtitle1"
              my={3}
            >
              {t('settings.profiles.form.role')}
            </Typography>

            <FormControl
              component="fieldset"
              error={Boolean(formik.touched.role && formik.errors.role)}
              sx={{ width: '100%' }}
            >
              <FormGroup>
                <Grid container spacing={2}>
                  {
                    roles
                      .filter(item => (
                        (currentUserRole === 'superadmin' && item !== 'client') ||
                        (currentUserRole === 'client' && item !== 'superadmin') ||
                        (currentUserRole === 'editor' && item === 'viewer')
                      ))
                      .map((item, key) => (
                        <Grid item xs={12} sm={4} md={4} key={key}>
                          <FormControlLabel
                            name="role"
                            checked={formik.values.role === item}
                            value={item}
                            control={<Radio color="primary" />}
                            onChange={e => {
                              formik.setFieldTouched('role')
                              formik.setFieldValue('role', e.target.value)
                            }}
                            label={t(`roles.${item}`)}
                            labelPlacement="end"
                          />
                          <Typography variant="body2" color="gray" mb={2}>
                            {
                              t(`roles.${item}_description`).split('\n')
                                .map((_item, _key) => <Fragment key={`${key}-${_key}`}>{_item}<br /></Fragment>)
                            }
                          </Typography>
                        </Grid>
                      ))
                  }
                </Grid>
                {
                  formik.touched.role && formik.errors.role ? (
                    <FormHelperText color="error">{formik.errors.role}</FormHelperText>
                  ) : null
                }
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </FormDrawer>
  )
}

ProfileForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
}
