import { API } from 'services'


export function getReportsPreview(params) {
  return API({
    method: 'POST',
    endpoint: `/report/preview`,
    ...params
  })
}

export function getReports(params) {
  return API({
    endpoint: `/report`,
    ...params
  })
}

export function getReport({ id, params }) {
  return API({
    endpoint: `/report/${id}`,
    ...params
  })
}

export function createReport(params) {
  return API({
    method: 'POST',
    endpoint: `/report`,
    ...params
  })
}

export function updateReport({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/report/${id}`,
    ...params
  })
}

export function getReportDashboard({ id, ...params }) {
  return API({
    endpoint: `/report/dashboard/${id}`,
    ...params
  })
}
