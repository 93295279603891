import PropTypes from 'prop-types'
import { useState, useContext, useRef } from 'react'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import ACL from 'components/ACL'
import DataGrid from 'components/DataGrid'

import { UserContext } from 'contexts'
import { getProfiles } from 'services/requests/profile'
import { enums } from 'utils'

import ProfileForm from './Form'

export default function ProfilesView({ newItem, cancelNew }) {
  const { state: { data: { profile: { role: currentUserRole } } } } = useContext(UserContext)
  const dataGridRef = useRef(null)

  const [selectedProfile, setSelectedProfile] = useState(null)

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('settings.profiles.fields.name')
      },
      {
        accessorKey: 'role',
        accessorFn: row => t(`roles.${row.role}`),
        header: t('settings.profiles.fields.role'),
        filterVariant: 'select',
        filterSelectOptions: enums.roles.filter(item => (
          (currentUserRole === 'superadmin' && item.key !== 'client') ||
          (currentUserRole === 'client' && item.key !== 'superadmin') ||
          (currentUserRole === 'editor' && item.key === 'viewer')
        ))
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('settings.profiles.update'),
        onClick: item => setSelectedProfile(item)
      }
    ],
    onRowClick: row => setSelectedProfile(row)
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={getProfiles}
        config={listConfig}
        ref={dataGridRef}
      />

      <ACL roles={['superadmin', 'client', 'editor']}>
        <ProfileForm
          open={(!!selectedProfile?.id || newItem)}
          data={selectedProfile}
          onClose={(reload) => {
            cancelNew()
            setSelectedProfile(null)
            if (reload) {
              dataGridRef?.current.refreshData()
            }
          }}
        />
      </ACL>
    </Box>
  )
}

ProfilesView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

