import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { useFormik } from 'formik'
import * as yup from 'yup'

import FormDrawer from 'components/FormDrawer'
import WidgetPainel from 'components/WidgetPainel'

import { getReports } from 'services/requests/report'

const initialValues = {
  enable: true,
  title: '',
  subtitle: '',
  render: '',
  size: '',
  report: null
}

export default function FormVision({ open, data, onCancel, onConfirm }) {
  const [reports, setReports] = useState([])

  const validationSchema = yup.object({
    title: yup.string()
      .required(t('required')),
    subtitle: yup.string()
      .required(t('required')),
    render: yup.string()
      .required(t('required')),
    size: yup.string()
      .required(t('required')),
    report: yup.object()
      .required(t('required'))
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ report, ...body }, { setSubmitting }) => {
      onConfirm({ id: uuidv4(), ...body, report: report.id })
      setSubmitting(false)
      handleClose()
    },
  })

  const handleChangeReport = (_, value) => {
    formik.setFieldTouched('report')
    formik.setFieldValue('report', value)
  }

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleClose = (reload) => {
    formik.resetForm(initialValues)
    onCancel(reload)
  }

  useEffect(() => {
    if (data) {
      formik.setFieldValue('enable', data.enable)
      formik.setFieldValue('title', data.title)
      formik.setFieldValue('subtitle', data.subtitle)
      formik.setFieldValue('render', data.render)
      formik.setFieldValue('size', data.size)
      formik.setFieldValue('report', reports.find(item => item.id === data.report))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    getReports()
      .then(res => setReports(res))
      .catch(() => {})
  }, [])

  return (
    <FormDrawer
      title={t('visualization.panels.form_vision.main')}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextField
              name="title"
              label={t('visualization.panels.form_vision.title')}
              value={formik.values.title}
              onChange={handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={6}>
            <TextField
              name="subtitle"
              label={t('visualization.panels.form_vision.subtitle')}
              value={formik.values.subtitle}
              onChange={handleChange}
              error={formik.touched.subtitle && Boolean(formik.errors.subtitle)}
              helperText={formik.touched.subtitle && formik.errors.subtitle}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid
            item
            xs={12} sm={12} md={2} lg={1}
            display="flex"
            alignItems="center"
            justifyContent={{ xs: 'left', md: 'center' }}
          >
            <FormControl sx={{ px: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="enable"
                    checked={formik.values.enable}
                    onChange={handleChange}
                  />
                }
                label={t('visualization.panels.form_vision.enable')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                id="size"
                error={formik.touched.size && Boolean(formik.errors.size)}
              >
                {t('visualization.panels.form_vision.render')}
              </InputLabel>
              <Select
                labelId="render"
                name="render"
                label={t('visualization.panels.form_vision.render')}
                value={formik.values.render}
                onChange={handleChange}
                error={formik.touched.render && Boolean(formik.errors.render)}
              >
                <MenuItem value="line">{t('panel_render.line')}</MenuItem>
                <MenuItem value="bar">{t('panel_render.bar')}</MenuItem>
                <MenuItem value="column">{t('panel_render.column')}</MenuItem>
                <MenuItem value="treemap">{t('panel_render.treemap')}</MenuItem>
                <MenuItem value="table">{t('panel_render.table')}</MenuItem>
                <MenuItem value="big_number">{t('panel_render.big_number')}</MenuItem>
              </Select>
              {
                formik.touched.render && Boolean(formik.errors.render) ? (
                  <FormHelperText error>{formik.touched.render && formik.errors.render}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                id="size"
                error={formik.touched.size && Boolean(formik.errors.size)}
              >
                {t('visualization.panels.form_vision.size')}
              </InputLabel>
              <Select
                labelId="size"
                name="size"
                label={t('visualization.panels.form_vision.size')}
                value={formik.values.size}
                onChange={handleChange}
                error={formik.touched.size && Boolean(formik.errors.size)}
              >
                <MenuItem value="4">{t('panel_sizes.small')}</MenuItem>
                <MenuItem value="6">{t('panel_sizes.medium')}</MenuItem>
                <MenuItem value="8">{t('panel_sizes.large')}</MenuItem>
                <MenuItem value="12">{t('panel_sizes.total')}</MenuItem>
              </Select>
              {
                formik.touched.size && Boolean(formik.errors.size) ? (
                  <FormHelperText error>{formik.touched.size && formik.errors.size}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                name="report"
                value={formik.values.report}
                onChange={handleChangeReport}
                disabled={!reports?.length}
                options={reports}
                isOptionEqualToValue={(option, value) => (option?.name === value?.name)}
                getOptionLabel={option => option.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('visualization.panels.form_vision.report')}
                    error={formik.touched.report && Boolean(formik.errors.report)}
                  />
                )}
              />
              {
                formik.touched.report && Boolean(formik.errors.report) ? (
                  <FormHelperText error>{formik.touched.report && formik.errors.report}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3} mt={3}>
              {
                (formik.values.render && formik.values.size && formik.values.report?.id) ? (
                  <Grid item xs={12} sm={12} md={12} lg={Number(formik.values.size)}>
                    <WidgetPainel
                      item={{
                        ...formik.values,
                        report: formik.values.report?.id
                      }}
                    />
                  </Grid>
                ) : null
              }
            </Grid>
          </Grid>

        </Grid>
      </Box>
    </FormDrawer>
  )
}

FormVision.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}
