import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { t } from 'i18next'

import { thousandDetails, shortenLargeNumber } from 'utils'

import { useChart } from 'components/Chart'

export default function ChartBar({ data = {}, handleChartClick }) {
  const calculateHeight = (data) => {
    const height = 100 + ((data.data.length * (data.series.length > 1 ? data.series.length * 0.8 : 1)) * 40)
    return height > 100 ? height : 400
  }

  const series = data?.series?.map(item => ({
    name: item.name,
    data: data.data.map(_item => _item[item.name])
  })) || []

  const labels = data?.fields?.map(item => (
    data.data.map(_item => _item[item.header])
  )) || []

  const categories = labels?.length
    ? [...labels.reduce((a, b) => a.map((v, i) => `${v} / ${b[i]}`)).map(item => String(item))]
    : []

  const options = {
    noData: {
      text: t('empty_data')
    },
    grid: {
      show: false
    },
    chart: {
      background: '#fff',
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      },
      events: {
        click: async (event, chartContext, config) => {
          if (config.dataPointIndex !== -1) {
            handleChartClick(event, chartContext, config)
          }
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      distributed: true,
      style: {
        colors: ['#000'],
        fontSize: '10px'
      },
      formatter: val => thousandDetails(val)
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 30
      }
    },
    tooltip: {
      y: {
        formatter: val => thousandDetails(val)
      }
    },
    xaxis: {
      categories,
      categoriesDb: categories,
      details: data.groupBY,
      position: 'top',
      labels: {
        formatter: num => shortenLargeNumber(num)
      }
    }
  }

  if (data.splitStack) {
    options.chart.stacked = true
    options.chart.stackType = '100%'
  }

  const chartOptions = useChart(options)

  return (
    <ReactApexChart
      type="bar"
      series={series}
      options={chartOptions}
      height={calculateHeight(data)}
    />
  )
}

ChartBar.propTypes = {
  data: PropTypes.object,
  handleChartClick: PropTypes.func
}
