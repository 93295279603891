import PropTypes from 'prop-types'
import { useState, useContext } from 'react'
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  IconButton,
  InputAdornment
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Iconify from 'components/Iconify'
import TextField from 'components/TextField'

import { UserContext } from 'contexts'
import { updateCurrentUser } from 'services/requests/user'
import { Toast } from 'utils'

export default function AccountForm({ user }) {
  const userContext = useContext(UserContext)

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const validationSchema = yup.object({
    name: yup.string()
      .required('Obrigatório'),
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório')
  })

  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ name, email }, { setSubmitting, resetForm }) => {
      const body = { name, email }

      const response = await updateCurrentUser({ body })
        .then(() => {
          Toast(t('settings.my_account.form.success'), 'success')
          return true
        })
        .catch((err) => {
          Toast(err, 'error')
          return false
        })

      if (response) {
        const responseUser = await userContext.exec.getCurrentUser()
        resetForm({
          values: {
            name: responseUser.name,
            email: responseUser.email
          }
        })
      }

      setSubmitting(false)
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const validationSchemaPassword = yup.object({
    currentPassword: yup.string()
      .required('Obrigatório'),
    newPassword: yup.string()
      .required('Obrigatório')
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Senha fraca: sua senha deve conter números, letras maiúsculas, letras minúsculas e caracteres especiais'
      )
      .min(8, 'Sua senha deve conter pelo menos 8 caracteres')
      .max(32, 'Sua senha deve conter no máximo 32 caracteres')
  })

  const formikPassword = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: ''
    },
    validationSchema: validationSchemaPassword,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ currentPassword, newPassword }, { setSubmitting, resetForm }) => {
      const body = { currentPassword, newPassword }

      const response = await updateCurrentUser({ body })
        .then(() => {
          Toast(t('settings.my_account.form.success_password'), 'success')
          return true
        })
        .catch((err) => {
          Toast(err, 'error')
          return false
        })

      if (response) {
        resetForm({
          values: {
            currentPassword: '',
            newPassword: ''
          }
        })
      }

      setSubmitting(false)
    },
  })

  const handleChangePassword = e => {
    formikPassword.setFieldTouched(e.target.name)
    formikPassword.handleChange(e)
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Typography
              gutterBottom
              variant="h5"
            >
              {t('settings.my_account.form.personal')}
            </Typography>

            <TextField
              name="name"
              label={t('settings.my_account.form.name')}
              value={formik.values.name}
              onChange={handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              name="email"
              label={t('settings.my_account.form.email')}
              value={formik.values.email}
              onChange={handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 2 }}
                loading={formik.isSubmitting}
              >
                {t('settings.my_account.form.submit')}
              </LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Stack spacing={3}>
            <Typography
              gutterBottom
              variant="h5"
              pt={1}
            >
              {t('settings.my_account.form.access_password')}
            </Typography>

            <TextField
              name="currentPassword"
              label={t('settings.my_account.form.current_password')}
              type={showPassword ? 'text' : 'password'}
              value={formikPassword.values.currentPassword}
              onChange={handleChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formikPassword.touched.currentPassword && Boolean(formikPassword.errors.currentPassword)}
              helperText={formikPassword.touched.currentPassword && formikPassword.errors.currentPassword}
            />

            <TextField
              name="newPassword"
              label={t('settings.my_account.form.new_password')}
              type={showNewPassword ? 'text' : 'password'}
              value={formikPassword.values.newPassword}
              onChange={handleChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formikPassword.touched.newPassword && Boolean(formikPassword.errors.newPassword)}
              helperText={
                (formikPassword.touched.newPassword && formikPassword.errors.newPassword)
                  ? formikPassword.errors.newPassword
                  : t('settings.my_account.form.new_password_helper')
              }
            />

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => formikPassword.handleSubmit()}
                sx={{ mt: 2 }}
                loading={formikPassword.isSubmitting}
              >
                {t('settings.my_account.form.submit_password')}
              </LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

AccountForm.propTypes = {
  user: PropTypes.object
}
