import PropTypes from 'prop-types'
import React from "react";
import { Typography, List, Box, Tooltip, IconButton } from "@mui/material";
import { Info as IconInfo } from "@mui/icons-material";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Empty from "components/Empty";

import { SortableItem } from "./SortableItem";

export default function ContainerDnd(props) {
  const { id, items, type, height, name, description, storageTable, setRefreshData, fieldsReport, setFieldsReport } = props;
  const {
    isOver,
    attributes,
    listeners,
    setNodeRef
  } = useDroppable({
    id,
    typeParrent:props.type,
    data: {
      parent: null,
      typeParrent:props.type,
      isContainer: true
    }
  });

  return (
    <div>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0',
          bgcolor: theme => theme.palette.grey[600],
          color: theme => theme.palette.common.white,
        }}
      >
        <Typography variant="h6">
          {name}
        </Typography>
        {
          description ? (
            <Tooltip title={description}>
              <IconButton size="small">
                <IconInfo
                  fontSize="small"
                  sx={{
                    fill: theme => theme.palette.common.white
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null
        }
      </Box>
       <SortableContext
        strategy={verticalListSortingStrategy}
        items={items.map((f) => f.id)}
       >
        <List
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          type={type}
          sx={{
            px: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            height: height || '300px',
            borderRadius: '0 0 8px 8px',
            borderBottom: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            borderLeft: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            borderRight: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            bgcolor: theme => theme.palette.grey[200],
          }}
        >
          {
            items?.length > 0 ? (
              <>
                {
                  items.map((itemDrag, i) => (
                    <SortableItem
                      key={itemDrag.id}
                      id={itemDrag.id}
                      storageTable={storageTable}
                      typeParrent={type}
                      index={i}
                      itemProps={itemDrag}
                      type={itemDrag.type === 'spacer' ? 'spacer': id}
                      setRefreshData={setRefreshData}
                      fieldsReport={fieldsReport}
                      setFieldsReport={setFieldsReport}
                    />
                  ))
                }
              </>
            ) : (
              name === 'Dados' ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="80%"
                >
                  <Empty
                    hideTitle
                    sizeIllustration={150}
                  />
                </Box>
              ) : null
            )
          }
        </List>
       </SortableContext>
    </div>
  );
}

ContainerDnd.propTypes = {
  id: PropTypes.string,
  fieldsReport: PropTypes.object,
  type: PropTypes.string,
  storageTable: PropTypes.object,
  setRefreshData: PropTypes.func,
  setFieldsReport: PropTypes.func,
  items: PropTypes.array,
  height: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string
}
