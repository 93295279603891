import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

Loader.propTypes = {
  noSpacer: PropTypes.bool
}

export default function Loader({ noSpacer = false, ...props }) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: noSpacer ? 0 : 20
    }}>
      <CircularProgress {...props} />
    </Box>
  )
}
