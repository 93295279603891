import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import Loader from 'components/Loader'
import Empty from 'components/Empty'
import WidgetPainel from 'components/WidgetPainel'

import { getPanelDashboard } from 'services/requests/panel'
import { Toast } from 'utils'

export default function DashboardAppPage() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const [panel, setPanel] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (id) {
      setLoading(true)

      getPanelDashboard({ id })
        .then((res) => setPanel(res))
        .catch((err) => {
          Toast(err, 'error')
          navigate('/', { replace: true })
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <Helmet>
        <title>{panel?.name || t('panels.panel.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          {
            loading ? (
              <Loader />
            ) : (
              <Stack>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    <Typography variant="h4">
                      {panel?.name || t('panels.panel.page_title')}
                    </Typography>
                  </Grid>
                </Grid>
                <div>
                  {
                    panel?.config?.length > 0 ? (
                      <Grid container spacing={3} mt={3}>
                        {
                          panel.config.map((item, key) => (
                            <Grid item xs={12} sm={12} md={12} lg={Number(item.size)} key={key}>
                              <WidgetPainel
                                item={item}
                              />
                            </Grid>
                          ))
                        }
                      </Grid>
                    ) : (
                      <Empty />
                    )
                  }
                </div>
              </Stack>
            )
          }
        </Container>
      </Box>
    </>
  );
}
