import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Stack, Typography, Grid, Button } from '@mui/material'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

import PanelsView from 'sections/visualization/Panels/View'

export default function Panels() {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{t('visualization.panels.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
              >
                <Typography variant="h4">
                  {t('visualization.panels.title')}
                </Typography>
                <Typography variant="body2">
                  {t('visualization.panels.description')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  size="large"
                  type="submit"
                  sx={{ mt: { xs: 2, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
                  variant="contained"
                  onClick={() => navigate('/visualizacao/paineis/novo')}
                  startIcon={<Iconify icon="mdi:plus" />}
                >
                  {t('visualization.panels.new')}
                </Button>
              </Grid>
            </Grid>
            <div>
              <PanelsView />
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
