import PropTypes from 'prop-types'
import { useState, useContext } from 'react'
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom'
import { Stack, IconButton, InputAdornment, TextField, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Iconify from 'components/Iconify'

import { UserContext } from 'contexts'
import { Toast } from 'utils'

const initialValues = {
  email: '',
  password: ''
}

export default function LoginForm({ t }) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userContext = useContext(UserContext)

  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object({
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    password: yup.string()
      .required('Obrigatório'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email, password }, { setSubmitting }) => {
      userContext.exec
        .login({ body: { email, password } })
        .then(() => navigate(`/${searchParams.get('next') || ''}`, { replace: true }))
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('auth.login.form.email')}
          value={formik.values.email}
          onChange={handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          name="password"
          label={t('auth.login.form.password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
        <Link to={'/auth/recuperar-senha'} component={RouterLink} variant="subtitle2" underline="hover">
          {t('auth.login.form.forgot_password')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ mt: 2 }}
      >
        {t('auth.login.form.submit')}
      </LoadingButton>

      <Link to={'/auth/criar-conta'} component={RouterLink}>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          sx={{ mt: 2 }}
        >
          {t('auth.login.form.register')}
        </LoadingButton>
      </Link>
    </>
  )
}

LoginForm.propTypes = {
  t: PropTypes.func
}
