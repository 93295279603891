import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Nav from 'components/DashNav'
import Header from 'components/DashHeader'

import {
  StyledRoot,
  Main
} from './styled'

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  )
}
