import PropTypes from 'prop-types'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stack, IconButton, InputAdornment, Typography, CircularProgress, Button, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useDialog } from 'components/Dialog'
import Iconify from 'components/Iconify'
import TextField from 'components/TextField'

import { getInvitePreview, acceptInvite, refuseInvite } from 'services/requests/invite'
import { UserContext } from 'contexts'
import { Toast } from 'utils'

const initialValues = {
  name: '',
  password: ''
}

export default function InviteForm({ t }) {
  const { token } = useParams()
  const navigate = useNavigate()
  const { exec } = useContext(UserContext)
  const dialog = useDialog()

  const [dataPreview, setDataPreview] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingRefuse, setLoadingRefuse] = useState(false)
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const getDataPreview = () => {
    if (token) {
      getInvitePreview({ token })
        .then(res => {
          if (res.type === 'existuser') {
            if (res.email) {
              setDataPreview(res)
            } else {
              navigate(`/auth?next=auth/convite/${token}`, { replace: true })
              Toast('Você deve se autenticar para visualizar o convite', 'success')
            }
          } else {
            setDataPreview(res)
          }
        })
        .catch(() => setError(true))
        .then(() => setLoadingPreview(false))
    } else {
      navigate('/', { replace: true })
    }
  }

  useEffect(() => {
    getDataPreview()
    // eslint-disable-next-line
  }, [token])

  const handleRefuseInvite = () => {
    dialog({
      title: t('auth.invite.form.refuse'),
      description: t('auth.invite.refuse_alert')
    })
      .then(() => {
        setLoadingRefuse(true)

        refuseInvite({ token })
          .then(() => {
            Toast(t('auth.invite.refuse_success'), 'success')
            navigate('/', { replace: true })
          })
          .catch(() => Toast(t('auth.invite.refuse_error'), 'error'))
          .then(() => setLoadingRefuse(false))
      })
      .catch(() => { })
  }

  const validationSchema = yup.object({
    name: yup.string()
      .required('Obrigatório'),
    password: yup.string()
      .required('Obrigatório')
      .matches(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Senha fraca: sua senha deve conter números, letras maiúsculas, letras minúsculas e caracteres especiais'
      )
      .min(8, 'Sua senha deve conter pelo menos 8 caracteres')
      .max(32, 'Sua senha deve conter no máximo 32 caracteres'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ name, password }, { setSubmitting }) => {
      const response = await acceptInvite({ token, body: { name, password, type: dataPreview.type } })
        .then(() => true)
        .catch((err) => {
          Toast(err, 'error')
          setSubmitting(false)
          return false
        })

      if (response) {
        exec
          .login({ body: { email: dataPreview.email, password } })
          .then(() => true)
          .catch(() => Toast('Conta criada com sucesso', 'success'))
          .then(() => {
            navigate('/', { replace: true })
            setSubmitting(false)
          })
      }
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleSubmit = async () => {
    if (dataPreview.type === 'newuser') {
      formik.handleSubmit()
    } else {
      setLoadingSubmit(true)

      const response = await acceptInvite({ token, body: { type: dataPreview.type } })
        .then(() => true)
        .catch((err) => {
          Toast(err, 'error')
          setLoadingSubmit(false)
          return false
        })

      if (response) {
        exec.getCurrentUser()
        navigate('/', { replace: true })
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <>
      {
        loadingPreview ? (
          <Stack display="flex" alignItems="center">
            <CircularProgress size={80} sx={{ my: 6 }} />
          </Stack>
        ) : error ? (
          <>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 0 }}>
              {t('auth.invite.error.title')}
            </Typography>

            <Typography sx={{ color: 'text.secondary', textAlign: 'center', mb: 4 }}>
              {t('auth.invite.error.description')}
            </Typography>

            <Button onClick={() => navigate('/', { replace: true })} size="large" variant="contained">
              {t('auth.invite.error.action')}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              {t(`auth.invite.title`)}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {t(`auth.invite.description`)}
              <b>{dataPreview.company_name}</b>
            </Typography>

            {
              dataPreview.type === 'newuser' ? (
                <Stack spacing={3}>
                  <TextField
                    name="email"
                    label={t('auth.invite.form.email')}
                    value={dataPreview.email}
                    disabled
                  />

                  <TextField
                    name="name"
                    label={t('auth.invite.form.name')}
                    value={formik.values.name}
                    onChange={handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    name="password"
                    label={t('auth.invite.form.password')}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Stack>
              ) : null
            }

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => handleSubmit()}
              loading={formik.isSubmitting || loadingSubmit}
              disabled={loadingRefuse}
              sx={{ my: 2 }}
            >
              {t('auth.invite.form.submit')}
            </LoadingButton>

            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
              <Link
                onClick={() => handleRefuseInvite()}
                variant="subtitle2"
                underline="hover"
                color="error"
                className="link"
              >
                {t('auth.invite.form.refuse')}
              </Link>
            </Stack>
          </>
        )
      }
    </>
  )
}

InviteForm.propTypes = {
  t: PropTypes.func
}
