import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie'

import { EventBus } from 'utils'
import { Storage } from 'services'

import { login, logout } from 'services/requests/auth'
import { getCurrentUser } from 'services/requests/user'

export const UserContext = createContext(null)

UserProvider.propTypes = {
  children: PropTypes.node
}

export function UserProvider({ children }) {
  const navigate = useNavigate()
  const cookie = new Cookies();
  const [initialized, setInitialized] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [data, setData] = useState(null)

  const exec = {
    login(params) {
      return login(params)
        .then(async (response) => {
          cookie.set('access_token', response?.token, {
            path: '/',
            expires: new Date(response.expires)
          })

          const _this = this
          await _this.getCurrentUser()
        })
    },
    async logout() {
      await logout()

      setIsLoggedIn(false)
      setData(null)
      cookie.remove('access_token')
      Storage.delete({ key: '-user' })
      navigate('/auth')

      return true
    },
    getCurrentUser() {
      return getCurrentUser()
        .then((user) => {
          const { company, profiles } = user.userCompany.find(item => item.isSelected)
          const profile = profiles.find(item => item.isSelected)
          const newData = {
            ...user,
            company,
            profile
          }

          setIsLoggedIn(true)
          setData({ ...newData })
          Storage.set({ key: '-user', value: { ...newData } })

          return newData
        })
        .catch(() => {
          if (isLoggedIn) {
            setIsLoggedIn(false)
            setData(null)
            cookie.remove('access_token')
            Storage.delete({ key: '-user' })
            navigate('/auth')
          }
        })
    }
  }

  EventBus.override('logout', exec.logout)

  const getInitialData = async () => {
    if (cookie.get('access_token')) {
      const userFromStorage = Storage.get({ key: '-user' })

      if (userFromStorage) {
        setData(userFromStorage)
        setIsLoggedIn(true)
        setInitialized(true)
      }

      await exec.getCurrentUser()
    } else {
      setIsLoggedIn(false)
      setData(null)
      cookie.remove('access_token')
      Storage.delete({ key: '-user' })
    }

    setInitialized(true)
  }

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserContext.Provider value={{ state: { initialized, isLoggedIn, data }, exec }}>
      {children}
    </UserContext.Provider>
  )
}
