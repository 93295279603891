import PropTypes from 'prop-types'
import { useContext } from 'react'

import { UserContext } from 'contexts'
import { Storage } from 'services'

const isApproved = ({ roles, isLogged, condition }, { isLoggedIn, role }) => {
  if (roles !== undefined && isLogged !== undefined) {
    return Boolean(isLogged) === isLoggedIn || roles.includes(role)
  }

  if (roles !== undefined) {
    return roles.includes(role)
  }

  if (isLogged !== undefined) {
    return Boolean(isLogged) === isLoggedIn
  }

  if (condition === false) {
    return false
  }

  return true
}

export default function ACL({ children, roles, isLogged, condition }) {
  const { state: { isLoggedIn, data: { profile: { role } } } } = useContext(UserContext)

  return (
    isApproved({ roles, isLogged, condition }, { isLoggedIn, role })
      ? children
      : null
  )
}

export const isAuthorized = params => {
  const roles = params?.roles
  const isLogged = params?.isLogged
  const condition = params?.condition

  const { id: isLoggedIn, profile: { role } } = Storage.get({ key: '-user' })

  return isApproved({ roles, isLogged, condition }, { isLoggedIn, role })
}

ACL.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array,
  isLogged: PropTypes.bool,
  condition: PropTypes.bool
}
