import PropTypes from 'prop-types'
import { useState, useEffect, useContext, useRef } from 'react'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { t } from 'i18next'

import ACL, { isAuthorized } from 'components/ACL'
import DataGrid from 'components/DataGrid'
import { useDialog } from 'components/Dialog'

import { UserContext } from 'contexts'
import { getInvites, resendInvite, cancelInvite } from 'services/requests/invite'
import { getProfiles } from 'services/requests/profile'
import { Toast, enums } from 'utils'

import InviteForm from './Form'

export default function InvitesView({ newItem, cancelNew }) {
  const { state: { data: { profile: { role: currentUserRole } } } } = useContext(UserContext)
  const dataGridRef = useRef(null)
  const dialog = useDialog()

  const [profiles, setProfiles] = useState([])

  const listConfig = {
    fields: [
      {
        accessorKey: 'email',
        header: t('settings.invites.fields.email')
      },
      {
        accessorKey: 'profiles.name',
        accessorFn: row => row.profiles.map(item => item.name).join(', '),
        header: t('settings.invites.fields.profiles'),
        filterVariant: 'select',
        filterSelectOptions: profiles.map(item => item.name)
      },
      {
        accessorKey: 'status',
        accessorFn: row => `${t(`invite.${row.status}`)}${row.status === 'pending' ? ` (${t('expires_in')} ${format(row.expiresAt, 'dd/MM/yyyy')})` : ''}`,
        header: t('settings.invites.fields.status'),
        filterVariant: 'select',
        filterSelectOptions: enums.inviteStatus
      },
      {
        accessorKey: 'userInvitor.name',
        header: t('settings.invites.fields.invitor_name')
      }
    ],
    actions: [
      {
        icon: 'mdi:email-resend-outline',
        label: t('settings.invites.resend_title'),
        onClick: (row, event) => handleResendInvite(row, event),
        condition: row => row.status === 'pending'
      },
      {
        icon: 'mdi:ban',
        label: t('settings.invites.cancel_title'),
        onClick: (row, event) => handleCancelInvite(row, event),
        condition: row => row.status === 'pending'
      }
    ]
  }

  const getDataProfiles = () => {
    getProfiles()
      .then(res => {
        if (currentUserRole === 'editor') {
          res = res.filter(item => item.role === 'viewer')
        }
        setProfiles(res)
      })
      .catch(() => Toast('Não foi possível obter os perfís disponíveis'))
  }

  useEffect(() => {
    const authorized = isAuthorized({ roles: ['superadmin', 'client', 'editor'] })

    if (authorized) {
      getDataProfiles()
    }
    // eslint-disable-next-line
  }, [])

  const handleResendInvite = (row, e) => {
    e.stopPropagation()

    dialog({
      title: t('settings.invites.resend_title'),
      description: t('settings.invites.resend_description', { email: row.email })
    })
      .then(() => {
        resendInvite({ id: row.id })
          .then(() => {
            dataGridRef?.current.refreshData()
            Toast(t('settings.invites.resend_success'), 'success')
          }).catch(err => {
            Toast(err, 'error')
          })
      })
      .catch(() => {})
  }

  const handleCancelInvite = (row, e) => {
    e.stopPropagation()

    dialog({
      title: t('settings.invites.cancel_title'),
      description: t('settings.invites.cancel_description', { email: row.email, profiles: row.profiles.map(item => item.name).join(', ') })
    })
      .then(() => {
        cancelInvite({ id: row.id })
          .then(() => {
            dataGridRef?.current.refreshData()
            Toast(t('settings.invites.cancel_success'), 'success')
          }).catch(err => {
            Toast(err, 'error')
          })
      })
      .catch(() => {})
  }

  return (
    <Box mt={5}>
      <DataGrid
        config={listConfig}
        getData={getInvites}
        ref={dataGridRef}
      />

      <ACL roles={['superadmin', 'client', 'editor']}>
        <InviteForm
          open={newItem}
          onClose={(reload) => {
            cancelNew()
            if (reload) {
              dataGridRef?.current.refreshData()
            }
          }}
          profiles={profiles}
        />
      </ACL>
    </Box>
  )
}

InvitesView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

