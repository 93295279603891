import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ACL, { isAuthorized } from 'components/ACL'
import TextField from 'components/TextField'

import { updateCurrentCompany } from 'services/requests/company'
import { Toast, removeMask } from 'utils'

const initialValues = {
  name: '',
  fantasyName: '',
  cnpj: ''
}

export default function MyCompanyForm({ data, refresh }) {
  const disabledUpdate = !isAuthorized({ roles: ['superadmin', 'client'] })

  const validationSchema = yup.object({
    name: yup.string()
      .required('Obrigatório'),
    fantasyName: yup.string()
      .required('Obrigatório'),
    cnpj: yup.string()
      .cnpj('O CNPJ deve ser válido')
      .required('Obrigatório'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ name, fantasyName, cnpj }, { setSubmitting }) => {
      const body = { name, fantasyName, cnpj: removeMask(cnpj) }

      const response = await updateCurrentCompany({ body })
        .then(() => true)
        .catch((err) => {
          Toast(err, 'error')
          return false
        })

      if (response) {
        Toast(t('settings.my_company.form.success'), 'success')
        refresh()
      }

      setSubmitting(false)
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  useEffect(() => {
    if (data?.id) {
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('fantasyName', data.fantasyName)
      formik.setFieldValue('cnpj', data.cnpj)
    }
  // eslint-disable-next-line
  }, [data])

  return (
    <Card>
      <CardContent>
        <Stack spacing={3} py={disabledUpdate ? 2 : 0}>
          <ACL roles={['superadmin', 'client']}>
            <Typography
              gutterBottom
              variant="h5"
            >
              {t('settings.my_company.form.personal')}
            </Typography>
          </ACL>

          <TextField
            name="name"
            label={t('settings.my_company.form.name')}
            value={formik.values.name}
            onChange={handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={disabledUpdate}
          />

          <TextField
            name="fantasyName"
            label={t('settings.my_company.form.fantasy_name')}
            value={formik.values.fantasyName}
            onChange={handleChange}
            error={formik.touched.fantasyName && Boolean(formik.errors.fantasyName)}
            helperText={formik.touched.fantasyName && formik.errors.fantasyName}
            disabled={disabledUpdate}
          />

          <TextField
            name="cnpj"
            label={t('settings.my_company.form.cnpj')}
            value={formik.values.cnpj}
            onChange={handleChange}
            error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
            helperText={formik.touched.cnpj && formik.errors.cnpj}
            mask="99.999.999/9999-99"
            disabled={disabledUpdate}
          />

          <ACL roles={['superadmin', 'client']}>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 2 }}
                loading={formik.isSubmitting}
              >
                {t('settings.my_company.form.submit')}
              </LoadingButton>
            </Box>
          </ACL>
        </Stack>
      </CardContent>
    </Card>
  )
}

MyCompanyForm.propTypes = {
  data: PropTypes.object,
  refresh: PropTypes.func
}
