import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  DndContext,
  DragOverlay,
  useDndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  useSortable,
  arrayMove,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import { motion } from 'framer-motion'
import { t } from 'i18next'

import Dialog from 'components/Dialog'
import Iconify from 'components/Iconify'

const ComponentsIconsRender = {
  bar: () => <Iconify icon="ph:chart-bar-horizontal" />,
  column: () => <Iconify icon="ph:chart-bar" />,
  line: () => <Iconify icon="ph:chart-line" />,
  table: () => <Iconify icon="ph:table" />,
  treemap: () => <Iconify icon="ph:grid-four" />,
  big_number: () => <Iconify icon="ph:number-square-one" />
}

export default function SortVision({
  open = false,
  items,
  onCancel,
  onConfirm,
  loadingSubmit
}) {
  const [config, setConfig] = useState([])
  const [active, setActive] = useState(null)

  const handleDragStart = ({ active }) => {
    const activeItem = config.find(item => item.id === active.id)
    setActive(activeItem)
  }

  const handleDragOver = ({ active, over }) => {
    setConfig((config) =>
      arrayMove(
        config,
        config.findIndex(item => item.id === active.id),
        config.findIndex(item => item.id === over?.id)
      )
    )
  }

  const handleDragEnd = () => {
    setActive(null)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    setConfig(items || [])
  }, [items])

  return (
    <Dialog
      open={open}
      options={{
        title: t('visualization.panel_detail.sort_vision.title'),
        content: (
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={config} strategy={() => null}>
              <div className="vision-reorder">
                {
                  config.map((item, key) => (
                    <Item key={key} item={item} activeId={active?.id} />
                  ))
                }
              </div>
            </SortableContext>

            <DragOverlay>
              {active?.id ? <DragOverlayItem item={active} /> : null}
            </DragOverlay>
          </DndContext>
        ),
        dialogProps: {
          maxWidth: 'md'
        },
        cancellationText: t('visualization.panel_detail.sort_vision.cancel'),
        confirmationText: t('visualization.panel_detail.sort_vision.submit'),
        confirmationButtonProps: {
          loading: loadingSubmit
        }
      }}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm(config)}
    />
  )
}

function Item({ activeId, item }) {
  const sortable = useSortable({
    id: item.id
  })

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition
  } = sortable

  return (
    <motion.div
      ref={setNodeRef}
      layoutId={item.id}
      transition={{
        type: 'spring',
        duration: activeId ? 0 : 0.6
      }}
      className={`vision-reorder-card ${activeId === item?.id ? 'selected' : ''}`}
      style={{
        gridColumn: `span ${item.size}`,
        transform: transform
          ? `translate(${transform.x}px, ${transform.y}px)`
          : 'none',
        transition
      }}
      {...attributes}
      {...listeners}
    >
      {ComponentsIconsRender?.[item.render]() || null}
      {item.title}
    </motion.div>
  )
}

function DragOverlayItem({ item }) {
  const isReallyActive = useDndIsReallyactive(item.id)

  return (
    <div
      className="vision-reorder-card overlay"
      style={{
        transform: isReallyActive ? "scale(1.05)" : "none"
      }}
    >
      { ComponentsIconsRender?.[item.render]() || null }
      { item.title }
    </div>
  )
}

function useDndIsReallyactive(id) {
  const context = useDndContext()
  const isActive = context.active?.id === id
  return isActive
}

SortVision.propTypes = {
  open: PropTypes.bool,
  items: PropTypes.array,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  loadingSubmit: PropTypes.bool
}

Item.propTypes = {
  activeId: PropTypes.string,
  item: PropTypes.object
}

DragOverlayItem.propTypes = {
  item: PropTypes.object
}
