import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Autocomplete,
  MenuItem,
  Checkbox,
  ListItemText,
  Paper,
  Button,
  IconButton,
  FormHelperText,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import { t } from 'i18next'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import { LocalizationProvider, DateTimePicker, DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale'

import Dialog from 'components/Dialog'
import Iconify from 'components/Iconify'
import NumberFormatCustom from 'components/NumberFormatCustom'

import { getStageTableColumnValues } from 'services/requests/stage'
import { getConditionsByDataType } from 'utils'

const initialValueCondition = {
  condition: '=',
  value: '',
}

const initialValues = {
  columns: [],
  filters: []
}

export default function StageFormFilter({ open, values, table, columns, onConfirm, onCancel }) {
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ filters }, { setSubmitting }) => {
      const parsedFilters = filters
        .map(({ field, dataType, conditions }) => ({
          field,
          dataType,
          conditions: conditions.filter(_item => (_item.value || _item.value?.length > 0))
        }))
        .filter(item => item.conditions.length > 0)

      setSubmitting(false)
      onConfirm(parsedFilters)
      handleClose()
    },
  })

  const getDistinctValues = async fields => {
    const res = await Promise.all(fields.map(async item => {
      let distinctValues = null
      const field = item?.field || item.attribute
      const dataType = item?.dataType || item.converted_type
      const conditions = item?.conditions?.length ? item.conditions : [initialValueCondition]

      if (dataType === 'varchar' || dataType === 'text') {
        distinctValues = await getStageTableColumnValues({ connectionId: table.connectionId, table: table.table, column: field })
      }

      return ({
        distinctValues: distinctValues || [],
        field,
        dataType,
        conditions
      })
    }))

    return res
  }

  const handleClose = () => {
    formik.resetForm(initialValues)
    onCancel()
  }

  const handleConfirm = () => {
    formik.handleSubmit()
  }


  const handleChangeColumns = async (_, values) => {
    const existItemsFilter = formik.values.filters.filter(item => values.map(item => item.attribute).includes(item.field))
    let toAddFilter = values.filter(item => !formik.values.filters.map(item => item.field).includes(item.attribute))

    if (toAddFilter?.length) {
      toAddFilter = await getDistinctValues(toAddFilter)
    }

    const newFilters = [
      ...existItemsFilter,
      ...toAddFilter
    ]

    formik.setFieldValue('columns', values)
    formik.setFieldValue('filters', newFilters)
  }

  const handleRemoveConditionField = async (subarrayHelpers, subIndex, arrayHelpers, index) => {
    await subarrayHelpers.remove(subIndex)

    if (formik.values.filters[index].conditions.length === 1) {
      const item = { ...formik.values.filters[index] }
      arrayHelpers.remove(index)

      const indexToRemove = formik.values.columns.findIndex(_item => item.field === _item.attribute)
      if (indexToRemove > -1) {
        const arr = [...formik.values.columns]
        arr.splice(indexToRemove, 1)
        formik.setFieldValue('columns', [...arr])
      }
    }
  }

  const handleAddFilterCondition = (index) => {
    const newConditions = [
      ...formik.values.filters[index].conditions,
      { ...initialValueCondition }
    ]
    const filters = [...formik.values.filters]
    filters[index].conditions = [...newConditions]

    formik.setFieldValue('filters', filters)
  }

  const handleChangeCondition = (e, index, _index) => {
    const { value } = e.target
    formik.setFieldValue(`filters.${index}.conditions.${_index}.condition`, value)
    formik.setFieldValue(`filters.${index}.conditions.${_index}.value`, '')
  }

  const fillEditForm = async () => {
    const filters = await getDistinctValues(values)

    const cols = values.map(item => item.field)
    const selectedCols = columns.filter(item => cols.includes(item.attribute))

    formik.setFieldValue('columns', selectedCols)
    formik.setFieldValue('filters', filters)
  }

  useEffect(() => {
    if (open && values?.length) {
      fillEditForm()
    }
    // eslint-disable-next-line
  }, [values, open])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Dialog
        open={open}
        options={{
          title: t('data.stage.form.filters'),
          content: (
            <Box pt={2}>
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  name="columns"
                  value={formik.values.columns}
                  onChange={handleChangeColumns}
                  disabled={!columns?.length}
                  options={columns}
                  getOptionLabel={option => option.attribute || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('data.stage.form.columns')}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem value={option.attribute} {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.attribute} />
                    </MenuItem>
                  )}
                  PaperComponent={paperProps => {
                    const { children, ...restPaperProps } = paperProps
                    return (
                      <Paper {...restPaperProps} sx={{ zIndex: 999999999 }}>
                        {children}
                      </Paper>
                    )
                  }}
                />
              </FormControl>

              <FormikProvider value={formik}>
                <FieldArray
                  name="filters"
                  render={arrayHelpers => (
                    <div>
                      {
                        formik.values.filters?.map((item, key) => (
                          <Box my={2} key={key}>
                            <Paper elevation={2}>
                              <Box px={2} pb={3}>
                                <Grid container spacing={2} my={1}>
                                  <Grid item xs={6} sm={6} md={7} lg={8} display="flex" alignItems="center">
                                    <Typography variant="subtitle1">
                                      {item.field}
                                    </Typography>
                                    <Typography variant="body2">&nbsp;&nbsp;({item.dataType})</Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={5} lg={4} display="flex" alignItems="center" justifyContent="flex-end">
                                    <Button onClick={() => handleAddFilterCondition(key)} size="small">
                                      {t('data.stage.form_filter.add_condition')}
                                    </Button>
                                  </Grid>
                                </Grid>

                                <FieldArray
                                  name={`filters[${key}].conditions`}
                                  render={subarrayHelpers => (
                                    <div>
                                      {
                                        formik.values.filters[key].conditions?.map((__, _key) => (
                                          <Grid container spacing={2} key={`${key}_${_key}`} mt={1}>
                                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                              <FormControl
                                                sx={{ width: '100%' }}
                                                error={formik.touched.filters?.[key]?.conditions?.[_key]?.condition && Boolean(formik.errors.filters?.[key]?.conditions?.[_key]?.condition)}
                                              >
                                                <InputLabel id={`condition-${key}-${_key}`}>{t('data.stage.form_filter.condition')}</InputLabel>
                                                <Select
                                                  labelId={`condition-${key}-${_key}`}
                                                  label={t('data.stage.form_filter.condition')}
                                                  name={`filters.${key}.conditions.${_key}.condition`}
                                                  value={formik.values.filters[key].conditions[_key].condition}
                                                  onChange={e => handleChangeCondition(e, key, _key)}
                                                  sx={{ background: 'rgba(255, 255, 255, .4)' }}
                                                >
                                                  {
                                                    getConditionsByDataType(formik.values.filters[key].dataType).map((item, key) => (
                                                      <MenuItem value={item.key} key={key}>
                                                        {item.label}
                                                      </MenuItem>
                                                    ))
                                                  }
                                                </Select>
                                                {
                                                  (formik.touched.filters?.[key]?.conditions?.[_key]?.condition && Boolean(formik.errors.filters?.[key]?.conditions?.[_key]?.condition)) ? (
                                                    <FormHelperText error>{formik.errors.filters?.[key]?.conditions?.[_key]?.condition}</FormHelperText>
                                                  ) : null
                                                }
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={10} sm={11} md={11} lg={6}>
                                              {
                                                getInputFilter(formik, key, _key)
                                              }
                                            </Grid>
                                            <Grid item xs={2} sm={1} md={1} lg={1} display="flex" alignItems="center" justifyContent="flex-end">
                                              <IconButton onClick={() => handleRemoveConditionField(subarrayHelpers, _key, arrayHelpers, key)} color="error">
                                                <Iconify icon="mdi:trash" />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        ))
                                      }
                                    </div>
                                  )}
                                />
                              </Box>
                            </Paper>
                          </Box>
                        ))
                      }
                    </div>
                  )}
                />
              </FormikProvider>
            </Box>
          ),
          cancellationText: 'data.stage.form_filter.cancel',
          confirmationText: 'data.stage.form_filter.save',
          dialogProps: {
            maxWidth: 'md',
            PaperProps: {
              style: {
                backgroundColor: '#f8f8f8'
              }
            }
          }
        }}
        onCancel={() => handleClose()}
        onConfirm={() => handleConfirm()}
      />
    </LocalizationProvider>
  )
}

export const getInputFilter = (formik, key, _key) => {
  const { conditions, dataType, distinctValues } = formik.values.filters[key]
  const { condition, value } = conditions[_key]

  const handleChangeAutocomplete = (_, newValues) => {
    formik.setFieldValue(`filters.${key}.conditions.${_key}.value`, newValues)
  }

  const handleChange = (e) => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeDateTime = (name, date) => {
    formik.setFieldTouched(name)
    formik.setFieldValue(name, date)

  }

  const getLikeInput = () => (
    <TextField
      name={`filters.${key}.conditions.${_key}.value`}
      label={t('data.stage.form_filter.value_like')}
      value={value}
      onChange={handleChange}
    />
  )

  const getNullableInput = () => (
    <RadioGroup
      name={`filters.${key}.conditions.${_key}.value`}
      value={value}
      onChange={handleChange}
      sx={{ mt: 1, display: 'flex', justifyContent: 'space-around' }}
      row
    >
      <FormControlLabel value="NULL" control={<Radio />} label="NULL" />
      <FormControlLabel value="NOT NULL" control={<Radio />} label="NOT NULL" />
    </RadioGroup>
  )

  const getTextInput = () => (
    <Autocomplete
      multiple
      limitTags={2}
      name={`filters.${key}.conditions.${_key}.value`}
      value={value || []}
      onChange={handleChangeAutocomplete}
      disabled={!distinctValues?.length}
      options={distinctValues}
      getOptionLabel={option => option || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('data.stage.form_filter.value')}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem value={option} {...props}>
          <Checkbox checked={selected} />
          <ListItemText primary={option} />
        </MenuItem>
      )}
      PaperComponent={paperProps => {
        const { children, ...restPaperProps } = paperProps
        return (
          <Paper {...restPaperProps} sx={{ zIndex: 999999999 }}>
            {children}
          </Paper>
        )
      }}
    />
  )

  const getBoolInput = () => (
    <RadioGroup
      name={`filters.${key}.conditions.${_key}.value`}
      value={value}
      onChange={handleChange}
      sx={{ mt: 1, display: 'flex', justifyContent: 'space-around' }}
      row
    >
      <FormControlLabel value="0" control={<Radio />} label="FALSE" />
      <FormControlLabel value="1" control={<Radio />} label="TRUE" />
    </RadioGroup>
  )

  const getNumericInput = () => (
    <TextField
      name={`filters.${key}.conditions.${_key}.value`}
      label={t('data.stage.form_filter.value')}
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
  )

  const getTimestampInput = () => (
    <DateTimePicker
      name={`filters.${key}.conditions.${_key}.value`}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime(`filters.${key}.conditions.${_key}.value`, date)}
    />
  )

  const getDateInput = () => (
    <DatePicker
      name={`filters.${key}.conditions.${_key}.value`}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime(`filters.${key}.conditions.${_key}.value`, date)}
    />
  )

  const getTimeInput = () => (
    <TimePicker
      name={`filters.${key}.conditions.${_key}.value`}
      label={t('data.stage.form_filter.value')}
      value={value || null}
      onChange={date => handleChangeDateTime(`filters.${key}.conditions.${_key}.value`, date)}
    />
  )

  return (
    <FormControl sx={{ width: '100%' }}>
      {
        (condition === 'LIKE' || condition === 'NOT LIKE') ? (
          getLikeInput()
        ) : condition === 'NULLABLE' ? (
          getNullableInput()
        ) : (
          (dataType === 'varchar' || dataType === 'text') ? (
            getTextInput()
          ) : dataType === 'bool' ? (
            getBoolInput()
          ) : (dataType === 'timestamp' || dataType === 'timestamp with time zone') ? (
            getTimestampInput()
          ) : dataType === 'date' ? (
            getDateInput()
          ) : dataType === 'time' ? (
            getTimeInput()
          ) : (
            getNumericInput()
          )
        )
      }
    </FormControl>
  )
}

StageFormFilter.propTypes = {
  open: PropTypes.bool,
  values: PropTypes.array,
  table: PropTypes.object,
  columns: PropTypes.array,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}
