import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { pt } from 'date-fns/locale';

import 'react-toastify/dist/ReactToastify.css'

import ScrollToTop from 'components/ScrollToTop'

import { AppThemeProvider, UserProvider, DialogProvider } from 'contexts'
import Router from 'routes'
import ThemeProvider from 'theme'

import 'utils/i18n'
import 'utils/validations'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <UserProvider>
        <AppThemeProvider>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
              <DialogProvider>
                <ScrollToTop />
                <Router />
                <ToastContainer
                  position="top-right"
                  theme="colored"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  rtl={false}
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </DialogProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </AppThemeProvider>
      </UserProvider>
    </BrowserRouter>
  </HelmetProvider>
)
