import PropTypes from 'prop-types'
import { useEffect, useState, useCallback } from 'react'
import {
  Box,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  ListItemText,
  Paper,
  Grid,
  IconButton,
  Button,
  Badge,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useDialog } from 'components/Dialog'
import FormDrawer from 'components/FormDrawer'
import DataGrid from 'components/DataGrid'
import Iconify from 'components/Iconify'
import FormFilter from 'components/FormFilter'

import { createStage, updateStage, getStageTableColumns } from 'services/requests/stage'
import { Toast, enums } from 'utils'

import StagePreview from './_StagePreview'

const initialValues = {
  stageType: '',
  storageTable: null,
  stageTable: '',
  columns: [],
  rules: [],
  filters: []
}

const fatFieldTypes = [
  'keyfat',
  'dtfat',
  'key',
  'item',
  'unit',
  'amount',
  'value',
  'plain',
]

export default function StageForm({ open, tables, data, onClose }) {
  const [columns, setColumns] = useState([])
  const [openFilter, setOpenFilter] = useState(false)
  const [showPreview, setShowPreview] = useState({})
  const [validationErrors, setValidationErrors] = useState({})

  const dialog = useDialog()

  const validationSchema = yup.object({
    stageType: yup.string()
      .required('Obrigatório'),
    storageTable: yup.object()
      .required('Obrigatório'),
    stageTable: yup.string()
      .required('Obrigatório')
      .max(255, 'O nome da tabela deve conter no máximo 255 caracteres')
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9._]*$/i,
        'O nome da tabela deve conter apenas letras, números e _ (underline)'
      ),
    rules: yup.array()
      .min(1, 'Obrigatório')
      .required('Obrigatório')
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ stageType, storageTable, stageTable, rules, filters }, { setSubmitting }) => {
      if (verifyObjectUndefined(validationErrors)) {
        setSubmitting(false)
        return
      }

      if (stageType === 'fat') {
        const toCheckFieldTypes = fatFieldTypes.filter(item => item !== 'plain')

        const selectedTypes = rules.map(obj => obj.type)
        const pendingTypes = toCheckFieldTypes.filter(categoria => !selectedTypes.includes(categoria))

        const counterTypes = {}
        rules.forEach(obj => {
          if (counterTypes[obj.type]) {
            counterTypes[obj.type] += 1
          } else {
            counterTypes[obj.type] = 1
          }
        })
        const duplicatedTypes = Object.keys(counterTypes)
          .filter(categoria => counterTypes[categoria] > 1 && categoria !== 'plain')

        if (pendingTypes?.length || duplicatedTypes?.length) {
          dialog({
            title: t('error2'),
            hideCancel: true,
            confirmationText: 'ok',
            content: (
              <Box>
                {
                  pendingTypes?.length ? (
                    <Typography variant="body1" my={1}>
                      <Trans i18nKey="data.stage.form.error_pending_types" />
                      &nbsp;
                      <b>{pendingTypes.join(', ')}</b>.
                    </Typography>
                  ) : null
                }
                {
                  duplicatedTypes?.length ? (
                    <Typography variant="body1" my={1}>
                      <Trans i18nKey="data.stage.form.error_duplicated_types" />
                      &nbsp;
                      <b>{duplicatedTypes.join(', ')}</b>.
                    </Typography>
                  ) : null
                }
              </Box>
            ),
          })
            .then(() => { })
            .catch(() => { })

          setSubmitting(false)
          return
        }
      }

      const body = {
        stageType,
        connectionStorageId: storageTable.connectionId,
        storageTable: storageTable.table,
        stageTable,
        rules,
        filters
      }

      const method = data?.id ? updateStage : createStage

      method({ id: data?.id, body })
        .then(() => {
          Toast(t('data.stage.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .then(() => setSubmitting(false))
    },
  })

  function verifyObjectUndefined(obj) {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i += 1) {
      if (obj?.[keys[i]] !== undefined) {
        return true
      }
    }
    return false
  }

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeStageType = e => {
    if (e.target.value !== 'fat') {
      const newRules = formik.values.rules.map(item => ({ ...item, type: 'plain' }))
      formik.setFieldValue('rules', newRules)
    }

    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeStorageTable = (_, value) => {
    formik.setFieldTouched('storageTable')
    formik.setFieldValue('storageTable', value)

    setColumns([])
    setValidationErrors({})

    if (value?.connectionId) {
      getStageTableColumns(value)
        .then(res => setColumns(res))
        .catch(() => { })
    }
  }

  const handleChangeRules = (_, values) => {
    if (values === '___all') {
      values = formik.values.columns.length === columns.length ? [] : columns
    }

    const existItems = formik.values.rules.filter(item => values.map(item => item.attribute).includes(item.columnName))
    const toAdd = values.filter(item => !formik.values.rules.map(item => item.columnName).includes(item.attribute))
    const newRules = [
      ...existItems,
      ...toAdd.map(value => ({
        columnName: value.attribute,
        columnAlias: value.attribute.replaceAll(' ', '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase(),
        columnComment: value?.comment || value.attribute,
        type: 'plain',
        dataType: value.converted_type,
        transformPlain: ''
      }))
    ]

    formik.setFieldValue('columns', values)
    formik.setFieldValue('rules', newRules)
  }

  const handleChangeRuleCell = (cell, row, e) => {
    let error;

    const value = e?.currentTarget?.value || e.target.value

    if (cell.column.id === 'columnAlias') {
      error = value === '' ? t('required')
        : !(/^[a-zA-Z0-9][a-zA-Z0-9._]*$/i.test(value)) ? t('data.stage.form.invalid_rule_name')
          : undefined
    } else if (cell.column.id === 'columnComment') {
      error = value === '' ? t('required')
        : undefined
    } else if (cell.column.id === 'transformPlain') {
      error = value === '' ? undefined
        : value?.indexOf('$var') === -1 ? t('data.stage.form.invalid_rule_transform')
          : undefined
    } else if (cell.column.id === 'type') {
      error = value === '' ? t('required')
        : undefined
    }

    setValidationErrors({
      ...validationErrors,
      [cell.id]: error,
    })

    const rules = [...formik.values.rules]
    rules[row.index][cell.column.id] = value
    formik.setFieldValue('rules', rules)
  }

  const handleRemoveRowRule = columnName => {
    handleChangeRules(null, formik.values.columns.filter(item => item.attribute !== columnName))
  }

  const handleSaveFilter = values => {
    formik.setFieldValue('filters', values)
    setOpenFilter(false)
  }

  const handleCancelFilter = () => {
    setOpenFilter(false)
  }

  const handleShowPreview = async () => {
    const validation = await formik.validateForm()

    if (Object.keys(validation)?.length) {
      dialog({
        title: t('validation'),
        hideCancel: true,
        confirmationText: 'ok',
        content: (
          <Box>
            <Typography variant="body1" my={1}>
              {t('data.stage.form.invalid_to_preview')}
            </Typography>
          </Box>
        ),
      })
        .then(() => { })
        .catch(() => { })

      return
    }

    const { stageType, storageTable, stageTable, rules, filters } = formik.values
    const dataToPreview = {
      stageType,
      connectionStorageId: storageTable.connectionId,
      storageTable: storageTable.table,
      stageTable,
      rules,
      filters
    }
    setShowPreview(dataToPreview)
  }

  const fillEditForm = async () => {
    const res = await getStageTableColumns({ connectionId: data.connectionStorage.id, table: data.storageTable })
    if (res?.length >= 0) {
      setColumns(res)
      const cols = data.rules.map(item => item.columnName)
      const selectedCols = res.filter(item => cols.includes(item.attribute))
      formik.setFieldValue('columns', selectedCols)
    }

    formik.setFieldValue('stageType', data.stageType)
    formik.setFieldValue('storageTable', { connectionId: data.connectionStorage.id, table: data.storageTable })
    formik.setFieldValue('stageTable', data.stageTable.replace(/^[^_]*_/, ''))
    formik.setFieldValue('rules', data.rules)
    formik.setFieldValue('filters', data.filters)
  }

  const handleClose = useCallback((reload) => {
    formik.resetForm(initialValues)
    setColumns([])
    setOpenFilter(false)
    setValidationErrors({})
    onClose(reload)
  }, [formik, onClose])

  useEffect(() => {
    if (data?.id) {
      fillEditForm()
    }
    // eslint-disable-next-line
  }, [data])

  const isAllSelected = columns.length > 0 && formik.values.columns.length === columns.length

  const listConfig = {
    fields: [
      {
        accessorFn: (row) => (
          <IconButton onClick={() => handleRemoveRowRule(row.columnName)}>
            <Iconify icon="mdi:remove-bold" />
          </IconButton>
        ),
        header: '‎',
        size: 65,
        maxSize: 65,
        grow: false,
        enableEditing: false
      },
      {
        accessorKey: 'columnName',
        header: t('data.stage.form.rule_field'),
        size: 150,
        enableEditing: false
      },
      {
        accessorKey: 'dataType',
        header: t('data.stage.form.data_type'),
        size: 90,
        enableEditing: false
      },
      {
        accessorKey: 'type',
        header: t('data.stage.form.rule_type'),
        size: 120,
        editSelectOptions: fatFieldTypes,
        muiEditTextFieldProps: ({ cell, row }) => ({
          select: true,
          value: row.original.type || '',
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: e => handleChangeRuleCell(cell, row, e)
        })
      },
      {
        accessorKey: 'columnAlias',
        header: t('data.stage.form.rule_name'),
        size: 150,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          value: row.original.columnAlias,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: e => handleChangeRuleCell(cell, row, e)
        })
      },
      {
        accessorKey: 'columnComment',
        header: t('data.stage.form.rule_comment'),
        size: 150,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          value: row.original.columnComment,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: e => handleChangeRuleCell(cell, row, e)
        })
      },
      {
        accessorKey: 'transformPlain',
        header: t('data.stage.form.rule_transform'),
        size: 350,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          value: row.original.transformPlain,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onChange: e => handleChangeRuleCell(cell, row, e)
        })
      }
    ],
    enableSorting: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableRowOrdering: true,
    enableEditing: true,
    editDisplayMode: 'table',
    muiTableContainerProps: { sx: { maxHeight: '60vh' } },
    autoResetPageIndex: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState()
        if (hoveredRow && draggingRow) {
          const { rules } = formik.values
          rules.splice(
            hoveredRow.index,
            0,
            rules.splice(draggingRow.index, 1)[0],
          )
          formik.setFieldValue('rules', [...rules])
        }
      },
    }),
    state: { columnVisibility: { type: formik.values.stageType === 'fat' } }
  }

  return (
    <FormDrawer
      title={t(`data.stage.${data?.id ? 'update' : 'new'}`)}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: () => formik.handleSubmit(),
          loading: false,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="stageType">{t('data.stage.form.stg_type')}</InputLabel>
              <Select
                labelId="stageType"
                name="stageType"
                label={t('data.stage.form.stg_type')}
                value={formik.values.stageType}
                onChange={handleChangeStageType}
                error={formik.touched.stageType && Boolean(formik.errors.stageType)}
              >
                {
                  enums.stageType.map((item, key) => (
                    <MenuItem value={item.key} key={key}>{item.label}</MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.stageType && Boolean(formik.errors.stageType) ? (
                  <FormHelperText error>{formik.touched.stageType && formik.errors.stageType}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                disablePortal
                name="storageTable"
                value={formik.values.storageTable}
                onChange={handleChangeStorageTable}
                disabled={!tables?.length}
                options={tables}
                isOptionEqualToValue={(option, value) => (option?.table === value?.table)}
                getOptionLabel={option => option.table || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('data.stage.form.storage_table')}
                    error={formik.touched.storageTable && Boolean(formik.errors.storageTable)}
                  />
                )}
              />
              {
                formik.touched.storageTable && Boolean(formik.errors.storageTable) ? (
                  <FormHelperText error>{formik.touched.storageTable && formik.errors.storageTable}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={5}>
            <TextField
              name="stageTable"
              label={t('data.stage.form.stage_table')}
              value={formik.values.stageTable}
              onChange={handleChange}
              error={formik.touched.stageTable && Boolean(formik.errors.stageTable)}
              helperText={formik.touched.stageTable && formik.errors.stageTable}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={8}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                multiple
                disablePortal
                limitTags={5}
                name="columns"
                value={formik.values.columns}
                onChange={handleChangeRules}
                disabled={!columns?.length}
                options={columns}
                getOptionLabel={option => option.attribute || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('data.stage.form.columns')}
                    error={formik.touched.rules && Boolean(formik.errors.rules)}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem value={option.attribute} {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option.attribute} />
                  </MenuItem>
                )}
                PaperComponent={paperProps => {
                  const { children, ...restPaperProps } = paperProps
                  return (
                    <Paper {...restPaperProps}>
                      <Box onMouseDown={(e) => e.preventDefault()}>
                        <MenuItem onClick={e => handleChangeRules(e, '___all')}>
                          <Checkbox id="select-all-checkbox"
                            checked={isAllSelected}
                            indeterminate={formik.values.columns.length > 0 && formik.values.columns.length < columns.length}
                          />
                          <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary={t('select_all')} />
                        </MenuItem>
                      </Box>
                      {children}
                    </Paper>
                  )
                }}
              />
              {
                (formik.touched.rules && Boolean(formik.errors.rules)) ? (
                  <FormHelperText error>{formik.errors.rules}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Badge
              color="primary"
              badgeContent={formik.values.filters?.length || 0}
              max={9}
              sx={{ mt: 0.5, mr: '3%', width: '47%' }}
            >
              <Button
                size="large"
                type="button"
                variant="contained"
                color="inherit"
                sx={{ width: '100%' }}
                endIcon={<Iconify icon="mdi:filter-outline" />}
                onClick={() => setOpenFilter(true)}
              >
                {t('data.stage.form.filters')}
              </Button>
            </Badge>
            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              color="inherit"
              sx={{ mt: 0.5, ml: '3%', width: '47%' }}
              endIcon={<Iconify icon="mdi:eye" />}
              onClick={() => handleShowPreview()}
            >
              {t('data.stage.form.preview')}
            </LoadingButton>
          </Grid>

          {
            formik.values.rules?.length > 0 ? (
              <Grid item xs={12} className="datagrid-stage-form">
                <DataGrid
                  data={formik.values.rules || []}
                  config={listConfig}
                />
              </Grid>
            ) : null
          }
        </Grid>
      </Box>

      <FormFilter
        open={openFilter}
        table={formik.values.storageTable}
        columns={columns}
        values={formik.values.filters}
        onConfirm={values => handleSaveFilter(values)}
        onCancel={() => handleCancelFilter()}
      />

      <StagePreview
        values={showPreview}
        onClose={() => setShowPreview({})}
      />
    </FormDrawer>
  )
}

StageForm.propTypes = {
  open: PropTypes.bool,
  tables: PropTypes.array,
  data: PropTypes.object,
  onClose: PropTypes.func
}
