import i18n from 'i18next'

import { EventBus } from 'utils'

export function API({
  method = 'get',
  endpoint = '',
  url,
  queryParams,
  body,
  formData,
  headers,
  timeout = 10000,
  responseType = 'json',
  ...props
}) {
  const controller = new AbortController()

  const destination = (url || process.env.REACT_APP_API_URL + endpoint) + (queryParams ? `?${queryParams}` : '')

  const defaultHeader = formData ? {} : {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
  }

  const request = {
    headers: {
      ...defaultHeader,
      ...headers
    },
    responseType,
    signal: controller.signal,
    credentials: 'include',
    cache: 'default',
    method,
    body: body ? JSON.stringify(body) : formData || null,
    ...props
  }

  if (process.env.NODE_ENV === 'development') {
    console.log(method, destination, request.body)
  }

  const timer = setTimeout(() => controller.abort(), timeout)

  return fetch(destination, request)
    .then((rawResponse) => {
      clearTimeout(timer)
      if (rawResponse.ok) {
        return rawResponse
      }
      throw rawResponse
    })
    .catch((rawResponse) => {
      if (!rawResponse[responseType]) {
        throw new Error(i18n.t('UNKNOWN_ERROR'))
      }

      return rawResponse[responseType]()
        .then((errBody) => {
          if (typeof errBody === 'string') {
            return errBody
          }

          if (typeof errBody?.message === 'string') {
            return errBody.message
          }

          if (Array.isArray(errBody?.message)) {
            return errBody.message.map(item => item || i18n.t('UNKNOWN_ERROR'))
          }

          if (typeof errBody?.error === 'string') {
            return errBody.error
          }

          if (errBody?.errors?.length) {
            return errBody.errors.map(item => item?.msg || i18n.t('UNKNOWN_ERROR'))
          }

          return new Error(i18n.t('UNKNOWN_ERROR'))
        })
        .catch((err) => {
          switch (true) {
            case !!rawResponse.message:
              return new Error(rawResponse.message)
            case !!rawResponse.statusText:
              return new Error(rawResponse.statusText)
            case !!rawResponse.status:
              return new Error(rawResponse.status)
            case !!rawResponse:
              return new Error(rawResponse)
            case err instanceof Error:
              return err
            default:
              return err
          }
        })
        .then((parsedError) => {
          if (process.env.NODE_ENV === 'development') {
            console.log('response parsedError: ', parsedError)
          }

          if (
            parsedError.message === 'Unauthorized' || parsedError.message === '401' ||
            parsedError === 'Unauthorized' || parsedError === '401'
          ) {
            EventBus.emit('logout')
          }

          throw parsedError
        })
    })
    .then(rawResponse => rawResponse[responseType]().catch(() => true))
    .then(parsedResponse => {
      if (process.env.NODE_ENV === 'development') {
        console.log('response: ', parsedResponse)
      }
      return parsedResponse
    })
}
