import { toast } from 'react-toastify'
import i18n from 'i18next'

const renderToast = (msg, type) => {
  if (type === 'default') {
    toast(msg)
  } else {
    toast[type](msg)
  }
}

export const Toast = (message, type = 'default') => {
  if (typeof message === 'string') {
    renderToast(message, type)
  } else if (typeof message.message === 'string') {
    renderToast(message.message, type)
  } else if(Array.isArray(message)) {
    message.forEach(msg => {
      renderToast(msg, type)
    })
  } else {
    renderToast(i18n.t('UNKNOWN_ERROR'), type)
  }
}
