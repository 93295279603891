import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AuthView from 'sections/auth/View'
import InviteForm from 'sections/auth/Form/Invite'

export default function Invite() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.invite.page_title')} | {t('project_name')}</title>
      </Helmet>

      <AuthView
        t={t}
        pageType="invite"
        Form={InviteForm}
      />
    </>
  )
}
