import {useDraggable, useDroppable} from "@dnd-kit/core";
import { nanoid } from "nanoid";
import React, { useRef } from "react";
import { Typography, List, ListItem, Box, Tooltip, IconButton } from "@mui/material";
import { Info as IconInfo } from "@mui/icons-material";

import { ItemDnd } from "./SortableItem";


export function SidebarField(props) {
  const { field, overlay } = props;
  const { title } = field;
  let className = "sidebar-field";
  if (overlay) {
    className += " overlay";
  }
  // return <div className={className}>{title}aaaa</div>;
  return (
    <ItemDnd key={`dnd-${props.keytmp}`} id={props.keytmp} itemProps={props}/>
  )
}

function DraggableSidebarField(props) {
  const { field, ...rest } = props;

  const id = useRef(nanoid());

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id.current,
    data: {
      field,
      fromSidebar: true
    }
  });
  return (
    <div
      key={`div-${id.current}`}
      ref={setNodeRef}
      className="sidebar-field"
      {...listeners}
      {...attributes}
      {...rest}
    >
      <SidebarField keytmp={`sb-${id.current}`} field={field} />
    </div>
  );
}

export default function ContainerFields(props) {
  const { fieldsRegKey, items, name, description, height, id } = props;
  const {isOver, setNodeRef} = useDroppable({
    id,
    type: 'tableColumns',
    data: items
  });
  return (
    <div key={id}>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0',
          bgcolor: theme => theme.palette.grey[600],
          color: theme => theme.palette.common.white,
        }}
      >
        <Typography variant="h6">
          {name}
        </Typography>
        {
          description ? (
            <Tooltip title={description}>
              <IconButton size="small">
                <IconInfo
                  fontSize="small"
                  sx={{
                    fill: theme => theme.palette.common.white
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null
        }
        </Box>
        <List
          key={fieldsRegKey}
          sx={{
            px: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            height: height || '300px',
            borderRadius: '0 0 8px 8px',
            borderBottom: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            borderLeft: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            borderRight: theme => `3px solid ${isOver ? theme.palette.grey[600] : theme.palette.grey[300]}`,
            bgcolor: theme => theme.palette.grey[200],
          }}>
          {items.map((f) => (
            <DraggableSidebarField key={f.attribute} field={f} />
          ))}
        </List>
    </div>
  );
}
