import PropTypes from 'prop-types'
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import {
  Card,
  Divider,
  Typography,
  MenuItem,
  ListItemIcon,
  IconButton
} from '@mui/material'
import {
  Search as SearchIcon,
  SearchOff as SearchOffIcon
} from '@mui/icons-material'
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton
} from 'material-react-table'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

const DataGrid = forwardRef(({ config = {}, data, getData, getDataParams = {} }, ref) => {
  const {
    title,
    fields = [],
    actions,
    enableColumnFilters = true,
    initialState = {},
    onRowClick,
    ...restConfigs
  } = config

  const [dataTable, setDataTable] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isRefetching, setIsRefetching] = useState(false)
  const [rowCount, setRowCount] = useState(0)

  const fetchData = async () => {
    if (!getData) return

    if (!dataTable.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }

    try {
      const response = await getData({ ...getDataParams })
      setDataTable(response)
      setRowCount(response.length)
      setIsError(false)
    } catch (error) {
      setIsError(true)
    }

    setIsLoading(false)
    setIsRefetching(false)
  }

  const refreshData = () => {
    fetchData()
  }

  const getColumnFields = fields => (
    fields.map(item => ({
      grow: true,
      filterFn: 'fuzzy',
      ...item
    }))
  )

  const getActions = ({ closeMenu, row }) => {
    const filterdActions = actions?.filter(item => (item?.condition === undefined || item?.condition(row.original)))

    if (!filterdActions?.length) {
      return (
        <MenuItem sx={{ m: 0 }} disabled>
          {t('no_actions')}
        </MenuItem>
      )
    }

    return filterdActions.map((item, key) => (
      <MenuItem
        key={key}
        onClick={e => {
          item.onClick(row.original, e)
          closeMenu()
        }}
        sx={{ m: 0 }}
      >
        {
          item?.icon ? (
            <ListItemIcon>
              <Iconify icon={item.icon} />
            </ListItemIcon>
          ) : null
        }
        {
          item?.label
        }
      </MenuItem>
    ))
  }

  const table = useMaterialReactTable({
    columns: getColumnFields(fields),
    data: dataTable,
    rowCount,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnResizing: true,
    enableFullScreenToggle: false,
    enableRowActions: actions?.length > 0,
    renderRowActionMenuItems: item => getActions(item),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {
          enableColumnFilters ? (
            <IconButton onClick={() => setShowFilters(!showFilters)}>
              {
                showFilters ? <SearchOffIcon /> : <SearchIcon />
              }
            </IconButton>
          ) : null
        }
        <MRTToggleDensePaddingButton table={table} />
        <MRTShowHideColumnsButton table={table} />
      </>
    ),
    muiTableBodyRowProps: ({ row }) => onRowClick ? ({
      onDoubleClick: () => {
        onRowClick(row.original)
      }
    }) : undefined,
    initialState: {
      ...initialState,
      columnPinning: {
        right: ['mrt-row-actions']
      }
    },
    state: {
      showSkeletons: isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showColumnFilters: showFilters
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: t('datagrid_error'),
      }
      : undefined,
    localization: MRT_Localization_PT_BR,
    ...restConfigs
  })

  useEffect(() => {
    if (data) {
      setDataTable(data)
      setRowCount(data.length)
    }
    if (isLoading) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(ref, () => ({
    refreshData
  }))

  return (
    <Card>
      {
        title ? (
          <>
            <Typography variant="h5" px={3} py={3}>
              {title}
            </Typography>

            <Divider />
          </>
        ) : null
      }

      <div className="datagrid">
        <MaterialReactTable
          table={table}
        />
      </div>
    </Card>
  )
})

DataGrid.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  getData: PropTypes.func,
  getDataParams: PropTypes.object
}

export default DataGrid
