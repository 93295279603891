import PropTypes from 'prop-types'
import { useContext, useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import { UserContext } from 'contexts'

export default function BaseRoute({ isPrivate, isPublic, roles }) {
  const navigate = useNavigate()
  const location = useLocation()

  const { state } = useContext(UserContext)

  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    if (state.initialized) {
      if ((isPrivate && isPublic && !roles) || (!isPrivate && !isPublic && !roles)) {
        setAuthorized(true)
        return
      }

      if (isPrivate) {
        if (!state.isLoggedIn && !state.data) {
          navigate(`/auth?${location?.pathname ? `next=${location.pathname.replace('/', '')}` : ''}`, { replace: true })
          return
        }
      }

      if (isPublic) {
        if (state.isLoggedIn && state.data) {
          navigate('/', { replace: true })
          return
        }
      }

      if (roles?.length) {
        if(!roles.includes(state.data.profile.role)) {
          navigate('/', { replace: true })
          return
        }
      }

      setAuthorized(true)
    }
  }, [isPrivate, isPublic, roles, state, location, navigate])

  return (
    state.initialized
      ? authorized && <Outlet />
      : <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"><CircularProgress size={100} /></Box>
  )
}

BaseRoute.propTypes = {
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  roles: PropTypes.array
}
