import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import AuthView from 'sections/auth/View'
import LoginForm from 'sections/auth/Form/Login'

export default function Login() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.login.page_title')} | {t('project_name')}</title>
      </Helmet>

      <AuthView
        t={t}
        pageType="login"
        Form={LoginForm}
      />
    </>
  )
}
