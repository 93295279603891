import { styled } from '@mui/material/styles'

export const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

export const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  padding: theme.spacing(3, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.grey[200]
}))

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  width: '100%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 3),
  borderRadius: theme.spacing(2),
  background: theme.palette.background.default
}))
