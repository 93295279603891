import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  // CardActions,
  // Button,
  // Divider,
  Typography
} from '@mui/material'
// import { t } from 'i18next'

export default function AccountView({ user }) {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            my: { xs: 0, md: 5 }
          }}
        >
          <Avatar
            src={null}
            alt={user.name}
            sx={{
              height: 80,
              mb: 2,
              width: 80
            }}
          >
            {user.name.slice(0, 1)}
          </Avatar>

          <Typography
            gutterBottom
            variant="h5"
            textAlign="center"
          >
            {user.name}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body1"
            textAlign="center"
          >
            {user.email}
          </Typography>
        </Box>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button
          fullWidth
          variant="text"
        >
          { t('settings.my_account.upload_avatar') }
        </Button>
      </CardActions> */}
    </Card>
  )
}

AccountView.propTypes = {
  user: PropTypes.object
}
