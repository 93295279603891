import { GlobalStyles as MUIGlobalStyles } from '@mui/material'

export default function GlobalStyles() {
  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
          pointerEvents: 'none',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        button: {
          textTransform: 'none !important'
        },
        '#mui-rte-container [class*="MUIRichTextEditor-placeHolder"]': {
          position: 'relative !important'
        },
        '.vision-reorder': {
          display: 'grid',
          gridGap: 24,
          gridTemplateColumns: 'repeat(12, 1fr)'
        },
        '.vision-reorder-card': {
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#139BC3',
          color: '#FFFFFF',
          borderRadius: 16,
          padding: 16,
          userSelect: 'none',
          touchAction: 'none',
          cursor: 'grab',
        },
        '.vision-reorder-card.selected': {
          backgroundColor: '#DDDDDD',
          color: '#DDDDDD',
        },
        '.vision-reorder-card.overlay': {
          cursor: 'grabbing'
        },
        '.vision-reorder-card svg': {
          marginRight: 8
        },
        '.link': {
          cursor: 'pointer'
        },
        '.rmdp-wrapper.rmdp-shadow': {
          width: '100%',
          boxShadow: 'none !important'
        },
        '.rmdp-shadow': {
          boxShadow: 'none !important'
        },
        '.rmdp-calendar': {
          width: '100%'
        },
        '.rmdp-header': {
          display: 'none'
        },
        '.rmdp-week:first-of-type': {
          display: 'none'
        },
        '.rmdp-day-picker > div': {
          width: '100%'
        },
        '.rmdp-day-picker': {
          background: '#ededed',
          borderRadius: '16px',
          padding: '10px'
        },
        '.purple .rmdp-day:not(.rmdp-day-hidden) span': {
          backgroundColor: 'transparent !important',
          color: '#000 !important'
        },
        '.purple .rmdp-day:not(.rmdp-day-hidden) span:hover': {
          backgroundColor: 'transparent !important',
          color: '#000 !important'
        },
        '.purple .rmdp-day.rmdp-today span': {
          backgroundColor: 'transparent !important',
          color: '#000 !important'
        },
        '.purple .rmdp-day.rmdp-selected:not(.rmdp-day-hidden) span:hover': {
          backgroundColor: '#139BC3 !important',
          color: '#FFF !important'
        },
        '.purple .rmdp-day.rmdp-selected:not(.rmdp-day-hidden) span:active': {
          backgroundColor: '#139BC3 !important',
          color: '#FFF !important'
        },
        '.purple .rmdp-day.rmdp-selected:not(.rmdp-day-hidden) span': {
          backgroundColor: '#139BC3 !important',
          color: '#FFF !important',
          border: '0'
        },

        // datagrid component
        '.datagrid': {
          '.MuiPaper-root.MuiPaper-elevation': {
            backgroundColor: '#FFF !important'
          },
          '.MuiBox-root': {
            backgroundColor: '#FFF !important'
          },
          'svg.MuiBox-root': {
            backgroundColor: 'transparent !important'
          },
          'table tr': {
            backgroundColor: '#FFF !important'
          }
        },
        '.italic': {
          fontStyle: 'italic'
        },
        '.underline': {
          textDecoration: 'underline'
        },

        // stage form
        '.datagrid-stage-form': {
          '.datagrid tr th:nth-of-type(1)': {
            pointerEvents: 'none',
            opacity: 0
          },
          '.datagrid tr td:nth-of-type(2)': {
            paddingLeft: '0 !important'
          }
        },

        // panels
        '.datagrid-panel': {
          '.MuiPaper-root.MuiPaper-elevation': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important'
          },
          'div:first-of-type': {
            minHeight: '0 !important'
          },
          '.MuiBox-root': {
            backgroundColor: 'transparent !important'
          },
          'svg.MuiBox-root': {
            backgroundColor: 'transparent !important'
          },
          'table tr': {
            backgroundColor: 'transparent !important'
          }
        },
        '.widget-panel': {
          '.MuiCollapse-root.MuiCollapse-vertical:not(.MuiCollapse-entered)': {
            position: 'relative'
          },
          '.MuiCollapse-root.MuiCollapse-vertical:not(.MuiCollapse-entered)::before': {
            content: '""',
            zIndex: 99999,
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '30px',
            background: 'linear-gradient(to top, #FFFFFF 0%, transparent 100%)'
          }
        }
      }}
    />
  )
}
