import { Mask } from 'utils/_mask/Mask'
import { MoneyMask } from 'utils/_mask/Money'

/*
  the mask pattern:
    9 - accept digit.
    A - accept alpha.
    S - accept alphanumeric.
    * - accept all, EXCEPT white space.
*/

export const setMask = (value = '', mask) => {
  const masker = new Mask()
  return masker.getValue(value, { mask })
}

export const removeMask = value =>
  // eslint-disable-next-line no-restricted-globals
  isNaN(value) ? value.replace(/[^\d]+/g, '') : value;

export const setMoneyMask = (value = '', precision = 2) => {
  const masker = new MoneyMask()
  return masker.getValue(Math.round(value), { precision })
}

export const removeMoneyMask = value =>
  // eslint-disable-next-line no-restricted-globals
  isNaN(value) ? Number(value.replace(/[^\d]+/g, '')).toFixed(2) / 100 : value;
