import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormatCustom = forwardRef(({ name, onChange, ...rest }, ref) => (
  <NumericFormat
    {...rest}
    name={name}
    getInputRef={ref}
    onChange={e => onChange(e)}
    allowNegative
  />
  // thousandSeparator="."
  // decimalSeparator=","
))

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom
