import PropTypes from 'prop-types'
import { useMemo, useContext } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles'

import { AppThemeContext } from 'contexts'

import paletteLight from './palette/light'
import paletteDark from './palette/dark'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'
import componentsOverride from './overrides'

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default function ThemeProvider({ children }) {
  const { state: { data: apptheme } } = useContext(AppThemeContext)

  const themeOptions = useMemo(
    () => ({
      palette: apptheme === 'dark' ? paletteDark : paletteLight,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [apptheme]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
