import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import NotFoundView from 'sections/404'

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('404.page_title')} | {t('project_name')}</title>
      </Helmet>

      <NotFoundView
        t={t}
      />
    </>
  )
}
