import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getReports } from 'services/requests/report'
import { getStageTables } from 'services/requests/stage'

import ReportForm from './Form'

export default function ReportsView({ newItem, cancelNew }) {
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)
  const [tables, setTables] = useState([])

  const listConfig = {
    fields: [
      {
        accessorKey: 'name',
        header: t('visualization.reports.fields.name')
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('visualization.reports.update'),
        onClick: row => {setSelectedData(row)}
      }
    ],
    onRowClick: row => setSelectedData(row)
  }

  useEffect(() => {
    getStageTables({ query: { types: ['fat', 'dim'] } })
      .then(res => setTables(res))
      .catch(() => {})
  }, [])

  return (
    <Box mt={5}>
      <DataGrid
        getData={getReports}
        config={listConfig}
        ref={dataGridRef}
      />

      <ReportForm
        open={(!!selectedData?.id || newItem)}
        dataForm={selectedData}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
        tables={tables}
      />
    </Box>
  )
}

ReportsView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}
