import { API } from 'services'

export function getPanels(params) {
  return API({
    endpoint: `/panel`,
    ...params
  })
}

export function getPanel({ id, params }) {
  return API({
    endpoint: `/panel/${id}`,
    ...params
  })
}

export function createPanel(params) {
  return API({
    method: 'POST',
    endpoint: `/panel`,
    ...params
  })
}

export function updatePanel({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/panel/${id}`,
    ...params
  })
}


export function deletePanel({ id, ...params }) {
  return API({
    method: 'DELETE',
    endpoint: `/panel/${id}`,
    ...params
  })
}

export function getProfiles(params) {
  return API({
    endpoint: `/panel/profiles`,
    ...params
  })
}

export function getPanelsDashboard(params) {
  return API({
    endpoint: `/panel/dashboard`,
    ...params
  })
}

export function getPanelDashboard({ id, ...params }) {
  return API({
    endpoint: `/panel/dashboard/${id}`,
    ...params
  })
}
