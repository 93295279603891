import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Container, Stack, Typography, Grid } from '@mui/material'
import { t } from 'i18next'

import { UserContext } from 'contexts'

import AccountView from 'sections/settings/MyAccount/View'
import AccountForm from 'sections/settings/MyAccount/Form'

export default function Account() {
  const { state: { data: user }, exec } = useContext(UserContext)

  useEffect(() => {
    exec.getCurrentUser()
  // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('settings.my_account.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <div>
              <Typography variant="h4">
                {t('settings.my_account.title')}
              </Typography>
              <Typography variant="body2">
                {t('settings.my_account.description')}
              </Typography>
            </div>
            <div>
              <Grid
                container
                mt={3}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  p={1}
                >
                  <AccountView user={user} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={8}
                  p={1}
                >
                  <AccountForm user={user} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
