import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getUsers } from 'services/requests/user'
import { getProfiles } from 'services/requests/profile'
import { enums } from 'utils'

export default function CompanyUsersView() {
  const { id } = useParams()

  const [profiles, setProfiles] = useState([])

  const listConfig = {
    title: t('settings.company_detail.users.title'),
    fields: [
      {
        accessorKey: 'name',
        header: t('settings.users.fields.name')
      },
      {
        accessorKey: 'email',
        header: t('settings.users.fields.email')
      },
      {
        accessorKey: 'userCompany.profiles.name',
        accessorFn: row => row.userCompany[0].profiles.map(item => item.name).join(', '),
        header: t('settings.users.fields.profiles'),
        filterVariant: 'select',
        filterSelectOptions: profiles.map(item => item.name)
      },
      {
        accessorKey: 'isActive',
        accessorFn: row => t(`${row.isActive ? 'active' : 'inactive'}`),
        header: t('settings.users.fields.is_active'),
        filterVariant: 'select',
        filterSelectOptions: enums.active
      }
    ]
  }

  const getDataProfiles = () => {
    getProfiles({ companyId: id })
      .then(res => setProfiles(res))
      .catch(() => {})
  }

  useEffect(() => {
    getDataProfiles()
    // eslint-disable-next-line
  }, [])

  return (
    <DataGrid
      getData={getUsers}
      getDataParams={{ companyId: id }}
      config={listConfig}
    />
  )
}
