import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  Typography,
  Stack,
  Chip,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

import { updateCompany } from 'services/requests/company'
import { Toast, setMask } from 'utils'

export default function CompanyView({ data, refresh }) {
  const [loading, setLoading] = useState(false)

  const handleChangeCompany = params => {
    setLoading(true)

    updateCompany({ id: data.id, body: { ...params } })
      .then(() => {
        Toast(t('settings.company_detail.updated_success'), 'success')
        refresh()
      })
      .catch(err => Toast(err, 'error'))
      .then(() => setLoading(false))
  }

  return (
    <Card>
      <CardMedia
        sx={{ height: 220 }}
        image={`https://placehold.co/500x500?text=${data.name.slice(0, 1)}`}
        title={data.name}
      />
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            textAlign="center"
          >
            {data.fantasyName}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body1"
          >
            {setMask(data.cnpj, '99.999.999/9999-99')}
          </Typography>

          <Stack direction="row" spacing={1} mt={2}>
            <Chip
              label={t(`company.${data.isApproved === null ? 'pending' : data.isApproved ? 'approved' : 'reproved'}`)}
              color={data.isApproved === null ? 'warning' : data.isApproved ? 'success' : 'error'}
              variant="outlined"
              size="small"
            />
            {
              data.isApproved ? (
                <Chip
                  label={t(`company.${data.isActive ? 'active' : 'inactive'}`)}
                  color={data.isActive ? 'success' : 'error'}
                  variant="outlined"
                  size="small"
                />
              ) : null
            }
          </Stack>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        {
          data.isApproved === null ? (
            <Box sx={{ width: '100%' }}>
              <Button
                fullWidth
                size="large"
                variant="text"
                color="error"
                startIcon={<Iconify icon="mdi:times" />}
                onClick={() => handleChangeCompany({ isApproved: false })}
              >
                {t('settings.company_detail.reprove')}
              </Button>
              <Divider />
              <Button
                fullWidth
                size="large"
                variant="text"
                color="success"
                startIcon={<Iconify icon="mdi:check"/>}
                onClick={() => handleChangeCompany({ isApproved: true })}
              >
                {t('settings.company_detail.approve')}
              </Button>
            </Box>
          ) : !data.isApproved ? (
            <Button
              fullWidth
              size="large"
              variant="text"
              color="success"
              onClick={() => handleChangeCompany({ isApproved: true })}
            >
              {t('settings.company_detail.approve')}
            </Button>
          ) : (
            data.isActive ? (
              <Button
                fullWidth
                size="large"
                variant="text"
                color="error"
                onClick={() => handleChangeCompany({ isActive: false })}
              >
                {t('settings.company_detail.deactive')}
              </Button>
            ) : (
              <Button
                fullWidth
                size="large"
                variant="text"
                color="success"
                onClick={() => handleChangeCompany({ isActive: true })}
              >
                {t('settings.company_detail.active')}
              </Button>
            )
          )
        }
      </CardActions>

      <Backdrop
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </Card>
  )
}

CompanyView.propTypes = {
  data: PropTypes.object,
  refresh: PropTypes.func
}
