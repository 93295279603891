import { API } from 'services'

export function login(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/login',
    ...params
  })
}

export function logout(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/logout',
    ...params
  })
}

export function forgotPassword(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/forgot-password',
    ...params
  })
}

export function resetPassword(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/reset-password',
    ...params
  })
}

export function register(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/register',
    ...params
  })
}
