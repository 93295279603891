import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, Container, Box } from '@mui/material'

import { StyledContent } from './styled'

export default function NotFoundView({ t }) {
  return (
    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h4" paragraph>
          {t('404.title')}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          {t('404.description')}
        </Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to="/" size="large" variant="contained" component={RouterLink}>
          {t('404.action')}
        </Button>
      </StyledContent>
    </Container>
  )
}

NotFoundView.propTypes = {
  t: PropTypes.func
}
