import { Helmet } from 'react-helmet-async'
import { Box, Container, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import UsersView from 'sections/settings/Users/View'

export default function Users() {
  return (
    <>
      <Helmet>
        <title>{t('settings.users.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <div>
              <Typography variant="h4">
                {t('settings.users.title')}
              </Typography>
              <Typography variant="body2">
                {t('settings.users.description')}
              </Typography>
            </div>
            <div>
              <UsersView />
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
