import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import {
  Box
} from '@mui/material'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getConnections } from 'services/requests/connection'
import { enums } from 'utils'

import ConnectionForm from './Form'

export default function ConnectionsView({ newItem, cancelNew }) {
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)

  const listConfig = {
    fields: [
      {
        accessorFn: row => t(`connection_types.${row.type}`),
        header: t('data.connections.fields.type'),
        filterVariant: 'select',
        filterSelectOptions: enums.connectionTypes
      },
      {
        accessorFn: row => t(`adapters.${row.adapter}`),
        header: t('data.connections.fields.adapter'),
        filterVariant: 'select',
        filterSelectOptions: enums.adapters
      },
      {
        accessorKey: 'config.host',
        accessorFn: row => row.config?.host,
        header: t('data.connections.fields.host')
      },
      {
        accessorKey: 'config.port',
        accessorFn: row => row.config?.port,
        header: t('data.connections.fields.port')
      },
      {
        accessorKey: 'config.user',
        accessorFn: row => row.config?.user,
        header: t('data.connections.fields.user')
      },
      {
        accessorKey: 'config.database',
        accessorFn: row => row.config?.database,
        header: t('data.connections.fields.database')
      },
      {
        accessorKey: 'shortUUID',
        header: t('data.connections.fields.short_uuid')
      }
    ],
    actions: [
      {
        icon: 'mdi:pencil',
        label: t('data.connections.update'),
        onClick: item => setSelectedData(item)
      }
    ],
    onRowClick: row => setSelectedData(row)
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={getConnections}
        config={listConfig}
        ref={dataGridRef}
      />

      <ConnectionForm
        open={(!!selectedData?.id || newItem)}
        data={selectedData}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
      />
    </Box>
  )
}

ConnectionsView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

