import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  ListItemText,
  Chip
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useDialog } from 'components/Dialog'
import Empty from 'components/Empty'
import WidgetPainel from 'components/WidgetPainel'
import Iconify from 'components/Iconify'

import { createPanel, getPanel, getProfiles, updatePanel } from 'services/requests/panel'
import { Toast, enums } from 'utils'

import VisionForm from './_VisionForm'
import SortVision from './_SortVision'

const initialValues = {
  name: '',
  type: '',
  enable: false,
  profiles: [],
  config: []
}

export default function PanelForm() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dialog = useDialog()

  const [selectedVision, setSelectedVision] = useState(null)
  const [newItem, setNewItem] = useState(false)
  const [panel, setPanel] = useState(null)
  const [profiles, setProfiles] = useState([])
  const [showVisionSort, setShowVisionSort] = useState(false)

  const savePanel = (body, setSubmitting) => {
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key])

    const method = id === 'novo' ? createPanel : updatePanel

    method({ id, body })
      .then(res => {
        Toast(t('visualization.panels.form.success'), 'success')
        if (res?.id) {
          navigate(`/visualizacao/paineis/${res.id}`, { replace: true })
        }
      })
      .catch((err) => {
        Toast(err, 'error')
      })
      .finally(() => {
        if (setSubmitting) {
          setSubmitting(false)
        }
      })
  }

  const validationSchema = yup.object({
    name: yup.string()
      .required(t('required')),
    type: yup.string()
      .required(t('required'))
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async ({ name, type, enable, profiles, config }, { setSubmitting }) => {
      const body = {
        name,
        type
      }

      if (id !== 'novo') {
        body.enable = enable
        body.profilesId = profiles.map(item => item.id)
        body.config = config
      }

      savePanel(body, setSubmitting)
    },
  })

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeProfiles = (_, value) => {
    formik.setFieldTouched('profiles')
    formik.setFieldValue('profiles', value)
  }

  const handleEditVision = index => {
    const item = { ...formik.values.config[index] }
    setSelectedVision({
      index,
      item
    })
  }

  const handleDeleteVision = (item, index) => {
    if (!(index > -1) && !item?.title) return

    dialog({
      title: t('visualization.panels.form.delete_vision'),
      description: t('visualization.panels.form.delete_vision_description', { toDelete: item.title })
    })
      .then(() => {
        const config = [...formik.values.config]
        config.splice(index, 1)

        formik.setFieldValue('config', config)
        savePanel({ config })
      })
      .catch(() => { })
  }

  const handleSaveVisionForm = (values, index = -1) => {
    const config = formik.values.config || []
    if (index >= 0 && index !== null) {
      config[index] = { ...values }
    } else {
      config.push({ ...values })
    }

    formik.setFieldValue('config', config)
    savePanel({ config })
  }

  const handleCancelVisionForm = () => {
    setNewItem(false)
    setSelectedVision(null)
  }

  const handleSubmitSort = async config => {
    formik.setFieldValue('config', config)
    savePanel({ config }, false)
    handleSortClose()
  }

  const handleSortClose = () => {
    setShowVisionSort(false)
  }

  const getData = () => {
    getPanel({ id })
      .then(res => {
        setPanel(res)
      })
      .catch((err) => {
        navigate(-1)
        Toast(err, 'error')
      })
  }

  const getDataProfiles = () => {
    getProfiles()
      .then(res => {
        setProfiles(res)
      })
      .catch(err => {
        Toast(err, 'error')
      })
  }

  useEffect(() => {
    if (panel?.id) {
      formik.setFieldValue('name', panel.name)
      formik.setFieldValue('type', panel.type)
      formik.setFieldValue('enable', panel.enable)
      formik.setFieldValue('profiles', panel.profiles)
      formik.setFieldValue('config', panel.config)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel])

  useEffect(() => {
    if (id === 'novo') {
      formik.resetForm(initialValues)
    } else {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    getDataProfiles()
  }, [])

  return (
    <Box px={3}>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                name="name"
                label={t('visualization.panels.form.name')}
                value={formik.values.name}
                onChange={handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7} sm={7} md={id === 'novo' ? 6 : 4} lg={id === 'novo' ? 6 : 5}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel
                  id="type"
                  error={formik.touched.type && Boolean(formik.errors.type)}
                >
                  {t('visualization.panels.form.type')}
                </InputLabel>
                <Select
                  labelId="type"
                  name="type"
                  label={t('visualization.panels.form.type')}
                  value={formik.values.type}
                  onChange={handleChange}
                  disabled={formik.values.connectionType === ''}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                >
                  {
                    enums.panelTypes.map((item, key) => (
                      <MenuItem value={item.key} key={key}>
                        {item.label}
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  formik.touched.type && Boolean(formik.errors.type) ? (
                    <FormHelperText error>{formik.touched.type && formik.errors.type}</FormHelperText>
                  ) : null
                }
              </FormControl>
            </Grid>
            {
              id !== 'novo' ? (
                <>
                  <Grid item xs={5} sm={5} md={2} lg={1} display="flex" justifyContent="center" alignItems="center">
                    <FormControl sx={{ px: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="enable"
                            checked={formik.values.enable}
                            onChange={handleChange}
                          />
                        }
                        label={t('visualization.panels.form.enable')}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl sx={{ width: '100%' }}>
                      <Autocomplete
                        multiple
                        name="profiles"
                        value={formik.values.profiles}
                        onChange={handleChangeProfiles}
                        options={profiles}
                        isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                        getOptionLabel={option => option.name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('visualization.panels.form.profiles')}
                          />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <MenuItem value={option.id} {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        )}
                        renderTags={(tagValue, getTagProps) => (
                          tagValue.map((option, index) => {
                            const { key, ...restProps } = getTagProps({ index });
                            return <Chip {...restProps} key={`${option.name}-${key}`} label={option.name} />
                          })
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : null
            }
            <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={formik.handleSubmit}
                loading={formik.loadingSubmit}
              >
                {t(`visualization.panels.form.${id === 'novo' ? 'create' : 'edit'}`)}
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={3} sx={{ justifyContent: 'flex-end' }}>
        {
          id !== 'novo' ? (
            <>
              {
                formik.values?.config?.length > 0 ? (
                  <Grid item xs={12} sm={12} md={4} lg={3} order={{ xs: 3, sm: 1 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowVisionSort(true)}
                    >
                      <Iconify icon="teenyicons:drag-solid" mr={1} />
                      {t('visualization.panels.form.sort_vision')}
                    </Button>
                  </Grid>
                ) : null
              }
              <Grid item xs={12} sm={12} md={4} lg={3} order={{ xs: 2, sm: 2 }}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => setNewItem(true)}
                  startIcon={<Iconify icon="material-symbols:add" />}
                >
                  {t('visualization.panels.form.add_vision')}
                </Button>
              </Grid>
            </>
          ) : null
        }
      </Grid>

      <Divider sx={{ my: 3 }} />

      {
        formik.values?.config?.length > 0 ? (
          <Grid container spacing={3} mt={3}>
            {
              formik.values.config.map((item, key) => (
                <Grid item xs={12} sm={12} md={12} lg={Number(item.size)} key={key}>
                  <WidgetPainel
                    item={item}
                    configElement={() => handleEditVision(key)}
                    removeElement={() => handleDeleteVision(item, key)}
                  />
                </Grid>
              ))
            }
          </Grid>
        ) : (
          <Empty />
        )
      }

      <VisionForm
        open={(newItem || selectedVision?.index > -1)}
        data={selectedVision?.item}
        onCancel={() => handleCancelVisionForm()}
        onConfirm={data => handleSaveVisionForm(data, selectedVision?.index)}
      />

      <SortVision
        open={showVisionSort}
        items={formik.values?.config}
        onCancel={() => handleSortClose()}
        onConfirm={values => handleSubmitSort(values)}
      />
    </Box>
  )
}

PanelForm.propTypes = {
}

