import { API } from 'services'

export function getCurrentCompany(params) {
  return API({
    endpoint: '/company/me',
    ...params
  })
}

export function updateCurrentCompany(params) {
  return API({
    method: 'PUT',
    endpoint: '/company/me',
    ...params
  })
}

export function getCompanies(params) {
  return API({
    endpoint: '/company',
    ...params
  })
}

export function getCompany({ id, ...params }) {
  return API({
    endpoint: `/company/${id}`,
    ...params
  })
}

export function createCompany(params) {
  return API({
    method: 'POST',
    endpoint: '/company',
    ...params
  })
}

export function updateCompany({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/company/${id}`,
    ...params
  })
}

