import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  ListItem,
  ListItemText,
  Select,
  ListItemAvatar,
  Skeleton,
  Grid,
  Avatar,
  ButtonGroup,
  MenuItem,
  IconButton
} from '@mui/material'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { produce } from 'immer'

import Iconify from 'components/Iconify'
import FormFilter from 'components/FormFilter'
import FormFilterField from 'components/FormFilter/Field'
import SwitchAndOr from 'components/FormFilter/SwitchAndOr'

import { stringToColor } from 'utils'

function getIconType(mapped_type) {
  switch (mapped_type) {
    case 'numeric':
      return <Iconify icon="tabler:decimal" />
    case 'timestamp':
      return <Iconify icon="tabler:calendar-clock" />
    case 'time':
      return <Iconify icon="tabler:clock" />
    case 'date':
      return <Iconify icon="tabler:calendar" />
    case 'integer':
      return <Iconify icon="tabler:numbers" />
    case 'varchar':
      return <Iconify icon="tabler:abc" />
    case 'text':
      return <Iconify icon="tabler:file-text" />
    case 'bool':
      return <Iconify icon="tabler:circuit-switch-closed" />
    default:
      return <Iconify icon="fa-solid:sort-up" />
  }
}

function getIconSort(sort) {
  switch (sort) {
    case 'ASC':
      return <Iconify icon="fa-solid:sort-up" />
    case 'DESC':
      return <Iconify icon="fa-solid:sort-down" />
    default:
      return <Iconify icon="fa-solid:sort" />
  }
}

const baseItemStyle = {
  px: 2,
  py: 1,
  my: 1,
  borderRadius: 2
}

export function ItemDndOnDraging(props) {
  const { field } = props
  return (
    <ItemDnd
      id={field.id}
      itemProps={field}
    />
  )
}

export function ItemSpacer(props) {
  const { id } = props

  return (
    <ListItem
      key={id}
      id={id}
      sx={{
        ...baseItemStyle,
        ...{
          bgcolor: theme => theme.palette.grey[300]
        }
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <Skeleton variant="circular" width={40} height={40} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" sx={{ fontSize: '2rem' }} />}
      />
    </ListItem>
  )
}

export function ItemDnd(props) {
  const { id, itemProps } = props
  const { attribute, mapped_type, table } = itemProps.field

  return (
    <ListItem
      key={id}
      id={id}
      sx={{
        ...baseItemStyle, ...{ bgcolor: stringToColor(table, 0.5) }
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: stringToColor(table) }}>
          {getIconType(mapped_type)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={attribute}
        secondary={table}
      />
    </ListItem>
  )
}

export function ItemDndRow(props) {
  const { id, itemProps, setRefreshData, fieldsReport, setFieldsReport, listeners } = props
  const { attribute, table } = itemProps.field

  const sortAction = () => {
    // eslint-disable-next-line default-case
    switch (props.itemProps.field.sort) {
      case 'ASC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.rows.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.rows[itemIndex],
            field: { ...fieldsReport.rows[itemIndex].field, ...{ sort: 'DESC' } }
          }
          draft.rows[itemIndex] = itemEdit
          setRefreshData(true)
        }))
        break
      case 'DESC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.rows.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.rows[itemIndex],
            field: { ...fieldsReport.rows[itemIndex].field, ...{ sort: '' } }
          }
          draft.rows[itemIndex] = itemEdit
          setRefreshData(true)
        }))
        break
      default:
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.rows.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.rows[itemIndex],
            field: { ...fieldsReport.rows[itemIndex].field, ...{ sort: 'ASC' } }
          }
          draft.rows[itemIndex] = itemEdit
          setRefreshData(true)
        }))
        break
    }
  }

  useEffect(() => {
    (async () => {
      const itemIndex = fieldsReport.rows.findIndex((f) => f.id === id)
      if (fieldsReport.rows[itemIndex].field.sort === undefined) {
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.rows.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.rows[itemIndex],
            field: { ...fieldsReport.rows[itemIndex].field, ...{ sort: '' } }
          };
          draft.rows[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsReport])

  return (
    <ListItem
      key={id}
      disablePadding
      sx={{
        width: '100%',
        ...baseItemStyle, ...{ bgcolor: stringToColor(table, 0.5) }
      }}
      secondaryAction={
        <ButtonGroup variant="text" sx={{ bgcolor: theme => theme.palette.grey[600] }}>
          <IconButton aria-label="ordernar" onClick={() => sortAction()} sx={{ color: theme => theme.palette.common.white }}>
            {getIconSort(props.itemProps.field.sort)}
          </IconButton>
          <IconButton aria-label="mover" sx={{ color: theme => theme.palette.common.white }}>
            <Iconify icon="mdi:drag" {...listeners} />
          </IconButton>
        </ButtonGroup>
      }
      id={id}>
      <ListItemText
        primary={attribute}
        secondary={props.itemProps.field.mapped_type}
      />
    </ListItem>
  );
}

export function ItemDndCol(props) {
  const { id, itemProps, setRefreshData, fieldsReport, setFieldsReport, listeners } = props;
  const { attribute, table } = itemProps.field;

  const sortAction = () => {
    // eslint-disable-next-line default-case
    switch (props.itemProps.field.sort) {
      case '':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.columns.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.columns[itemIndex],
            field: { ...fieldsReport.columns[itemIndex].field, ...{ sort: 'ASC' } }
          };
          draft.columns[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      case 'ASC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.columns.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.columns[itemIndex],
            field: { ...fieldsReport.columns[itemIndex].field, ...{ sort: 'DESC' } }
          };
          draft.columns[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      case 'DESC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.columns.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.columns[itemIndex],
            field: { ...fieldsReport.columns[itemIndex].field, ...{ sort: '' } }
          };
          draft.columns[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      default:
    }
  }

  useEffect(() => {
    (async () => {
      const itemIndex = fieldsReport.columns.findIndex((f) => f.id === id)
      if (fieldsReport.columns[itemIndex].field.sort === undefined) {
        setFieldsReport(produce((draft) => {
          const itemEdit = {
            ...fieldsReport.columns[itemIndex],
            field: { ...fieldsReport.columns[itemIndex].field, ...{ sort: '' } }
          };
          draft.columns[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsReport])
  return (
    <ListItem
      key={id}
      id={id}
      sx={{
        ...baseItemStyle, ...{ bgcolor: stringToColor(table, 0.5) }
      }}
      secondaryAction={
        <ButtonGroup variant="text" sx={{ bgcolor: theme => theme.palette.grey[600] }}>
          <IconButton aria-label="ordernar" onClick={() => sortAction()} sx={{ color: theme => theme.palette.common.white }}>
            {getIconSort(props.itemProps.field.sort)}
          </IconButton>
          <IconButton aria-label="mover" sx={{ color: theme => theme.palette.common.white }}>
            <Iconify icon="mdi:drag" {...listeners} />
          </IconButton>
        </ButtonGroup>
      }
    >
      <ListItemText
        primary={attribute}
        secondary={props.itemProps.field.mapped_type}
      />
    </ListItem>
  );
}

export function ItemDndFunc(props) {
  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState([])
  const [functionValue, setFunctionValue] = useState('count');
  const { id, itemProps, setRefreshData, storageTable, fieldsReport, setFieldsReport, listeners } = props;
  const { attribute, mapped_type, table } = itemProps.field;

  const functionSelect = (event, setRefreshData) => {
    const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
    setFieldsReport(produce((draft) => {
      const itemEdit = {
        ...fieldsReport.functions[itemIndex],
        field: { ...fieldsReport.functions[itemIndex].field, ...{ func: event.target.value } }
      };
      draft.functions[itemIndex] = itemEdit;
      setFunctionValue(event.target.value);
      setRefreshData(true);
    }));
  }
  const handleSaveFilter = values => {
    const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
    setFieldsReport(produce((draft) => {
      const itemEdit = {
        ...fieldsReport.functions[itemIndex],
        field: { ...fieldsReport.functions[itemIndex].field, ...{ filters: values } }
      };
      draft.functions[itemIndex] = itemEdit;
      setFilters(filters);
      setRefreshData(true);
    }));
    setOpenFilter(false)
  }

  const handleCancelFilter = () => {
    setOpenFilter(false)
  }

  const sortAction = () => {
    // eslint-disable-next-line default-case
    switch (props.itemProps.field.sort) {
      case '':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ sort: 'ASC' } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      case 'ASC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ sort: 'DESC' } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      case 'DESC':
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ sort: '' } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
        break;
      default:
    }
  }
  const enableFunctions = (mapped_type) => {
    if (["integer", "numeric"].includes(mapped_type)) {
      return false
    }
    return true
  }

  useEffect(() => {
    (async () => {
      const itemIndex = fieldsReport.functions.findIndex((f) => f.id === id)
      if (fieldsReport.functions[itemIndex].field.sort === undefined) {
        setFieldsReport(produce((draft) => {
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ sort: '' } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
      if (fieldsReport.functions[itemIndex].field.func === undefined) {
        setFieldsReport(produce((draft) => {
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ func: 'count' } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
      if (fieldsReport.functions[itemIndex].field.filters === undefined) {
        setFieldsReport(produce((draft) => {
          const itemEdit = {
            ...fieldsReport.functions[itemIndex],
            field: { ...fieldsReport.functions[itemIndex].field, ...{ filters: [] } }
          };
          draft.functions[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
      if (fieldsReport.functions[itemIndex].field.func) {
        setFunctionValue(fieldsReport.functions[itemIndex].field.func);
      }
      if (fieldsReport.functions[itemIndex].field.filters) {
        setFilters(fieldsReport.functions[itemIndex].field.filters);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsReport])

  return (
    <div>
      <ListItem
        key={id}
        id={id}
        sx={{
          ...baseItemStyle, ...{ bgcolor: stringToColor(table, 0.5) }
        }}
        secondaryAction={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ButtonGroup variant="text" aria-label="Basic button group" sx={{ bgcolor: theme => theme.palette.grey[600], mr: 0.5 }}>
              <Select
                displayEmpty
                size="small"
                id="select-func"
                defaultValue={'count'}
                value={functionValue}
                onChange={event => functionSelect(event, setRefreshData)}
                variant="standard"
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      background: theme => theme.palette.grey[300],
                    },
                  },
                }}
                sx={{
                  color: theme => theme.palette.common.white,
                  pl: 2.5,
                  pt: 0.7,
                  pb: 0.4,
                  "svg": {
                    display: 'none'
                  },
                  "& [aria-expanded=true]": {
                    borderBottomColor: 'none',
                  },
                  "&::after:hover": {
                    borderBottomColor: 'none',
                  },
                  "&::before": {
                    display: 'none'
                  },
                  "&::after": {
                    borderBottom: 'none'
                  }
                }}
              >
                <MenuItem value={'count'}>Contar</MenuItem>
                <MenuItem value={'sum'} disabled={enableFunctions(mapped_type)}>Somar</MenuItem>
                <MenuItem value={'avg'} disabled={enableFunctions(mapped_type)}>Média</MenuItem>
                <MenuItem value={'max'} disabled={enableFunctions(mapped_type)}>Máximo</MenuItem>
                <MenuItem value={'min'} disabled={enableFunctions(mapped_type)}>Mínimo</MenuItem>
              </Select>
            </ButtonGroup>

            <ButtonGroup variant="text" aria-label="Basic button group" sx={{ bgcolor: theme => theme.palette.grey[600] }}>
              <IconButton
                aria-label="ordernar"
                onClick={(event) => sortAction(event, setRefreshData)}
                sx={{ color: theme => theme.palette.common.white, px: 1.3 }}
              >
                {getIconSort(props.itemProps.field.sort)}
              </IconButton>

              <IconButton
                aria-label="ordernar"
                onClick={() => setOpenFilter(true)}
                sx={{ color: theme => theme.palette.common.white, px: 1.3 }}
              >
                <Badge
                  color="info"
                  badgeContent={filters?.length || 0}
                  max={9}
                  sx={{ position: 'absolute', }}
                >
                  <Iconify icon="mdi:filter-outline" />
                </Badge>
              </IconButton>

              <IconButton
                aria-label="mover"
                sx={{ color: theme => theme.palette.common.white, px: 1.3 }}
              >
                <Iconify icon="mdi:drag" {...listeners} />
              </IconButton>
            </ButtonGroup>
          </Box>
        }
      >
        <ListItemText
          primary={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {attribute}
              </Grid>
            </Grid>
          }
          secondary={props.itemProps.field.mapped_type}
        />
      </ListItem>

      <FormFilter
        open={openFilter}
        table={storageTable}
        columns={fieldsReport.tableColumns}
        values={filters}
        onConfirm={values => handleSaveFilter(values)}
        onCancel={() => handleCancelFilter()}
      />
    </div>
  );
}

export function ItemDndFilter(props) {
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState({})

  const {
    id,
    index,
    storageTable,
    itemProps,
    setRefreshData,
    fieldsReport,
    setFieldsReport,
    listeners
  } = props;
  const { attribute, table } = itemProps.field;

  useEffect(() => {
    (async () => {
      const itemIndex = fieldsReport.filters.findIndex((f) => f.id === id)
      if (fieldsReport.filters[itemIndex].field?.filter?.operator === undefined) {
        setFieldsReport(produce((draft) => {
          const itemIndex = fieldsReport.filters.findIndex((f) => f.id === id)
          const itemEdit = {
            ...fieldsReport.filters[itemIndex],
            field: { ...fieldsReport.filters[itemIndex].field, ...{ filter: { operator: 'AND' } } }
          };
          draft.filters[itemIndex] = itemEdit;
          setRefreshData(true);
        }));
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsReport])

  const handleChangeCondition = checked => {
    const itemIndex = fieldsReport.filters.findIndex((f) => f.id === id)
    setFieldsReport(produce((draft) => {
      const itemEdit = {
        ...fieldsReport.filters[itemIndex],
        field: {
          ...fieldsReport.filters[itemIndex].field,
          ...{ filter: { ...fieldsReport.filters[itemIndex].field.filter, operator: checked === true ? 'OR' : 'AND' } }
        }
      };
      draft.filters[itemIndex] = itemEdit;
      setFilter(filter);
      setRefreshData(true);
    }));
  }

  const handleSaveFilter = values => {
    const itemIndex = fieldsReport.filters.findIndex((f) => f.id === id)
    setFieldsReport(produce((draft) => {
      const itemEdit = {
        ...fieldsReport.filters[itemIndex],
        field: {
          ...fieldsReport.filters[itemIndex].field,
          ...{ filter: { ...fieldsReport.filters[itemIndex].field.filter, ...values } }
        }
      };
      draft.filters[itemIndex] = itemEdit;
      setFilter(filter);
      setRefreshData(true);
    }));
  }

  const handleCancelFilter = () => {
    setOpenFilter(false)
  }

  return (
    <div>
      <ListItem
        key={id}
        disablePadding
        sx={{
          width: '100%',
          ...baseItemStyle, ...{ bgcolor: stringToColor(table, 0.5) }
        }}
        secondaryAction={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
              index > 0 ? (
                <SwitchAndOr
                  checked={itemProps.field?.filter?.operator === 'OR'}
                  onChange={e => handleChangeCondition(e.target.checked)}
                />
              ) : null
            }
            <ButtonGroup variant="text" sx={{ bgcolor: theme => theme.palette.grey[600] }}>
              <IconButton
                aria-label="ordernar"
                onClick={() => setOpenFilter(true)}
                sx={{ color: theme => theme.palette.common.white, pl: 2.5, pr: 1.5 }}
              >
                <Badge
                  color="info"
                  badgeContent={itemProps.field?.filter?.condition ? 1 : 0}
                  max={9}
                  sx={{ position: 'absolute', }}
                >
                  <Iconify icon="mdi:filter-outline" />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="mover"
                sx={{ color: theme => theme.palette.common.white, px: 1.3 }}
              >
                <Iconify icon="mdi:drag" {...listeners} />
              </IconButton>
            </ButtonGroup>
          </Box>
        }
        id={id}>
        <ListItemText
          primary={attribute}
          secondary={props.itemProps.field.mapped_type}
        />
      </ListItem>

      <FormFilterField
        open={openFilter}
        storageTable={storageTable}
        field={itemProps.field}
        value={itemProps.field.filter}
        onConfirm={value => handleSaveFilter(value)}
        onCancel={() => handleCancelFilter()}
      />
    </div>
  );
}


export function SortableItem(props) {
  const {
    id,
    index,
    itemProps,
    typeParrent
  } = props
  const { field } = itemProps

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    data: {
      id,
      index,
      field,
      typeParrent,
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const renderSwitch = (props, listeners) => {
    switch (props.type) {
      case 'rows':
        return (
          <ItemDndRow
            {...props}
            listeners={listeners}
          />
        )
      case 'columns':
        return (
          <ItemDndCol
            {...props}
            listeners={listeners}
          />
        )
      case 'filters':
        return (
          <ItemDndFilter
            {...props}
            listeners={listeners}
          />
        )
      case 'functions':
        return (
          <ItemDndFunc
            {...props}
            listeners={listeners}
          />
        )
      default:
        return (
          <ItemSpacer
            {...props}
          />
        )
    }
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div>
        {
          renderSwitch(props, listeners)
        }
      </div>
    </div>
  )
}

const defaultPropTypes = {
  id: PropTypes.string,
  fieldsReport: PropTypes.object,
  itemProps: PropTypes.object,
  listeners: PropTypes.object,
  typeParrent: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  storageTable: PropTypes.object,
  setRefreshData: PropTypes.func,
  setFieldsReport: PropTypes.func,
}

ItemDnd.propTypes = {
  id: PropTypes.string,
  itemProps: PropTypes.object
}

ItemDndOnDraging.propTypes = {
  field: PropTypes.string
}

ItemSpacer.propTypes = {
  id: PropTypes.string
}

ItemDndRow.propTypes = defaultPropTypes
ItemDndCol.propTypes = defaultPropTypes
ItemDndFilter.propTypes = defaultPropTypes
ItemDndFunc.propTypes = defaultPropTypes
SortableItem.propTypes = defaultPropTypes
