import { API } from 'services'

export function getMaterializations(params) {
  return API({
    endpoint: '/materialization',
    ...params
  })
}

export function getMaterialization({ id, ...params }) {
  return API({
    endpoint: `/materialization/${id}`,
    ...params
  })
}

export function createMaterialization(params) {
  return API({
    method: 'POST',
    endpoint: '/materialization',
    ...params
  })
}

export function updateMaterialization({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/materialization/${id}`,
    ...params
  })
}

export function runMaterialization({ id, ...params }) {
  return API({
    endpoint: `/materialization/${id}/execute`,
    ...params
  })
}

export function getMaterializationLogs({ id, ...params }) {
  return API({
    endpoint: `/materialization/${id}/logs`,
    ...params
  })
}
