import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Container, Stack, Typography, Grid, Skeleton } from '@mui/material'
import { t } from 'i18next'

import { isAuthorized } from 'components/ACL'
import Error from 'components/Error'

import MyCompanyView from 'sections/settings/MyCompany/View'
import MyCompanyForm from 'sections/settings/MyCompany/Form'

import { getCurrentCompany } from 'services/requests/company'

export default function MyCompany() {
  const disabledUpdate = !isAuthorized({ roles: ['superadmin', 'client'] })

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const getData = () => {
    setLoading(true)

    getCurrentCompany()
      .then(res => setData(res))
      .catch(() => setData(null))
      .then(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('settings.my_company.page_title')} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <div>
              <Typography variant="h4">
                {t('settings.my_company.title')}
              </Typography>
              <Typography variant="body2">
                {t(`settings.my_company.description${disabledUpdate ? '_restrict' : ''}`)}
              </Typography>
            </div>
            {
              loading
                ? <Loader />
                : data?.id ? (
                  <div>
                    <Grid
                      container
                      mt={3}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        p={1}
                      >
                        {
                          data?.id ? (
                            <MyCompanyView data={data} />
                          ) : null
                        }
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={8}
                        p={1}
                      >
                        <MyCompanyForm data={data} refresh={() => getData()} />
                      </Grid>
                    </Grid>
                  </div>
                ) : <Error />
            }
          </Stack>
        </Container>
      </Box>
    </>
  )
}

const Loader = () => (
  <Grid container>
    <Grid item xs={12} md={6} lg={4} p={1}>
      <Skeleton animation="wave" height={250} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
    <Grid item xs={12} md={6} lg={8} p={1}>
      <Skeleton animation="wave" height={250} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" sx={{ mt: 3 }} />
      </Box>
    </Grid>
  </Grid>
)
