import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import { MuiFileInput } from 'mui-file-input'
import {
  Box,
  Stack,
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  Chip,
  Autocomplete,
  ListItemText,
  Divider
} from '@mui/material'
import { TimeClock } from '@mui/x-date-pickers/TimeClock'
import { Calendar } from 'react-multi-date-picker'
import gregorianPtBr from 'react-date-object/locales/gregorian_pt_br'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'react-multi-date-picker/styles/colors/purple.css'

import FormDrawer from 'components/FormDrawer'

import { createMaterialization, updateMaterialization } from 'services/requests/materialization'
import { getConnections, getConnectionTables } from 'services/requests/connection'
import { Toast, objectToFormData } from 'utils'

const daysOfWeek = new Array(7).fill(null)

const initialValues = {
  alias: '',
  type: '',
  connectionRemote: '',
  connectionStorage: '',
  table: '',
  status: true,
  scheduleType: '',
  scheduleDays: [],
  scheduleHours: [],
  file: null,
  csvSeparator: '',
  csvEncoding: '',
  csvNullformat: '',
  shpFileName: '',
  shpSRID: '',
  shpEncoding: ''
}

const csvDefaultParams = {
  separator: ';',
  encoding: 'UTF-8'
}

export default function MaterializationForm({ open, data, onClose }) {
  const [connectionsRemote, setConnectionsRemote] = useState([])
  const [connectionsStorage, setConnectionsStorage] = useState([])
  const [tables, setTables] = useState([])

  const validationSchema = yup.object({
    alias: yup.string()
      .required(t('required')),
    type: yup.string()
      .required(t('required')),
    connectionRemote: yup.object()
      .required(t('required')),
    connectionStorage: yup.object()
      .required(t('required')),
    table: yup.string()
      .when('connectionRemote', {
        is: connection => !connection?.adapter?.startsWith('filelocal'),
        then: s => s.required(t('required')),
        otherwise: s => s
      }),
    file: yup.mixed()
      .when('connectionRemote', {
        is: connection => connection?.adapter?.startsWith('filelocal'),
        then: s => s.required(t('required')),
        otherwise: s => s
      }),
    shpFileName: yup.string()
      .when('connectionRemote', {
        is: connection => connection?.adapter === 'filelocal-shp',
        then: s => s.required(t('required')),
        otherwise: s => s
      }),
    shpSRID: yup.string()
      .when('connectionRemote', {
        is: connection => connection?.adapter === 'filelocal-shp',
        then: s => s.required(t('required')),
        otherwise: s => s
      }),
    shpEncoding: yup.string()
      .when('connectionRemote', {
        is: connection => connection?.adapter === 'filelocal-shp',
        then: s => s.required(t('required')),
        otherwise: s => s
      })
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({
      alias, connectionRemote, connectionStorage, table, type,
      status, scheduleType, scheduleDays, scheduleHours,
      file,
      csvSeparator, csvEncoding, csvNullformat,
      shpFileName, shpSRID, shpEncoding,
    }, { setSubmitting }) => {
      const method = data?.id ? updateMaterialization : createMaterialization

      const [schema, tablename] = table.split('.')

      const body = {
        alias,
        connectionStorageId: connectionStorage.id,
        backupType: type,
        status: 'inactive'
      }

      if (connectionRemote.adapter === 'filelocal-shp') {
        body.file = file
        body.config = JSON.stringify({
          shpFileName,
          SRID: shpSRID,
          encoding: shpEncoding
        })
      } else if (connectionRemote.adapter === 'filelocal-csv') {
        body.file = file
        body.config = JSON.stringify({
          separator: csvSeparator || csvDefaultParams?.separator,
          encoding: csvEncoding || csvDefaultParams?.encoding,
          nullformat: csvNullformat || undefined
        })
      } else {
        body.schema = !data?.id ? schema : undefined
        body.table = !data?.id ? tablename : undefined
        body.status = status ? 'active' : 'inactive'
        body.scheduleType = scheduleType || null
        body.scheduleDays = scheduleDays?.length ? scheduleDays : null
        body.scheduleHours = scheduleHours?.length ? scheduleHours : null
      }

      if (!data?.id) {
        body.connectionRemoteId = connectionRemote.id
      }

      const formData = objectToFormData(body)

      method({ id: data?.id, formData })
        .then(() => {
          Toast(t('data.materializations.form.success'), 'success')
          handleClose(true)
        })
        .catch((err) => Toast(err, 'error'))
        .finally(() => setSubmitting(false))
    },
  })

  const handleClose = useCallback((reload) => {
    formik.resetForm(initialValues)
    onClose(reload)
  }, [formik, onClose])

  const handleChange = e => {
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeConnectionRemote = e => {
    formik.setFieldValue('table', '')
    formik.setFieldTouched(e.target.name)
    formik.handleChange(e)
  }

  const handleChangeTable = (_, value) => {
    formik.setFieldTouched('table')
    formik.setFieldValue('table', value)
  }

  const handleChangeFile = file => {
    formik.setFieldTouched('file')
    formik.setFieldValue('file', file)
  }

  const handleChangeRecurrenceType = type => {
    formik.setFieldTouched('scheduleType')
    formik.setFieldValue('scheduleType', type)
    formik.setFieldValue('scheduleDays', [])
    if (type === '') {
      formik.setFieldValue('scheduleHours', [])
    }
  }

  const handleAddHours = hour => {
    const exist = formik.values.scheduleHours.findIndex(item => item === hour)
    const arr = [...formik.values.scheduleHours]

    if (exist <= -1) {
      arr.push(hour)
    }

    formik.setFieldTouched('scheduleHours')
    formik.setFieldValue('scheduleHours', [...arr])
  }

  const handleRemoveHours = hour => {
    const indexToRemove = formik.values.scheduleHours.findIndex(item => item === hour)
    const arr = [...formik.values.scheduleHours]

    if (indexToRemove > -1) {
      arr.splice(indexToRemove, 1)
    }

    formik.setFieldTouched('scheduleHours')
    formik.setFieldValue('scheduleHours', [...arr])
  }

  const handleRemoveDays = day => {
    const indexToRemove = formik.values.scheduleDays.findIndex(item => item === day)
    const arr = [...formik.values.scheduleDays]

    if (indexToRemove > -1) {
      arr.splice(indexToRemove, 1)
    }

    formik.setFieldTouched('scheduleDays')
    formik.setFieldValue('scheduleDays', [...arr])
  }

  useEffect(() => {
    getConnections()
      .then(res => {
        const remotes = res.filter(item => item.type === 'remote')
        setConnectionsRemote(remotes)
        const storages = res.filter(item => item.type === 'storage')
        setConnectionsStorage(storages)
      })
      .catch(err => {
        Toast(err, 'error')
        handleClose()
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!formik.values.connectionRemote) return

    if (!formik.values.connectionRemote.adapter?.startsWith('filelocal')) {
      getConnectionTables({ id: formik.values.connectionRemote.id })
        .then(res => res.map(item => `${item.schema}.${item.table}`).sort())
        .then(parsed => setTables(parsed))
        .catch(() => setTables([]))
    } else {
      setTables([])
    }

    // eslint-disable-next-line
  }, [formik.values.connectionRemote])

  useEffect(() => {
    if (data?.id) {
      const connectionRemote = connectionsRemote.find(item => item.id === data.connectionRemote.id)
      const connectionStorage = connectionsStorage.find(item => item.id === data.connectionStorage.id)

      formik.setFieldValue('connectionRemote', connectionRemote)
      formik.setFieldValue('connectionStorage', connectionStorage)
      formik.setFieldValue('alias', data.alias)
      formik.setFieldValue('table', `${data.originSchemaName}.${data.originTableName}`)
      formik.setFieldValue('type', data.backupType)
      formik.setFieldValue('status', data.status === 'active')
      formik.setFieldValue('scheduleType', data.scheduleType || '')
      formik.setFieldValue('scheduleDays', data.scheduleDays || [])
      formik.setFieldValue('scheduleHours', data.scheduleHours || [])
      formik.setFieldValue('csvSeparator', data?.config?.separator || '')
      formik.setFieldValue('csvEncoding', data?.config?.encoding || '')
      formik.setFieldValue('csvNullformat', data?.config?.nullformat || '')
      formik.setFieldValue('shpFileName', data?.config?.shpFileName || '')
      formik.setFieldValue('shpSRID', data?.config?.SRID || '')
      formik.setFieldValue('shpEncoding', data?.config?.encoding || '')
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <FormDrawer
      title={t(`data.materializations.${data?.id ? 'update' : 'new'}`)}
      open={open}
      handleClose={() => handleClose()}
      actions={[
        {
          label: t('close'),
          onClick: handleClose,
          color: 'error'
        },
        {
          label: t('submit'),
          onClick: formik.handleSubmit,
          loading: formik.isSubmitting,
          variant: 'contained'
        }
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="alias"
              label={t('data.materializations.form.alias')}
              value={formik.values.alias}
              onChange={handleChange}
              error={formik.touched.alias && Boolean(formik.errors.alias)}
              helperText={formik.touched.alias && formik.errors.alias}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="connectionRemote">{t('data.materializations.form.connection_remote')}</InputLabel>
              <Select
                labelId="connectionRemote"
                name="connectionRemote"
                label={t('data.materializations.form.connection_remote')}
                value={formik.values.connectionRemote}
                onChange={handleChangeConnectionRemote}
                disabled={!connectionsRemote?.length || !!data?.id}
                error={formik.touched.connectionRemote && Boolean(formik.errors.connectionRemote)}
              >
                {
                  connectionsRemote.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      <b>{t(`adapters.${item.adapter}`)}</b>
                      {
                        item?.config?.host ? (
                          <>: {item.config.host} - {item.config.database}</>
                        ) : null
                      }
                    </MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.connectionRemote && Boolean(formik.errors.connectionRemote) ? (
                  <FormHelperText error>{formik.touched.connectionRemote && formik.errors.connectionRemote}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {
              formik.values.connectionRemote?.adapter?.startsWith('filelocal') ? (
                <FormControl sx={{ width: '100%' }}>
                  <MuiFileInput
                    label={formik.values.file ? t('data.materializations.form.file') : null}
                    placeholder={t('data.materializations.form.file')}
                    value={formik.values.file}
                    onChange={handleChangeFile}
                    error={formik.touched.file && Boolean(formik.errors.file)}
                    inputProps={{
                      accept: formik.values.connectionRemote?.adapter === 'filelocal-csv' ? '.csv' : '.zip',
                    }}
                  />
                  {
                    formik.touched.file && Boolean(formik.errors.file) ? (
                      <FormHelperText error>{formik.touched.file && formik.errors.file}</FormHelperText>
                    ) : (
                      <FormHelperText>{t(`data.materializations.form.${formik.values.connectionRemote.adapter}_description`)}</FormHelperText>
                    )
                  }
                </FormControl>
              ) : (
                <FormControl sx={{ width: '100%' }}>
                  <Autocomplete
                    disablePortal
                    name="table"
                    value={formik.values.table}
                    onChange={handleChangeTable}
                    disabled={!tables?.length || !!data?.id}
                    options={tables}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('data.materializations.form.table')}
                        error={formik.touched.table && Boolean(formik.errors.table)}
                      />
                    )}
                  />
                  {
                    formik.touched.table && Boolean(formik.errors.table) ? (
                      <FormHelperText error>{formik.touched.table && formik.errors.table}</FormHelperText>
                    ) : null
                  }
                </FormControl>
              )
            }
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="connectionStorage">{t('data.materializations.form.connection_storage')}</InputLabel>
              <Select
                labelId="connectionStorage"
                name="connectionStorage"
                label={t('data.materializations.form.connection_storage')}
                value={formik.values.connectionStorage}
                onChange={handleChange}
                disabled={!connectionsStorage?.length}
                error={formik.touched.connectionStorage && Boolean(formik.errors.connectionStorage)}
              >
                {
                  connectionsStorage.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      <b>{t(`adapters.${item.adapter}`)}</b>
                      {
                        item?.config?.host ? (
                          <>: {item.config.host} - {item.config.database}</>
                        ) : null
                      }
                    </MenuItem>
                  ))
                }
              </Select>
              {
                formik.touched.connectionStorage && Boolean(formik.errors.connectionStorage) ? (
                  <FormHelperText error>{formik.touched.connectionStorage && formik.errors.connectionStorage}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="type">{t('data.materializations.form.type')}</InputLabel>
              <Select
                labelId="type"
                name="type"
                label={t('data.materializations.form.type')}
                value={formik.values.type}
                onChange={handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
              >
                <MenuItem value="full">{t(`materialization_types.full`)}</MenuItem>
                <MenuItem value="incremental">{t(`materialization_types.incremental`)}</MenuItem>
              </Select>
              {
                formik.touched.type && Boolean(formik.errors.type) ? (
                  <FormHelperText error>{formik.touched.type && formik.errors.type}</FormHelperText>
                ) : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Divider />
          </Grid>
          {
            formik.values.connectionRemote?.adapter?.startsWith('filelocal') ? (
              formik.values.connectionRemote.adapter === 'filelocal-csv' ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="csvSeparator"
                      label={t('data.materializations.form.csv_separator')}
                      value={formik.values.csvSeparator}
                      onChange={handleChange}
                      helperText={t('data.materializations.form.csv_separator_description', { data: csvDefaultParams.separator })}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="csvEncoding"
                      label={t('data.materializations.form.csv_encoding')}
                      value={formik.values.csvEncoding}
                      onChange={handleChange}
                      helperText={t('data.materializations.form.csv_encoding_description', { data: csvDefaultParams.encoding })}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      name="csvNullformat"
                      label={t('data.materializations.form.csv_nullformat')}
                      value={formik.values.csvNullformat}
                      onChange={handleChange}
                      helperText={t('data.materializations.form.csv_nullformat_description')}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="shpFileName"
                      label={t('data.materializations.form.shp_filename')}
                      value={formik.values.shpFileName}
                      onChange={handleChange}
                      error={formik.touched.shpFileName && Boolean(formik.errors.shpFileName)}
                      helperText={(formik.touched.shpFileName && formik.errors.shpFileName) ? formik.errors.shpFileName : t('data.materializations.form.shp_filename_description')}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="shpSRID"
                      label={t('data.materializations.form.shp_srid')}
                      value={formik.values.shpSRID}
                      onChange={handleChange}
                      error={formik.touched.shpSRID && Boolean(formik.errors.shpSRID)}
                      helperText={(formik.touched.shpSRID && formik.errors.shpSRID) ? formik.errors.shpSRID : t('data.materializations.form.shp_srid_description')}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        name="shpEncoding"
                        label={t('data.materializations.form.shp_encoding')}
                        value={formik.values.shpEncoding}
                        onChange={handleChange}
                        error={formik.touched.shpEncoding && Boolean(formik.errors.shpEncoding)}
                        helperText={(formik.touched.shpEncoding && formik.errors.shpEncoding) ? formik.errors.shpEncoding : t('data.materializations.form.shp_encoding_description')}
                        sx={{ width: '100%' }}
                      />
                  </Grid>
                </>
              )
            ) : (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        name="status"
                        control={<Checkbox color="primary" />}
                        checked={formik.values.status}
                        value={formik.values.status}
                        onChange={handleChange}
                        label={(
                          <Stack my={1}>
                            <Typography variant="subtitle1">{t('data.materializations.form.status')}</Typography>
                            <Typography variant="body2">{t('data.materializations.form.status_description')}</Typography>
                          </Stack>
                        )}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="scheduleType">{t('data.materializations.form.schedule_type')}</InputLabel>
                    <Select
                      labelId="scheduleType"
                      name="scheduleType"
                      label={t('data.materializations.form.schedule_type')}
                      value={formik.values.scheduleType}
                      onChange={e => handleChangeRecurrenceType(e.target.value)}
                    >
                      <MenuItem value="">{t('data.materializations.form.schedule_type_inactive')}</MenuItem>
                      <MenuItem value="week">{t(`materialization_schedule_types.week`)}</MenuItem>
                      <MenuItem value="month">{t(`materialization_schedule_types.month`)}</MenuItem>
                    </Select>
                    {
                      formik.touched.scheduleType && Boolean(formik.errors.scheduleType) ? (
                        <FormHelperText error>{formik.touched.scheduleType && formik.errors.scheduleType}</FormHelperText>
                      ) : null
                    }
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {
                    formik.values.scheduleType === 'week' ? (
                      <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="scheduleDays">{t('data.materializations.form.schedule_weekdays')}</InputLabel>
                        <Select
                          multiple
                          labelId="scheduleDays"
                          name="scheduleDays"
                          label={t('data.materializations.form.schedule_weekdays')}
                          value={formik.values.scheduleDays}
                          onChange={handleChange}
                          renderValue={(selected) => selected.sort((a, b) => (a - b)).map(item => t(`weekdays.${item}`)).join(', ')}
                        >
                          {
                            daysOfWeek.map((_, key) => (
                              <MenuItem key={key} value={key}>
                                <Checkbox checked={formik.values.scheduleDays.includes(key)} />
                                <ListItemText primary={t(`weekdays.${key}`)} />
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    ) : null
                  }

                  {
                    formik.values.scheduleType === 'month' ? (
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <Calendar
                          sort
                          multiple
                          format="DD"
                          value={formik.values.scheduleDays}
                          onChange={values => {
                            formik.setFieldTouched('scheduleDays')
                            formik.setFieldValue('scheduleDays', values)
                          }}
                          locale={gregorianPtBr}
                          className="purple"
                          style={{ maxWidth: '280px' }}
                        />
                        <Stack direction="row" flexWrap="wrap" justifyContent="center">
                          {
                            formik.values.scheduleDays.sort((a, b) => (a - b)).map((item, key) => (
                              <Chip
                                key={key}
                                label={`${t('day')} ${item}`}
                                onDelete={() => handleRemoveDays(item)}
                                color="default"
                                style={{ margin: 4 }}
                              />
                            ))
                          }
                        </Stack>
                      </Stack>
                    ) : null
                  }

                  {
                    formik.values.scheduleType ? (
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <TimeClock
                          views={['hours']}
                          onChange={value => handleAddHours(value.getHours())}
                        />

                        <Stack direction="row" flexWrap="wrap" justifyContent="center">
                          {
                            formik.values.scheduleHours.sort((a, b) => (a - b)).map((item, key) => (
                              <Chip
                                key={key}
                                label={`${(`0${item}`).slice(-2)}:00`}
                                onDelete={() => handleRemoveHours(item)}
                                color="default"
                                style={{ margin: 4 }}
                              />
                            ))
                          }
                        </Stack>
                      </Stack>
                    ) : null
                  }
                </Grid>
              </>
            )
          }
        </Grid>
      </Box>
    </FormDrawer>
  )
}

MaterializationForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
}
