import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Container,
  Stack,
  Typography
} from '@mui/material'
import { t } from 'i18next'

import Iconify from 'components/Iconify'

import PanelForm from 'sections/visualization/PanelDetail/Form'

export default function PanelDetail() {
  const navigate = useNavigate()
  const { id } = useParams()

  const newOrEdit = id === 'novo' ? 'new' : 'edit'

  return (
    <>
      <Helmet>
        <title>{t(`visualization.panel_detail.page_title_${newOrEdit}`)} | {t('project_name')}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5
        }}
      >
        <Container maxWidth="xl">
          <Stack>
            <Typography variant="h4" color="primary" sx={{ width: 'max-content' }} className="link" onClick={() => navigate(-1)}>
              <Iconify icon="lets-icons:back" /> {t('visualization.panels.title')}
            </Typography>
            <Typography variant="body2">
              {t(`visualization.panel_detail.description_${newOrEdit}`)}
            </Typography>
          </Stack>
        </Container>
      </Box>

      <PanelForm />
    </>
  )
}
