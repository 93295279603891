import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box
} from '@mui/material'
// import { format } from 'date-fns'
import { t } from 'i18next'

import DataGrid from 'components/DataGrid'

import { getMaterializations, runMaterialization } from 'services/requests/materialization'
import { Toast, enums } from 'utils'

import MaterializationForm from './Form'

export default function MaterializationsView({ newItem, cancelNew }) {
  const navigate = useNavigate()
  const dataGridRef = useRef(null)

  const [selectedData, setSelectedData] = useState(null)

  // const formatDate = dt => format(new Date(dt), 'dd/MM/yyyy \'às\' HH:mm:ss')

  const listConfig = {
    fields: [
      {
        accessorFn: row => row.alias,
        header: t('data.materializations.fields.alias'),
        size: 250,
      },
      {
        accessorFn: row => `${row.originSchemaName}.${row.originTableName}`,
        header: t('data.materializations.fields.origin'),
        size: 300,
      },
      {
        accessorFn: row => `${t(`materialization_types.${row.backupType}`)}`,
        header: t('data.materializations.fields.type'),
        size: 280,
        filterVariant: 'select',
        filterSelectOptions: enums.materializationType
      },
      {
        accessorFn: row => t(row.status === 'active' ? 'active' : 'inactive'),
        header: t('data.materializations.fields.status'),
        size: 180,
        filterVariant: 'select',
        filterSelectOptions: enums.active,
        filterFn: 'equals'
      },
      // {
      //   accessorKey: 'last_exec_status',
      //   accessorFn: row => row.last_exec_status ? t(`materialization_logs.${row.last_exec_status}`) : '',
      //   header: t('data.materializations.fields.last_exec_status'),
      //   size: 280,
      //   filterVariant: 'select',
      //   filterSelectOptions: enums.materializationLog,
      // },
      // {
      //   accessorKey: 'last_exec_at',
      //   accessorFn: row => row.last_exec_at ? new Date(row.last_exec_at) : null,
      //   header: t('data.materializations.fields.last_exec_at'),
      //   size: 350,
      //   filterVariant: 'date-range',
      //   filterFn: 'filterDateInterval',
      //   Cell: ({ cell }) => cell.getValue() ? formatDate(cell.getValue()) : ''
      // }
    ],
    actions: [
      {
        icon: 'icon-park-solid:play',
        label: t('data.materializations.execute'),
        onClick: item => item.connectionRemote.adapter.startsWith('filelocal') ? setSelectedData(item) : handleExecuteMaterialization(item.id)
      },
      {
        icon: 'mdi:file-eye-outline',
        label: t('data.materializations.open'),
        onClick: item => navigate(`/dados/materializacoes/${item.id}`)
      },
      {
        icon: 'mdi:pencil',
        label: t('data.materializations.update'),
        onClick: item => setSelectedData(item)
      }
    ],
    filterFns: {
      filterDateInterval: (row, id, filterValue) => {
        const value = row.getValue(id)
        if (!value && (filterValue[0] || filterValue[1])) {
          return false
        }
        if (typeof filterValue[0] === 'object') {
          if (typeof filterValue[1] === 'object') {
            return value >= filterValue[0] && value <= filterValue[1]
          }
          return value >= filterValue[0]
        }
        if(typeof filterValue[1] === 'object') {
          return value <= filterValue[1]
        }
        return true
      },
    },
    onRowClick: row => navigate(`/dados/materializacoes/${row.id}`)
  }

  const handleExecuteMaterialization = id => {
    runMaterialization({ id })
      .then(() => {
        Toast(t('data.materializations.running'), 'info')
      })
      .catch(err => Toast(err, 'error'))
  }

  return (
    <Box mt={5}>
      <DataGrid
        getData={getMaterializations}
        config={listConfig}
        ref={dataGridRef}
      />

      <MaterializationForm
        open={(!!selectedData?.id || newItem)}
        data={selectedData}
        onClose={(reload) => {
          cancelNew()
          setSelectedData(null)
          if (reload) {
            dataGridRef?.current.refreshData()
          }
        }}
      />
    </Box>
  )
}

MaterializationsView.propTypes = {
  newItem: PropTypes.bool,
  cancelNew: PropTypes.func
}

