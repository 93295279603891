import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'

import Logo from 'components/Logo'

import {
  StyledRoot,
  StyledSection,
  StyledContent
} from './styled'

export default function AuthView({ t, pageType, Form }) {
  return (
    <StyledRoot>
      <StyledSection>
        <StyledContent>
          {
            pageType !== 'invite' ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: `0 30px`
                  }}
                >
                  <Logo disabledLink />

                  <Typography variant="h4" mt={4} gutterBottom>
                    {t(`auth.${pageType}.title`)}
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 5 }}>
                    {t(`auth.${pageType}.description`)}
                  </Typography>
                </Box>
              </>
            ) : null
          }

          <Form t={t} />
        </StyledContent>
      </StyledSection>
    </StyledRoot>
  )
}

AuthView.propTypes = {
  t: PropTypes.func,
  pageType: PropTypes.string,
  Form: PropTypes.elementType
}
